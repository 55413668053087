import {Dispatch} from 'redux';
import {calculateSize} from '../util/object';
import {getUserPreferencesState, getUserPreferencesStateAdmin} from '../../api/userManagementApi';
import {Dictionary} from '../util';
import {batchActions} from 'redux-batched-actions';
import {User, userStore} from './entities/user';
import {VolunteerGroup, volunteerGroupStore} from './entities/volunteerGroup';

export interface UserPreferencesResponse {
  users: Dictionary<User>;
  volunteerGroups: Dictionary<VolunteerGroup>;
}

export const loadUserPreferences = (isAdmin: boolean) => async (dispatch: Dispatch) => {
  let response: UserPreferencesResponse;
  if (isAdmin) {
    response = await getUserPreferencesStateAdmin();
  } else {
    response = await getUserPreferencesState();
  }
  if (process.env.NODE_ENV === 'development') {
    calculateSize(response, 'User Preferences Response');
  }
  await dispatch(batchActions([
    dispatch(userStore.actions.set(response.users)),
    dispatch(volunteerGroupStore.actions.set(response.volunteerGroups))
  ], 'BATCH_SET_USER_PREFERENCES_STATE'));
};
