import {format, parse, startOfWeek, getDay} from 'date-fns';
import {enUS} from 'date-fns/locale';
import {dateFnsLocalizer} from 'react-big-calendar';

const locales = {
  'en-US': enUS
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
});

export default localizer;

