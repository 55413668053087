import React, {useEffect, useState} from 'react';
import styles from './Register.module.scss';
import {Alert, Container, Image, Row} from 'react-bootstrap';
import {Link, Navigate} from 'react-router-dom';
import {isAuthenticated, userStore} from '../../common/redux/entities/user';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {RoutePaths} from '../../router/RoutePaths';
import {WebState} from '../../redux/types/WebState';
import {getLogoLink} from '../../appTheme';
import {
  UsernameAndPasswordForm,
  UsernameAndPasswordFormInterface
} from './components/UsernameAndPasswordForm/UsernameAndPasswordForm';
import {
  ContactInformationForm,
  ContactInformationFormInterface
} from './components/ContactInformationForm/ContactInformationForm';
import {register} from '../../api/authApi';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../common/util/http';
import {AuthenticationBody} from "../../components/layout/AuthenticationBody/AuthenticationBody";


export enum ERegistrationPage {
  usernameAndPassword,
  contactInformation,
  finished
}

export interface IRegistrationRequest extends UsernameAndPasswordFormInterface, ContactInformationFormInterface {
}

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

function Register(props: Props) {
  const {authenticated, actions: {setCurrentUser}} = props;

  const [userNameAndPasswordForm, setUsernameAndPasswordForm] = useState<UsernameAndPasswordFormInterface>({
    email: '',
    password: '',
    confirmPassword: '',
    name: ''
  });
  const [registrationPage, setRegistrationPage] = useState<ERegistrationPage>(ERegistrationPage.usernameAndPassword);
  const setToNextRegistrationPage = () => { setRegistrationPage((prevState) => (prevState + 1)); };

  const [errorMessage, setErrorMessage] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');

  useEffect(() => {
    if (authenticated) {
      setRedirectUrl(RoutePaths.home);
    }
  });

  const renderRedirect = () => {
    if (redirectUrl.length !== 0) {
      return <Navigate to={redirectUrl} />;
    }
    return null;
  };

  const usernameAndPasswordOnSubmit = (data: UsernameAndPasswordFormInterface) => {
    setUsernameAndPasswordForm(data);
    setRegistrationPage(ERegistrationPage.contactInformation);
  };

  const contactInformationOnSubmit = async (data: ContactInformationFormInterface) => {
    try {
      await register({...userNameAndPasswordForm, ...data} as IRegistrationRequest);
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load volunteer signup calendar'}));
      return;
    }
    setRegistrationPage(ERegistrationPage.finished);
  };

  const renderCurrentRegistrationComponent = () => {
    switch (registrationPage) {
      case ERegistrationPage.usernameAndPassword:
        return (
          <UsernameAndPasswordForm onSubmit={usernameAndPasswordOnSubmit}/>);
      case ERegistrationPage.contactInformation:
        return (
          <ContactInformationForm onSubmit={contactInformationOnSubmit} errorMessage={'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa'}/>);
      case ERegistrationPage.finished:
        return (<div>Account Registered, check your email to verify your email address.</div>);
    }
  };

  return (
    <AuthenticationBody>
      {renderRedirect()}
      <Container className={styles['login-form']}>
        {renderCurrentRegistrationComponent()}
      </Container>
      {errorMessage !== '' ?
        <Row style={{marginTop: '1rem', justifyContent: 'center', flexWrap: 'wrap'}}>
          <Alert variant='danger'>{errorMessage}</Alert>
        </Row>
        : null}
      <div className={styles['forgot-password']}>
        <Link to={RoutePaths.login}>Back to login</Link>
      </div>
    </AuthenticationBody>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({setCurrentUser: userStore.actions.setCurrentUser}, dispatch)});
const mapStateToProps = (state: WebState) => ({ authenticated: isAuthenticated(state)});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
