import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../../redux/types/WebState';
import {ConfirmationDialog} from '../../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import VideoModal, {CreateVideoInterface} from '../VideoModal/VideoModal';
import {Alert, Button} from 'react-bootstrap';
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next';
// @ts-ignore
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.js';
import {Video, videoStore} from '../../../../../common/redux/entities/video';
import {CenteredSpinner} from '../../../../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../../../../common/util/http';
import {makeUpdateVideoRequest} from '../../../../../common/util/factory';
import UpdateVideoModal, {UpdateVideoInterface} from '../VideoModal/UpdateVideoModal';
import gridStyles from '../../../GridStyles.module.scss';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function VideoGrid(props: Props) {
  const {actions: {createVideo, updateVideo, deleteVideo}, videos} = props;
  const { SearchBar } = Search;
  const [isDeleting, setIsDeleting] = useState('');
  const [viewCreateVideoModal, setViewCreateVideoModal] = useState(false);
  const [viewUpdateVideoModal, setViewUpdateVideoModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [editingVideo, setEditingVideo] = useState(makeUpdateVideoRequest());

  const videoActionsFormatter = (cell: any, video: Video) => {
    return (
      <>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <IconButton
            icon='edit'
            size={'2x'}
            onClick={() => {
              setEditingVideo(video as any as UpdateVideoInterface);
              setViewUpdateVideoModal(true);
            }}
            color={'#005A9C'}
          />
          <div>
            <IconButton
              icon={'trash-alt'}
              styles={{color: 'red', marginLeft: '.5rem'}}
              size={'2x'}
              iconToolTipText={'Delete Video'}
              onClick={() => setIsDeleting(video.id)}
            />
          </div>
        </div>
      </>
    );
  };

  const directLinkFormatter = (cell: any, video: Video) => {
    const videoPath = `${process.env.REACT_APP_STORAGE_URL}${video.url}`;
    return (
      <>
        <div style={{marginRight: '10px', display: 'flex', justifyContent: 'center'}}>
          <a href={videoPath}>Open Video</a>
        </div>
      </>
    );
  };

  const columns: ColumnDescription[] = [
    {
      dataField: 'id',
      text: 'ID',
      hidden: true,
      sort: true,
      headerStyle: () => {
        return { width: '5rem'};
      },
      style: () => {
        return { width: '5rem'};
      }
    },
    {
      dataField: 'id',
      text: 'test',
      hidden: true
    },
    {
      dataField: 'title',
      text: 'Title'
    },
    {
      dataField: 'description',
      text: 'Description'
    },
    {
      dataField: 'url',
      formatter: directLinkFormatter,
      text: 'Video Link'
    },
    {
      dataField: 'actionsColumn',
      text: '',
      isDummyField: true,
      formatter: videoActionsFormatter,
      headerStyle: () => {
        return { width: '6rem'};
      },
      style: () => {
        return { width: '6rem'};
      }
    }
  ];

  return (
    <ToolkitProvider
      keyField='id'
      data={videos}
      columns={columns}
      search={{
        searchFormatted: true
      }}
    >
      {(tableProps: any) => (
        <>
          <div style={{marginRight: '10px', display: 'flex', flexGrow: 1}}>
            <div style={{marginRight: '10px', display: 'flex', justifyContent: 'flex-end', marginBottom: '10px'}}>
              <Button
                onClick={() => setViewCreateVideoModal(true)}
                style={{backgroundColor: '#005A9C'}}
              >
                Add Video
              </Button>
            </div>
          </div>
          <SearchBar {...tableProps.searchProps} placeholder={'Search'} />
          {isLoading ?  <CenteredSpinner/> :
            <BootstrapTable
              wrapperClasses={gridStyles['responsive-table-wrapper']}
              classes={gridStyles['table-auto']}
              rowStyle={{height: '100%'}}
              {...tableProps.baseProps}
            />
          }
          {isDeleting !== '' && (
            <ConfirmationDialog
              onAccept={async () => {
              await deleteVideo(isDeleting);
              setIsDeleting('');
              }}
              onDecline={async () => { setIsDeleting(''); }}
              open={isDeleting !== ''}
              prompt='Are you sure you want to delete this video?'
              positiveText='Yes'
              negativeText='No'
              positiveVariant='success'
              negativeVariant='danger'
            />
            )}
          {viewCreateVideoModal ?
            <VideoModal
              editable={true}
              onSubmit={async (value: CreateVideoInterface) => {
                try {
                  setErrorMessage('');
                  await createVideo(value);
                } catch (e: AxiosError | any) {
                  setErrorMessage(handleAxiosError(e));
                }
                setViewCreateVideoModal(false);
              }}
              onCancel={() => setViewCreateVideoModal(false)}
            />
            : null}
          {viewUpdateVideoModal ?
            <UpdateVideoModal
              existingVideo={editingVideo}
              editable={true}
              onSubmit={async (value: UpdateVideoInterface) => {
                try {
                  setErrorMessage('');
                  await updateVideo(value);
                } catch (e: AxiosError | any) {
                  setErrorMessage(handleAxiosError(e));
                }
                setViewUpdateVideoModal(false);
              }}
              onCancel={() => setViewUpdateVideoModal(false)}
            />
            : null}
          {errorMessage ? <Alert variant='danger'>{errorMessage}</Alert> : null}
        </>
      )}
    </ToolkitProvider>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    createVideo: videoStore.actions.create,
    deleteVideo: videoStore.actions.delete,
    updateVideo: videoStore.actions.update,
    uspertVideoFile: videoStore.actions.upsertVideoFile
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  videos: videoStore.selectors.getAsArray(state),
  getVideoById: videoStore.selectors.getByIdGuid(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(VideoGrid);
