import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {combineReducers} from 'redux';
import {CommonDispatch} from '../index';
import {
  upsertShift, deleteShift,
  loadShiftsForMonth,
  shiftSignup, ShiftUpsertInterface, cateringShiftSignup, leaveShift, deleteShiftRange, DeleteShiftRangeInterface
} from '../../../api/shiftManagementApi';
import {ShiftVolunteer} from './shiftVolunteer';
import {
  CateringSignupInterface,
  VolunteerSignupInterface
} from '../../../pages/VolunteerSignup/VolunteerShiftSignupModal/ShiftSignupModal';
import {createAction} from 'typesafe-actions';
import {forEach} from 'lodash';
import {
  ShiftDeletionModalInterface
} from '../../../pages/Configuration/CalendarManagement/components/ShiftDeletionModal/ShiftDeletionModal';

export interface Shift {
  id: string;
  title: string;
  startDate: string;
  endDate: string;
  volunteersNeeded: number;
  shiftVolunteers: ShiftVolunteer[];
  catering: boolean;
  businessName: string;
  description: string;
  open: boolean;
}

export const deleteShiftAction = createAction('SHIFT_DELETE/DELETE')<string>();
const actions = createStandardActions(placeholder<Shift>(), 'SHIFT/SET', 'SHIFT/SAVE');
const selectors = createStandardSelectors(placeholder<Shift>(), s => getEntities(s).shifts);
export type shiftActions = GetActions<typeof actions> | ReturnType<typeof deleteShiftAction>;
export const shifts = combineReducers({
  items: standardItemsReducer<Shift, shiftActions>(actions)
    .handleAction(deleteShiftAction, (state, action) => {
      const newState = {...state};
      delete newState[action.payload as any];
      return newState;
    })
});
export const shiftStore = readonly({
  selectors: {
    ...selectors,
    volunteerShifts: selector(s => selectors.getAsArray(s).filter(shift => !shift.catering)),
    openVolunteerShifts: selector(s => selectors.getAsArray(s).filter(shift => !shift.catering).filter(shift => shift.open)),
    cateringShifts: selector(s => selectors.getAsArray(s).filter(shift => shift.catering)),
    openCateringShifts: selector(s => selectors.getAsArray(s).filter(shift => shift.catering).filter(shift => shift.open))
  },
  actions: {
    ...actions,
    loadMonth: (isoDate: string) => async (dispatch: CommonDispatch) => {
      const response = await loadShiftsForMonth(isoDate);
      forEach(response, async (r) => {
        await dispatch(actions.save(r));
      });
      return response;
    },
    create: (request: ShiftUpsertInterface) => async (dispatch: CommonDispatch) => {
      const response = await upsertShift(request);
      forEach(response, async (r) => {
        await dispatch(actions.save(r));
      });
      return response;
    },
    signup: (id: string, request: VolunteerSignupInterface) => async (dispatch: CommonDispatch) => {
      const response = await shiftSignup(id, request);
      await dispatch(actions.save(response));
    },
    cateringSignup: (id: string, request: CateringSignupInterface) => async (dispatch: CommonDispatch) => {
      const response = await cateringShiftSignup(id, request);
      await dispatch(actions.save(response));
    },
    delete: (id: string) => async (dispatch: CommonDispatch) => {
      const response = await deleteShift(id);
      await dispatch(deleteShiftAction(id));
    },
    deleteRange: (request: DeleteShiftRangeInterface) => async (dispatch: CommonDispatch) => {
      const response = await deleteShiftRange(request);
      forEach(response, async (r) => {
        await dispatch(deleteShiftAction(r.id));
      });
      return response;
    },
    leaveShift: (id: string) => async (dispatch: CommonDispatch) => {
      const response = await leaveShift(id);
      await dispatch(actions.save(response));
      return response;
    }
  }
});
