import {
  LinkedCenteredCard,
  LinkedCenteredCardDisabled
} from '../../../../components/util/LinkedCenteredCard/LinkedCenteredCard';
import styles from './HomePageLinkedCard.module.scss';
import React, {CSSProperties, memo} from 'react';
import {Col} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp, SizeProp} from '@fortawesome/fontawesome-svg-core';

interface Props {
  imageSrc?: string;
  icon: IconProp;
  size?: SizeProp;
  style?: CSSProperties;
  faStyle?: CSSProperties;
  text: string;
  link: string;
  children?: JSX.Element | null;
  disabled?: boolean;
}

const disabledCard = (props: Props) => (
  <LinkedCenteredCardDisabled link={props.link}>
    {props.children}
    <div className={styles['container']} style={{...props.style, color: 'gray'}}>
      <FontAwesomeIcon icon={props.icon} size={props.size} style={props.faStyle}/>
      <h5>
        {props.text}
      </h5>
    </div>
  </LinkedCenteredCardDisabled>
);

const enabledCard = (props: Props) => (
  <LinkedCenteredCard link={props.link}>
    {props.children}
    <div className={styles['container']} style={props.style}>
      <FontAwesomeIcon icon={props.icon} size={props.size} style={props.faStyle}/>
      <h5>
        {props.text}
      </h5>
    </div>
  </LinkedCenteredCard>
);

const HomePageLinkedCard = memo((props: Props) => (
  <Col style={{padding: '2rem 4vw'}}>
    {props.disabled ? disabledCard(props) : enabledCard(props)}
  </Col>
));

export default HomePageLinkedCard;
