import React, {useState} from 'react';
import {RoutePaths} from '../../router/RoutePaths';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../redux/types/WebState';
import {Card, Col, Container, Row} from 'react-bootstrap';
import styles from './Home.module.scss';
import {CenteredSpinner} from '../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import HomePageLinkedCard from './components/HomePageLinkedCard/HomePageLinkedCard';
import {useMount} from '../../hooks/useMount';
import {homeTextStore} from '../../common/redux/entities/homeText';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../common/util/http';
import {loadHomeTextData, setHomeTextData} from '../../common/redux/homeText';
import HomeTextEditorModal from './components/HomeTextEditorModal';
import {userStore} from '../../common/redux/entities/user';
import {CartIcon} from '../../components/util/widgets/CartIcon/CartIcon';
import IconButton from '../../components/util/widgets/IconButton/IconButton';
import {getLogoLink} from '../../appTheme';
import TwillioNotificationCheckBox from './components/TwillioNotificationCheckBox';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

function Home(props: Props) {

  const {currentUser, homeText, actions: {loadHomeText, loadVideosToWatch}, getNumberOfVideosToWatch} = props;

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [showModal, setShowModal] = useState(false);

  useMount(async () => {
    try {
      await loadHomeText();
      await loadVideosToWatch();
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Error Communicating with Server.'}));
    }
    setLoading(false);
  });

  const renderHomeTextEditorComponents = () => (
    <>
      <IconButton icon={'edit'} size={'lg'} styles={{paddingLeft: '1rem'}} onClick={() => setShowModal(true)}/>
      <HomeTextEditorModal show={showModal} setShow={setShowModal}/>
    </>
  );

  const renderTitleAndDescription = () => (
    <>
      <Row className='align-items-center' style={{padding: '0'}}>
        <Col style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
          <div style={{display: 'flex', flex: '1', justifyContent: 'center'}}>
            <img src={getLogoLink()} alt={'largeLogo'} style={{maxHeight: '8rem', maxWidth: '90%', display: 'flex'}}/>
          </div>
        </Col>
      </Row>
      <Row style={{justifyContent: 'center', textAlign: 'center', margin: '2rem 0 1rem 0', alignItems: 'center'}}>
        <TwillioNotificationCheckBox/>
      </Row>
      <Row className='justify-content-center'>
        <h5 className={styles['subtitle']}>
          {homeText.value ? homeText.value : null}
        </h5>
      </Row>
      <Row style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center', flexDirection: 'column'}}>
        <p style={{marginLeft: '2rem', marginRight: '2rem', maxWidth: '1000px'}}>
          Welcome to the White Flag Volunteer portal hub!
        </p>
        <p>
          White Flag is an emergency shelter that is open from November 15th -
          April 15th  when the weather is below 35&#176;F
          between the hours of 7:00 PM to 7:00 AM for at least 4 hours.
          We provide a warm dinner, safe place to sleep, breakfast and bagged a lunch to each of our guests!
        </p>
        <p>
          Here you can access trainings modules so that you are prepared to Volunteer for White Flag,
          or cater meals for a specific day.
          You can also opt in to text message alerts to know when the shelter will be open or closed on a given day.
        </p>
      </Row>
    </>
  );

  const renderLinkedCards = () => (
    <>
      <HomePageLinkedCard
        link = {RoutePaths.trainingVideos}
        icon={'video-camera'}
        text={'Training videos'}
        size={'10x'}
        style={{width: '12rem'}}
      >
        {getNumberOfVideosToWatch ?
          <div style={{position: 'absolute', top: '0', right: '0%', transform: 'translate(-20%, 20%)'}}>
            <CartIcon ammount={getNumberOfVideosToWatch}/>
          </div>
          : null
        }
      </HomePageLinkedCard>
      <HomePageLinkedCard
        link = {RoutePaths.volunteerCalendar}
        icon={'calendar-days'}
        text={'Volunteer signup'}
        size={'10x'}
        style={{width: '12rem'}}
      />
      <HomePageLinkedCard
        link = {RoutePaths.cateringCalendar}
        icon={'utensils'}
        text={'Meal signup'}
        size={'10x'}
        style={{width: '12rem'}}
      />
      <HomePageLinkedCard
        link={currentUser?.role.roleName === 'Administrator' ? RoutePaths.groupManagement : RoutePaths.profilePaths.View}
        icon={'users'}
        text={currentUser?.role.roleName === 'Administrator' ? 'Volunteer Groups' : 'Volunteer Group'}
        size={'10x'}
        style={{width: '12rem'}}
      />
    </>
  );

  const renderContent = () => {
    return (
        <Card className={styles['page-body']} style={{borderLeftWidth: 0, borderRightWidth: 0}}>
          <Card.Body>
            {renderTitleAndDescription()}
            <Row className='justify-content-md-center'>
              {renderLinkedCards()}
            </Row>
          </Card.Body>
        </Card>
    );
  };

  return loading ?  <CenteredSpinner/> : (
        errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
          renderContent()
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadHomeText: loadHomeTextData,
    setHomeText: setHomeTextData,
    loadVideosToWatch: userStore.actions.loadVideosToWatch
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  homeText: homeTextStore.selectors.getState(state),
  getNumberOfVideosToWatch: userStore.selectors.getNumberOfVideosToWatch(state),
  currentUser: userStore.selectors.getCurrentUser(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
