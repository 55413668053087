import React, {useState} from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import styles from './VideoManagement.module.scss';
import {connect} from 'react-redux';
import {WebState} from '../../../redux/types/WebState';
import {bindActionCreators, Dispatch} from 'redux';
import {CenteredSpinner} from '../../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {handleAxiosError} from '../../../common/util/http';
import {useMount} from '../../../hooks/useMount';
import {loadVideoManagementData} from '../../../common/redux/videoConfiguration';
import VideoGrid from './components/VideoGrid/VideoGrid';
import {AxiosError} from 'axios';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function VideoManagement(props: Props) {
  const {actions: {loadVideoData}} = props;
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useMount(async () => {
    try {
      await loadVideoData();
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load videos'}));
    }
    setLoading(false);
  });

  return (
    <div>
      {loading ?  <CenteredSpinner/> : (
        errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
          <VideoGrid/>
      )}
    </div>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) =>
  ({actions: bindActionCreators({loadVideoData: loadVideoManagementData}, dispatch)});
const mapStateToProps = (state: WebState) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(VideoManagement);
