import React, {Dispatch, FormEvent, SetStateAction, useState} from 'react';
import styles from '../RegistrationFormStyles.module.scss';
import {Alert, Col, Container, Form, Row} from 'react-bootstrap';
import {LoadingButton} from '../../../../components/util/widgets/LoadingButton/LoadingButton';
import {Formik, FormikErrors} from 'formik';
import Input from '../../../../components/util/form-components/formik-inputs/Input/Input';
import {AxiosError} from 'axios';
import {usernameAndPasswordFormSchema} from './UsernameAndPasswordFormSchema';
import {makeUsernameAndPasswordForm} from '../../../../common/util/factory';
import {handleAxiosError} from '../../../../common/util/http';

export interface UsernameAndPasswordFormInterface {
  email: string;
  name: string;
  password: string;
  confirmPassword: string;
}

type Props = {
  onSubmit: (data: UsernameAndPasswordFormInterface) => void;
};

export const UsernameAndPasswordForm = (props: Props) => {
  const {onSubmit} = props;

  const [errorMessage, setErrorMessage] = useState('');
  const getFieldName = (name: keyof UsernameAndPasswordFormInterface) => name;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    data: UsernameAndPasswordFormInterface,
    validate: (data: UsernameAndPasswordFormInterface) => Promise<FormikErrors<UsernameAndPasswordFormInterface>>,
    handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void
  ) => {
    setIsSubmitting(true);
    e.persist();
    e.preventDefault();
    const errors = await validate(data);
    if (Object.values(errors).length !== 0) {
      handleSubmit(e);
      setIsSubmitting(false);
    } else {
        const request = {
          ...data
        };
        await onSubmit(request);
    }
    setIsSubmitting(false);
  };

  return (
      <Formik<UsernameAndPasswordFormInterface>
        initialValues={makeUsernameAndPasswordForm()}
        onSubmit={() => undefined}
        validationSchema={usernameAndPasswordFormSchema}
      >
        {({values,validateForm, handleSubmit}) => (
          <Form noValidate={true} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
            <Col lg={true}>
              <Form.Group as={Row}>
                <Col sm={14}>
                  <Input
                    type='text'
                    name={getFieldName('email')}
                    placeholder='Email'
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} style={{paddingTop: '10px', marginTop: '1rem'}}>
                <Col sm={14}>
                  <Input
                    type='text'
                    name={getFieldName('name')}
                    placeholder='Name'
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} style={{paddingTop: '10px', marginTop: '1rem'}}>
                <Col sm={14}>
                  <Input
                    type='password'
                    name={getFieldName('password')}
                    placeholder='Password'
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} style={{paddingTop: '10px', marginTop: '1rem'}}>
                <Col sm={14}>
                  <Input
                    type='password'
                    name={getFieldName('confirmPassword')}
                    placeholder='Confirm password'
                  />
                </Col>
              </Form.Group>
              <Row className={styles['login-button']}>
                <LoadingButton type='submit' loading={isSubmitting} label='Next'/>
              </Row>
            </Col>
          </Form>
        )}
      </Formik>
  );
};
