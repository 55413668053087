import {combineReducers} from 'redux';
import {UserActions, userPersistConfig, users} from './user';
import {persistReducer} from 'redux-persist';
import {RoleActions, roles} from './role';
import {VideoActions, videos} from './video';
import {homeText, HomeTextAction} from './homeText';
import {VideoRoleAssignmentActions, videoRoleAssignments} from './videoRoleAssignment';
import {videosUserWatched, VideoUserWatchedActions} from './videoUserWatched';
import {shiftActions, shifts} from './shift';
import {shiftVolunteerActions, shiftVolunteers} from './shiftVolunteer';
import {VolunteerGroupActions, volunteerGroups} from './volunteerGroup';
import {DocumentActions, documents} from './document';

export const entities = combineReducers({
  users: persistReducer(userPersistConfig, users) as unknown as typeof users,
  roles: roles,
  videos: videos,
  homeText: homeText,
  videoRoleAssignments: videoRoleAssignments,
  videosUserWatched: videosUserWatched,
  volunteerGroups: volunteerGroups,
  shifts: shifts,
  shiftVolunteers: shiftVolunteers,
  documents: documents
});

export type Entities  = ReturnType<typeof entities>;

export type EntitiesActions =
  UserActions |
  RoleActions |
  VideoActions |
  HomeTextAction |
  VideoRoleAssignmentActions |
  VideoUserWatchedActions |
  VolunteerGroupActions |
  shiftActions |
  shiftVolunteerActions |
  DocumentActions;
