import {api} from './index';
import {GeneralSmsAlert, GroupEmail} from '../pages/Export/HcsiTextAlerts';
import {DateRangeModalInterface} from '../pages/Configuration/CalendarManagement/CalendarManagement';

export async function getExport(report: string, range: DateRangeModalInterface) {
  return (await api.post(`/export/${report}`, range)).data;
}

export function downloadReport(reportName: string) {
  window.open(`${process.env.REACT_APP_API_URL}export/download/${reportName}`);
}

export async function sendShelterStatusSms(status: string) {
  return (await api.post(`/twilio/${status}`)).data;
}

export async function sendGeneralAlertSms(request: GeneralSmsAlert) {
  return (await api.post(`/twilio/general/`, request)).data;
}

export async function sendGroupEmail(request: GroupEmail) {
  return (await api.post('/User/groupEmail', request)).data;
}
