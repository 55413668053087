import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../../redux/types/WebState';
import {ConfirmationDialog} from '../../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import {Button, Col, Row} from 'react-bootstrap';
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next';
// @ts-ignore
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.js';
import {AccountStatus, User, userStore} from '../../../../../common/redux/entities/user';
import {makeUser, makeVolunteerGroup} from '../../../../../common/util/factory';
import {VolunteerGroup, volunteerGroupStore} from '../../../../../common/redux/entities/volunteerGroup';
import VolunteerGroupModal from '../../../../Profile/components/UserGroupPreferenceModal/VolunteerGroupModal';
import gridStyles from "../../../GridStyles.module.scss";

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function GroupGrid(props: Props) {
  const {groups, getUserNameById, getGroupById, actions: {upsertGroup, deleteGroup}} = props;
  const { SearchBar } = Search;
  const [isDeletingGroup, setIsDeletingGroup] = useState<VolunteerGroup | null>(null);
  const [editingVolunteerGroup, setEditingVolunteerGroup] = useState<VolunteerGroup | null>(null);

  const actionsFormatter = (cell: any, group: VolunteerGroup) => {
    return (
      <>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <IconButton
            icon='edit'
            size={'2x'}
            onClick={() => {
              setEditingVolunteerGroup(getGroupById(group.id));
            }}
            color={'#005A9C'}
          />
          <div>
            <IconButton
              icon={'trash-alt'}
              styles={{color: 'red', marginLeft: '.5rem'}}
              size={'2x'}
              iconToolTipText={'Delete Group'}
              onClick={() => setIsDeletingGroup(getGroupById(group.id))}
            />
          </div>
        </div>
      </>
    );
  };


  const groupLeaderFormatter = (cell: any, group: VolunteerGroup) => {
    return (
      <>
        {getUserNameById(group.leaderId).name}
      </>
    );
  };

  const groupCountFormatter = (cell: any, group: VolunteerGroup) => {
    return (
      <>
        {group.members.length}
      </>
    );
  };

  const columns: ColumnDescription[] = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true
    },
    {
      dataField: 'leaderId',
      text: 'Group Leader',
      formatter: groupLeaderFormatter,
      sort: true
    },
    {
      dataField: '',
      text: 'Number of Members',
      isDummyField: true,
      formatter: groupCountFormatter
    },
    {
      dataField: 'actionsColumn',
      text: '',
      isDummyField: true,
      formatter: actionsFormatter,
      headerStyle: () => {
        return { width: '5rem'};
      },
      style: () => {
        return { width: '5rem'};
      }
    }
  ];

  return (
    <ToolkitProvider
      keyField='id'
      data={groups}
      columns={columns}
      search={{
        searchFormatted: true
      }}
    >
      {(tableProps: any) => (
        <>
          <Row>
            <div style={{display: 'flex', flexGrow: 1}}>
              <SearchBar {...tableProps.searchProps} placeholder={'Search'}/>
            </div>
            <IconButton
              icon={'plus-circle'}
              size={'2x'}
              iconToolTipText={'Create Volunteer Group'}
              onClick={() => setEditingVolunteerGroup(makeVolunteerGroup(null))}
            />
          </Row>
          <BootstrapTable
            wrapperClasses={gridStyles['responsive-table-wrapper']}
            classes={gridStyles['table-auto']}
            rowStyle={{height: '100%'}}
            {...tableProps.baseProps}
          />
          {isDeletingGroup !== null && (
            <ConfirmationDialog
              onAccept={async () => {
                deleteGroup(isDeletingGroup?.id);
                setIsDeletingGroup(null);
              }}
              onDecline={async () => setIsDeletingGroup(null)}
              open={isDeletingGroup !== null}
              prompt='Are you sure you want to delete this group?'
              positiveText='Yes'
              negativeText='No'
              positiveVariant='success'
              negativeVariant='danger'
            />
          )}
          {editingVolunteerGroup ?
            <VolunteerGroupModal
              editable={true}
              userCreated={false}
              onSubmit={async (group: VolunteerGroup) => {
                await upsertGroup(group);
                setEditingVolunteerGroup(null);
              }}
              onCancel={() => setEditingVolunteerGroup(null)}
              volunteerGroup={editingVolunteerGroup}
            />
            : null}
        </>
      )}
    </ToolkitProvider>
  );

}

const mapStateToProps = (state: WebState) => ({
  getUserNameById: userStore.selectors.getByIdGuid(state),
  groups: volunteerGroupStore.selectors.getAsArray(state),
  getGroupById: volunteerGroupStore.selectors.getByIdGuid(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({ actions: bindActionCreators({
    upsertGroup: volunteerGroupStore.actions.upsert,
    deleteGroup: volunteerGroupStore.actions.delete
  }, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(GroupGrid);
