import React, {useEffect, useState} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../redux/types/WebState';
import {connect} from 'react-redux';
import {Video} from '../../common/redux/entities/video';
import {Col, Container, Row} from 'react-bootstrap';
import styles from './TrainingVideos.module.scss';
import {CenteredSpinner} from '../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {useMount} from '../../hooks/useMount';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../common/util/http';
import {userStore} from '../../common/redux/entities/user';
import {ListTable} from '../../components/util/lists/ListTable/ListTable';
import {ListRow} from '../../components/util/lists/ListRow/ListRow';
import TrainingVideoThumbnail from './components/TrainingVideoThumbnail/TrainingVideoThumbnail';
import TrainingVideo from './components/TrainingVideo';
import {ConfirmationDialog} from '../../components/util/ConfirmationDialog/ConfirmationDialog';
import {Link, Navigate} from 'react-router-dom';
import {RoutePaths} from '../../router/RoutePaths';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const TrainingVideos = (props: Props) => {
  const {videosToWatch, watchedVideos, currentUser, actions: {loadVideosToWatch, acceptTos}} = props;
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [watchingVideo, setWatchingVideo] = useState(null as any as Video);
  const [viewVideoModal, setViewVideoModal] = useState(false);
  const [viewTosModal, setViewTosModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');

  useMount(async () => {
    try {
      await loadVideosToWatch();
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load videos'}));
    }
    setLoading(false);
  });

  useEffect(() => {
    if(currentUser && !currentUser.tosAccepted) {
      setViewTosModal(true);
      setLoading(false);
      return;
    }
  }, [currentUser]);

  const videoClicked = (video: Video) => {
    setWatchingVideo(video);
    setViewVideoModal(true);
  };

  const renderRedirect = () => {
    if (redirectUrl.length !== 0) {
      return <Navigate to={redirectUrl} />;
    }
    return null;
  };

  const renderVideoItem = (video: Video, watched: boolean) => {
    return (
      <div className={styles['videos-list-item']} onClick={() => videoClicked(video)}>
        <div className={styles['thumbnail']}>
          <TrainingVideoThumbnail video={video} style={{maxWidth: '100%', maxHeight: '100%', aspectRatio: '1.5 / 1'}}/>
        </div>
        <div style={{width: '50%', display: 'flex', flexDirection: 'column', paddingLeft: '1rem'}}>
          <h2 style={{textOverflow: 'ellipsis', overflow: 'hidden', lineHeight: '3rem'}}>{video.title}</h2>
          <h6 style={{textOverflow: 'ellipsis', overflow: 'hidden',  lineHeight: '2rem'}}>{video.description}</h6>
          <div style={{marginTop: 'auto'}}>Length: {video.length}</div>
        </div>
        <div style={{textAlign: 'right', margin: 'auto', width: '20%', textOverflow: 'ellipsis', overflow: 'hidden'}}>
          <h3 style={watched ? {color: 'lightgreen'} : {color: 'lightgray'}}>
            {watched ? 'Complete' : 'Incomplete'}
          </h3>
        </div>
      </div>
    );
  };

  const renderVideoListItem = (video: Video, watched: boolean) => {
    return (
      <ListRow key={video.id} children={renderVideoItem(video, watched)} />
      );
  };

  const renderVideoList = (videos: Video[] | undefined, watched: boolean, displayEmpty: boolean) => {
    if(videos !== undefined && !(!displayEmpty && videos.length === 0)) {
      return (
        <>
            <ListTable
              items={videos}
              noItemsLabel={'No Required Videos To Watch'}
              renderItem={(video: Video) => renderVideoListItem(video, watched)}
              style={{display: 'flex', flexDirection: 'row', flex: '1'}}
            />
        </>
       );
    } else {
      return null;
    }
  };

  const tosModalPrompt = (
    <div>
      <div className={styles['modal-description']}>
        The video modules are meant to impart you with the knowledge and care necessary to effectively act as a White Flag Shelter volunteer.
        It is crucial that you retain this knowledge so that you may operate in a safe and effective manner with our clients.
        You cannot volunteer until you have completed all of your allocated trainings.
      </div>
      <h6 style={{display: 'flex', justifyContent: 'center'}}>
        You must accept the Terms of Service.
      </h6>
      <div style={{display: 'flex', justifyContent: 'center', marginTop: '1rem'}}>
        <Link to={RoutePaths.termsOfService} target={'_blank'}>
          Terms of Service
        </Link>
      </div>
    </div>
  );


  const renderContent = () => (
    <div className={styles['video-and-list-container']}>
      <Row>
        <Col>
          {renderVideoList(videosToWatch, false, true)}
        </Col>
        <br/>
        <Col>
          {renderVideoList(watchedVideos, true, false)}
        </Col>
      </Row>
      {viewVideoModal ?
        <TrainingVideo
          video={watchingVideo}
          onCancel={() => {
            setViewVideoModal(false);
            setWatchingVideo(null as any as Video);
          }}
        />
        : null}
      {viewTosModal && (
        <ConfirmationDialog
          onAccept={async () => {
            await acceptTos();
            setViewTosModal(false);
          }}
          onDecline={async () => setRedirectUrl(RoutePaths.home)}
          open={viewTosModal}
          prompt={tosModalPrompt}
          positiveText='Accept'
          negativeText='Decline'
          positiveVariant='success'
          negativeVariant='danger'
          title={'Accept Terms of Service'}
          titleStyles={{display: 'flex', justifyContent: 'center', marginTop: '1rem'}}
          promptStyles={{display: 'flex', justifyContent: 'center'}}
        />
      )}
    </div>
  );

  const renderTitleAndContent = () => (
    <>
      <div className={styles['page-title']}>
        <h2>White Flag Training Videos</h2>
      </div>
      {renderRedirect()}
      <div className={styles['page-body']}>
        {loading ?  <CenteredSpinner/> : (
          errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
            renderContent()
        )}
      </div></>
  );

  return (
    <div>
      {loading ?  <CenteredSpinner/> : (
        errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
          renderContent()
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadVideosToWatch: userStore.actions.loadVideosToWatch,
    acceptTos: userStore.actions.acceptTos
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  videosToWatch: userStore.selectors.getVideosToWatch(state),
  watchedVideos: userStore.selectors.getVideosWatched(state),
  currentUser: userStore.selectors.getCurrentUser(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingVideos);
