import React, {FormEvent, useState} from 'react';
import {useMount} from '../../../hooks/useMount';
import {AxiosError} from 'axios';
import {getErrorResponseMessage, handleAxiosError} from '../../../common/util/http';
import {Button, Card, Container, Col, Form as BSForm, Modal, Row} from 'react-bootstrap';
import styles from './CalendarManagement.module.scss';
import {CenteredSpinner} from '../../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../redux/types/WebState';
import {connect} from 'react-redux';
import VolunteerCalendar, {ShiftType} from '../../../components/util/VolunteerCalendar/VolunteerCalendar';
import {now} from '../../../util';
import {format, parseISO} from 'date-fns';
import {CalendarEventProps} from '../../../components/util/VolunteerCalendar/CalendarEventProps';
import VolunteerShiftCreationModal, {ShiftCreationInterface} from './components/ShiftCreationModal/ShiftCreationModal';
import {Shift, shiftStore} from '../../../common/redux/entities/shift';
import CalendarTitleAndLegendRow
  from '../../../components/util/VolunteerCalendar/CalendarTitleAndLegendRow/CalendarTitleAndLegendRow';
import IconButton from '../../../components/util/widgets/IconButton/IconButton';
// tslint:disable-next-line:no-import-side-effect
import 'react-datepicker/dist/react-datepicker.css';
import VolunteerShiftUpdateModal from './components/ShiftUpdateModal/ShiftUpdateModal';
import {ConfirmationDialog} from '../../../components/util/ConfirmationDialog/ConfirmationDialog';
import {downloadReport, getExport} from '../../../api/exportAndAlertApi';
import {Form, Formik, FormikErrors} from 'formik';
import {InputRow} from '../../../components/util/form-components/InputRow';
import DatepickerInput from '../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import {toTitleCase} from '../../../common/util/string';
import {boolean, date, number, object, string} from 'yup';
import {userStore} from '../../../common/redux/entities/user';
import ShiftDeletionModal from './components/ShiftDeletionModal/ShiftDeletionModal';

interface InitialStateProps {
  initialType: ShiftType;
}

export interface DateRangeModalInterface {
  startDate: string;
  endDate: string;
}

type Props = InitialStateProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

enum ECurrentModal {
  none,
  createShift,
  updateShift,
  deleteShift
}

function CalendarManagement(props: Props) {
  const {getShift, initialType, currentUser, actions: { loadMonth, deleteShift }} = props;

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [currentModal, setCurrentModal] = useState<ECurrentModal>(ECurrentModal.none);
  const [editingShift, setEditingShift] = useState<Shift | null>(null);
  const [currentShiftType, setCurrentShiftType] = useState(initialType);
  const [reportSubmitting, setReportSubmitting] = useState(false);
  const [reportDateRangeModal, setReportDateRangeModal] = useState('');
  const getFieldName = (name: keyof DateRangeModalInterface) => name;

  useMount(async () => {
    try {
      await loadMonth(now.toISOString());
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load calendar shifts'}));
    }
    setLoading(false);
  });

  const onEventClick = (shift: CalendarEventProps) => {
      setEditingShift(getShift(shift.id));
      setCurrentModal(ECurrentModal.updateShift);
  };

  const runExport = async (report: string, range: DateRangeModalInterface) => {
    setReportSubmitting(true);
    try {
      const reportName = await getExport(report, range);
      await downloadReport(reportName);
    } catch (e: AxiosError | any) {
      setErrorMessage(getErrorResponseMessage(e));

    }
    setReportSubmitting(false);
  };

  const shiftUpsertOnSubmit = async () => {
    setCurrentModal(ECurrentModal.none);
  };

  const shiftDeletionOnSubmit = async () => {
    setCurrentModal(ECurrentModal.none);
  };

  const renderModals = () => {
    if (currentModal === ECurrentModal.createShift) {
      return (
        <VolunteerShiftCreationModal
          type={currentShiftType}
          onSubmit={shiftUpsertOnSubmit}
          onCancel={() => setCurrentModal(ECurrentModal.none)}
        />
      );
    }

    if (currentModal === ECurrentModal.updateShift && editingShift) {
      return (
        <VolunteerShiftUpdateModal
          existingShift={editingShift}
          onSubmit={shiftUpsertOnSubmit}
          onCancel={() => {
            setCurrentModal(ECurrentModal.none);
            setEditingShift(null);
          }}
          onDelete={() => setCurrentModal(ECurrentModal.deleteShift)}
        />
      );
    }

    if (currentModal === ECurrentModal.deleteShift && editingShift) {
      return (
        <ShiftDeletionModal
          existingShift={editingShift}
          onSubmit={shiftDeletionOnSubmit}
          onCancel={() => {
            setCurrentModal(ECurrentModal.updateShift);
          }}
        />
      );
    }

    if(reportDateRangeModal) {
      return (
        <Modal backdropClassName={''} show={true}
               dialogClassName={styles['data-point-modal']} centered={true} onHide={() => null}>
          <Modal.Body>
            <Formik initialValues={{startDate: '', endDate: ''} as DateRangeModalInterface} validationSchema={DateRangeModalSchema} onSubmit={() => undefined}>
              {({values, validateForm, handleSubmit}) => {
                return (
                  <Form noValidate={true} onSubmit={async (e) => await exportModalSubmit(e, values, validateForm, handleSubmit)}>
                    <Row>
                      <Modal.Title style={{marginBottom: '1rem'}}>{toTitleCase(reportDateRangeModal)} Export</Modal.Title>
                    </Row>
                    <InputRow label={'Start Date'} columnSize={4} labelSize={3} style={{marginBottom: '1rem'}}>
                      <DatepickerInput name={getFieldName('startDate')} placeHolderText={'mm/dd/yyyy'}/>
                    </InputRow>
                    <InputRow label={'End Date'} columnSize={4} labelSize={3} style={{marginBottom: '1rem'}}>
                      <DatepickerInput name={getFieldName('endDate')} placeHolderText={'mm/dd/yyyy'}/>
                    </InputRow>
                    <BSForm.Group className={styles['form-buttons']} style={{justifyContent: 'flex-end', display: 'flex'}}>
                      <Button
                        onClick={() => setReportDateRangeModal('')}
                        variant={'danger'}
                        className={styles['close-button']}
                        style={{marginRight: '1rem'}}
                      >
                        Cancel</Button>
                      <Button variant={'success'} type='submit'>Submit</Button>
                    </BSForm.Group>
                  </Form>
                );
              }}
            </Formik>
          </Modal.Body>
        </Modal>
      );
      }
  };

  // @ts-ignore
  const DateRangeModalSchema = object<DateRangeModalInterface>({
    startDate: date().nullable()
      .required('A Start Date is required'),
    endDate: date().nullable().required('End date is required')
      .when('startDate', (startDate, schema) =>
        startDate && schema.min(startDate, 'End date must be the same as or after the shift start date'))
  });



  const exportModalSubmit = async (
    e: FormEvent<HTMLFormElement>,
    range: DateRangeModalInterface,
    validate: (data: DateRangeModalInterface) => Promise<FormikErrors<DateRangeModalInterface>>,
    handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    e.persist();
    e.preventDefault();
    const errors = await validate(range);
    if (Object.values(errors).length !== 0) {
      handleSubmit(e);
    } else {
      await runExport(reportDateRangeModal, range);
      setReportDateRangeModal('');
    }
  };

  const renderContent = () => (
    <Card className={styles['page-body']}>
        <CalendarTitleAndLegendRow
          title={`${currentShiftType === 'volunteer' ? 'Volunteer' : 'Meal'} Management`} showOpen={true} showClosed={true} showCoveredByUser={true}>
          <Row style={{alignItems: 'center', width: '100%', height: '100%', flexWrap: 'nowrap'}}>
            <Button
              className={styles['toggle-button']}
              onClick={() => setCurrentShiftType(currentShiftType === 'volunteer' ? 'catering' : 'volunteer')}
            >
              Show {currentShiftType === 'volunteer' ? 'meal' : 'volunteer'} calendar
            </Button>
              <Row style={{flexWrap: 'nowrap', flexGrow: '1', justifyContent: 'flex-end', alignItems: 'center'}}>
                {currentUser!.role.roleName === 'Administrator' ?
                  <>
                    <Button
                      onClick={() => setReportDateRangeModal('volunteer')}
                      className={styles['export-button']}
                      disabled={reportSubmitting}
                    >
                      Volunteer Export
                    </Button>
                    <Button
                      onClick={() => setReportDateRangeModal('catering')}
                      disabled={reportSubmitting}
                      className={styles['export-button']}
                    >
                      Meal Export
                    </Button>
                  </>
                  : null}
                <Button
                  onClick={() => setCurrentModal(ECurrentModal.createShift)}
                  disabled={currentModal !== ECurrentModal.none}
                  className={styles['export-button']}
                >
                  Create Shift
                </Button>
            </Row>
          </Row>
        </CalendarTitleAndLegendRow>
        <VolunteerCalendar
          shiftType={currentShiftType}
          showCovered={true}
          onEventClick={onEventClick}
        />
        {renderModals()}
    </Card>
  );

  return (
    <div>
        {loading ?  <CenteredSpinner/> : (
          errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
            renderContent()
        )}
    </div>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadMonth: shiftStore.actions.loadMonth,
    createShift: shiftStore.actions.create,
    deleteShift: shiftStore.actions.delete
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  shifts: shiftStore.selectors.volunteerShifts(state),
  getShift: shiftStore.selectors.getByIdGuid(state),
  currentUser: userStore.selectors.getCurrentUser(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(CalendarManagement);
