
import {connect} from 'react-redux';
import {mapIsUserToProps, mapIsUserToPropsType} from "../../../common/redux/entities/user";

type userRouteProps = mapIsUserToPropsType & {
  children: JSX.Element;
};

// TODO once manager role is implemented, create prop for whether managers can access
function userComponent({children, user}: userRouteProps) {
  return user ? children : null;
}

export default connect(mapIsUserToProps)(userComponent);
