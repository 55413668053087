import React from 'react';
import {Menu, MenuItem, SubMenu} from 'react-pro-sidebar';
import {RoutePaths} from "../../../router/RoutePaths";
import {User} from "../../../common/redux/entities/user";
import {Link} from "react-router-dom";
import AdministratorComponent from "../../util/roleComponent/AdministratorComponent";
import UserComponent from "../../util/roleComponent/UserComponent";
import VolunteerComponent from "../../util/roleComponent/ShelterManagerComponent";

export const GetMenuItemsForRole = (currentUser: User | null) => {

  const renderAdministratorComponents = () => (
    <>
      <AdministratorComponent>
        <MenuItem component={<Link to={RoutePaths.videoManagement}/>}> Trainings </MenuItem>
      </AdministratorComponent>
      <AdministratorComponent>
        <MenuItem component={<Link to={RoutePaths.calendarManagement}/>}> Calendar </MenuItem>
      </AdministratorComponent>
      <AdministratorComponent>
        <MenuItem component={<Link to={RoutePaths.documentManagement}/>}> Documents </MenuItem>
      </AdministratorComponent>
      <AdministratorComponent>
        <MenuItem component={<Link to={RoutePaths.userManagement}/>}> Users </MenuItem>
      </AdministratorComponent>
      <AdministratorComponent>
        <MenuItem component={<Link to={RoutePaths.groupManagement}/>}> Groups </MenuItem>
      </AdministratorComponent>
      <AdministratorComponent>
        <MenuItem component={<Link to={RoutePaths.alerts}/>}> Alerts </MenuItem>
      </AdministratorComponent>
    </>
  );

  const renderVolunteerComponents = () => (
    <>
      <VolunteerComponent>
        <MenuItem component={<Link to={RoutePaths.calendarManagement}/>}> Calendar </MenuItem>
      </VolunteerComponent>
      <VolunteerComponent>
        <MenuItem component={<Link to={RoutePaths.groupManagement}/>}> Groups </MenuItem>
      </VolunteerComponent>
      <VolunteerComponent>
        <MenuItem component={<Link to={RoutePaths.alerts}/>}> Alerts </MenuItem>
      </VolunteerComponent>
    </>
  );

  const renderUserComponents = () => (
    <>
      <UserComponent>
        <MenuItem component={<Link to={RoutePaths.trainingVideos}/>}> Training </MenuItem>
      </UserComponent>
      <UserComponent>
        <MenuItem component={<Link to={RoutePaths.volunteerCalendar}/>}> Volunteer </MenuItem>
      </UserComponent>
      <UserComponent>
        <MenuItem component={<Link to={RoutePaths.cateringCalendar}/>}> Catering </MenuItem>
      </UserComponent>
      <UserComponent>
        <MenuItem component={<Link to={RoutePaths.documents}/>}> Documents </MenuItem>
      </UserComponent>
    </>
  );

  return (
    <Menu menuItemStyles={{button: {color: '#FFFFFF', backgroundColor: '#005A9C', '&:hover': {backgroundColor: '#00AFFF'}}}}>
      <MenuItem component={<Link to={RoutePaths.home}/>}> Home </MenuItem>
      {renderAdministratorComponents()}
      {renderVolunteerComponents()}
      {renderUserComponents()}
    </Menu>
  );
};
