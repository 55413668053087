import {api} from './index';
import {Shift} from '../common/redux/entities/shift';
import {Dictionary} from '../common/util';
import {ShiftVolunteer} from '../common/redux/entities/shiftVolunteer';
import {
  CateringSignupInterface,
  VolunteerSignupInterface
} from '../pages/VolunteerSignup/VolunteerShiftSignupModal/ShiftSignupModal';

export async function loadShiftsForMonth(isoDate: string) {
  return (await api.get<Dictionary<Shift>>(`/shift/${isoDate}`)).data;
}

export interface ShiftUpsertInterface {
  id: string;
  title: string;
  startDate: string;
  endDate: string;
  volunteersNeeded: number;
  catering: boolean;
  description: string;
  businessName: string;
  shiftVolunteerIds: string[];
}

export async function upsertShift(request: ShiftUpsertInterface) {
  return (await api.post<Dictionary<Shift>>('/shift/upsert', request)).data;
}

export async function shiftSignup(id: string, request: VolunteerSignupInterface) {
  return (await api.post<Shift>(`/shift/signup/${id}`, request)).data;
}

export async function cateringShiftSignup(id: string, request: CateringSignupInterface) {
  return (await api.post<Shift>(`/shift/signup/catering/${id}`, request)).data;
}

export async function deleteShift(id: string) {
  return (await api.delete<Shift>(`/shift/delete/${id}`)).data;
}

export interface DeleteShiftRangeInterface {
  id: string;
  endDate?: Date;
}

export async function deleteShiftRange(request: DeleteShiftRangeInterface) {
  return (await api.post<Dictionary<Shift>>('shift/delete', request)).data;
}

export async function getGroupVolunteersOnShift(id: string) {
  return (await api.get<Dictionary<ShiftVolunteer>>(`/shift/${id}/group`)).data;
}

export async function leaveShift(id: string) {
  return (await api.delete<Shift>(`/shift/signup/${id}`)).data;
}
