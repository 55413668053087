import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities, selector} from '../selectors';

export interface VideoRoleAssignment {
  videoId: string;
  roleId: string;
}

const actions = createStandardActions(placeholder<VideoRoleAssignment>(), 'VIDEO_ROLE_ASSIGNMENT/SET', 'VIDEO_ROLE_ASSIGNMENT/SAVE');
const selectors = createStandardSelectors(placeholder<VideoRoleAssignment>(), s => getEntities(s).videoRoleAssignments);
export type VideoRoleAssignmentActions = GetActions<typeof actions>;
export const videoRoleAssignments = combineReducers({items: standardItemsReducer<VideoRoleAssignment, VideoRoleAssignmentActions>(actions)});
export const videoRoleAssignmentsStore = readonly({
  selectors: {
    ...selectors
  },
  actions: {
    ...actions
  }
});
