import React, {useState} from 'react';
import styles from './VolunteerCalendar.module.scss';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Calendar, EventProps, NavigateAction, View} from 'react-big-calendar';
// tslint:disable-next-line:no-import-side-effect
import 'react-big-calendar/lib/sass/styles.scss';
import localizer from './util/localizer';
import {WebState} from '../../../redux/types/WebState';
import {now} from './util/placeholderEvents';
import {AppTheme} from '../../../appTheme';
import {CalendarEventProps} from './CalendarEventProps';
import {format, getMonth, parseISO} from 'date-fns';
import {Shift, shiftStore} from '../../../common/redux/entities/shift';
import {userStore} from '../../../common/redux/entities/user';
import {Alert, Button, Col, Row} from 'react-bootstrap';
import {useMount} from '../../../hooks/useMount';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../../common/util/http';

export type ShiftType = 'volunteer' | 'catering';

interface CalendarTypeProps {
  shiftType: ShiftType;
  showCovered?: boolean;
  calendarEvents?: CalendarEventProps[];
  onEventClick?: (event: CalendarEventProps) => void;
  onNavigate?: (viewDate: Date, view: View, action: NavigateAction) => void;
  onView?: (view: View) => void;
  eventStyler?: (e: CalendarEventProps) => { className?: string | undefined; style?: React.CSSProperties };
}

type Props = CalendarTypeProps & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const VolunteerCalendar = (props: Props) => {
  const { calendarEvents, shiftType, showCovered, onEventClick, onNavigate, onView, eventStyler,
    volunteerShifts, cateringShifts, openVolunteerShifts, openCateringShifts, currentUser, actions: {loadMonth, loadUsersCalendarInformation}} = props;

  const [errorMessage, setErrorMessage] = useState('');

  useMount(async () => {
    try {
      await loadUsersCalendarInformation();
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load list of usernames into shifts'}));
    }
  });

  const [currentView, setCurrentView] = useState<View>('month');

  const dateFormatter = (isoDate: string) => {
    return format(parseISO(isoDate), 'p');
  };

  const titleFormatter = (event: Shift) => {
    if ((!event.title || event.title === '') && currentView !== 'month') {
      return '';
    }
    return event.title === '' ? `${dateFormatter(event.startDate)} - ${dateFormatter(event.endDate)}` : event.title;
  };

  const renderedEvents = (shiftType === 'volunteer' ? volunteerShifts : cateringShifts);

  const renderEvents = () => {
    const events = renderedEvents.map((event) => {
      return {
        id: event.id,
        shiftType: !event.catering ? 'volunteer' : 'meal',
        title: titleFormatter(event),
        start: new Date(parseISO(event.startDate).toLocaleString()),
        end: new Date(parseISO(event.endDate).toLocaleString()),
        volunteersNeeded: event.volunteersNeeded,
        volunteers: event.shiftVolunteers,
        open: event.shiftVolunteers?.length < event.volunteersNeeded
      } as CalendarEventProps;
    });
    if (!showCovered) {
      return events.filter(e => e.open || (currentUser && e.volunteers?.find(v => v.volunteerId === currentUser.id)));
    }
    return events;
  };


  const defaultOnNavigate = async (viewDate: Date, view: View, action: NavigateAction) => {
    const viewMonth = getMonth(viewDate);
    if (!(renderEvents().find(s => getMonth(s.start) === viewMonth)))
      await loadMonth(viewDate.toISOString());
  };

  const defaultOnView = (view: View) => {
    setCurrentView(view);
  };

  const defaultEventStyler = (s: CalendarEventProps) => {
    const userIsVolunteering = s.volunteers?.find(sv => sv.volunteerId === currentUser?.id);
    return {style: {
        backgroundColor: userIsVolunteering ? AppTheme.colors.calendarEventColors.currentUserCovered :
          s.open ? AppTheme.colors.calendarEventColors.open :
            AppTheme.colors.calendarEventColors.covered,
        title: ''
      }};
  };

  return (
    <>
      <div className={styles['scroll-container']}>
        <div className={styles['calendar']}>
          <Calendar
            localizer={localizer}
            events={calendarEvents ?? renderEvents()}
            defaultDate={now}
            defaultView={'month'}
            onSelectEvent={onEventClick}
            eventPropGetter={eventStyler ?? defaultEventStyler}
            onNavigate={defaultOnNavigate}
            onView={onView ?? defaultOnView}
            views={['month', 'week', 'day']}
            showAllEvents={true}
            components={{
              event: eventComponent,
              month: {event: monthEventComponent}
            }}
          />
        </div>
        <Alert show={!!errorMessage} variant='danger'>{errorMessage}</Alert>
      </div>
    </>
  );
};

const eventComponent: React.FC<EventProps<CalendarEventProps>> = ({event, title}) => {
  return (
    <Col style={{padding: '0', height: '100%'}}>
      <Row className={styles['rbc-event']} style={{padding: '2px 0 2px 0', textOverflow: 'ellipsis', overflow: 'hidden', height: '100%', fontSize: '0.9rem'}}>
        <div style={{position: 'relative', marginRight: 'auto', flexShrink: '1', overflow: 'hidden', textOverflow: 'ellipsis'}}>
        {title}
        </div>
        <div style={{position: 'relative', marginLeft: 'auto', marginTop: 'auto'}}>
          {event.volunteers?.length ?? 0} / {event.volunteersNeeded}
        </div>
      </Row>
    </Col>
  );
};

const monthEventComponent: React.FC<EventProps<CalendarEventProps>> = ({event, title}) => {
  return (
      <Row className={styles['rbc-event']} style={{textOverflow: 'ellipsis', flexWrap: 'nowrap', overflow: 'hidden', fontSize: '0.85rem'}}>
        <div style={{position: 'relative', marginRight: 'auto', flexShrink: '1', overflow: 'hidden', textOverflow: 'ellipsis'}}>
          {title}
        </div>
        <div style={{position: 'relative', paddingLeft: '5px', marginLeft: 'auto'}}>
          {event.volunteers?.length ?? 0} / {event.volunteersNeeded}
        </div>
      </Row>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadMonth: shiftStore.actions.loadMonth,
    loadUsersCalendarInformation: userStore.actions.loadUsersVolunteerCalendarInformation
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  volunteerShifts: shiftStore.selectors.volunteerShifts(state),
  cateringShifts: shiftStore.selectors.cateringShifts(state),
  openVolunteerShifts: shiftStore.selectors.openVolunteerShifts(state),
  openCateringShifts: shiftStore.selectors.openCateringShifts(state),
  currentUser: userStore.selectors.getCurrentUser(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(VolunteerCalendar);
