import React from 'react';
import {WebState} from '../../../../redux/types/WebState';
import {connect} from 'react-redux';
import styles from './CartIcon.module.scss';

interface Props {
  ammount?: number;
  shadow?: boolean;
}

export const CartIcon = (props: Props) => {
  const {ammount, shadow} = props;

  return (
    <>
      <div>
        {ammount !== null ?
          <span className={styles['dot']} style={shadow ? {boxShadow: '0px 3px 5px 1px rgba(0, 0, 0, 0.7)'} : undefined}>
            <div className={styles['number']}>{ammount}</div>
          </span> : null}
      </div>
    </>
  );
};

const mapStateToProps = (state: WebState) => ({});
export default connect(mapStateToProps)(CartIcon);
