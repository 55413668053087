import {
  mixed,
  number,
  object, string,
} from 'yup';
import {CreateVideoInterface} from './VideoModal';
import {getExtension} from '../../../../../util';

const maxFileSize = '1000';

// @ts-ignore
export const CreateVideoModalSchema = object<CreateVideoInterface>({
  title: string().required('Title is required'),
  description: string().required('Description is required'),
  videoFile: mixed().required('You must select a video')
    .test({
      message: 'Please provide a supported file type. Types are .mp4, .mov, and .avi',
      test: (file, context) => {
        let isValid = true;
        // @ts-ignore
        if(context.originalValue !== undefined) {
          // @ts-ignore
          isValid = ['.mp4', '.mov', '.avi'].includes(getExtension(context.originalValue));
        }
        if (!isValid) context?.createError();
        return isValid;
      }
    }),
  thumbnailFile: mixed()
    .test({
      message: 'Please provide a supported file type. Types are .png, .jpg, and .jpeg',
      test: (file, context) => {
        let isValid = true;
        // @ts-ignore
        if(context.originalValue !== undefined) {
          // @ts-ignore
          isValid = ['.png', '.jpeg', '.jpg'].includes(getExtension(context.originalValue));
        }
        if (!isValid) context?.createError();
        return isValid;
      }
    })
});
