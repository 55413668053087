import React, {FormEvent, useState} from 'react';
import {WebState} from '../../redux/types/WebState';
import {connect} from 'react-redux';
import {userStore} from '../../common/redux/entities/user';
import {bindActionCreators, Dispatch} from 'redux';
import styles from './HcsiTextAlerts.module.scss';
import {Container, Row, Col, Card, Button, Form as BSForm, Alert, Spinner} from 'react-bootstrap';
import {sendGeneralAlertSms, sendGroupEmail, sendShelterStatusSms} from '../../api/exportAndAlertApi';
import {AxiosError} from 'axios';
import {getErrorResponseMessage, handleAxiosError} from '../../common/util/http';
import {CenteredSpinner} from '../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {ConfirmationDialog} from '../../components/util/ConfirmationDialog/ConfirmationDialog';
import {makeGeneralSmsAlert, makeGroupEmail} from '../../common/util/factory';
import {Form, Formik, FormikErrors} from 'formik';
import {InputRow} from '../../components/util/form-components/InputRow';
import Input from '../../components/util/form-components/formik-inputs/Input/Input';
import DatepickerInput from '../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import {now} from '../../util';
import {useMount} from '../../hooks/useMount';
import {loadGroupEmailUserAndRoleData} from '../../common/redux/GroupEmailUserAndRoleData';
import {roleStore} from '../../common/redux/entities/role';
import Select, {MultiValue} from 'react-select';
import {DropdownOption} from '../../components/util/form-components/SearchableDropdown/SearchableDropdown';
import {GroupEmailSchema} from './GroupEmailSchema';
import {RedErrorMessage} from '../../components/util/form-components/RedErrorMessage/RedErrorMessage';
import {CenteredErrorMessage} from "../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage";
import {volunteerGroupStore} from "../../common/redux/entities/volunteerGroup";

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export interface GeneralSmsAlert {
  startDate: string;
  endDate: string;
  message: string;
}

export interface GroupEmail {
  userIds: string[];
  roleIds: string[];
  groupIds: string[];
  subject: string;
  title: string;
  body: string;
}

const HcsiTextAlerts = (props: Props) => {
  const {users, roles, groups, actions: {loadUserEmailsData}} = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [generalSmsErrorMessage, setGeneralSmsErrorMessage] = useState('');
  const [groupEmailErrorMessage, setGroupEmailErrorMessage] = useState('');
  const [submittingSms, setSubmittingSms] = useState(false);
  const [submittingEmail, setSubmittingEmail] = useState(false);
  const [confirmStatusUpdate, setConfirmStatusUpdate] = useState('');
  const [successStatus, setSuccessStatus] = useState('');
  const [successGeneralSmsStatus, setSuccessGeneralSmsStatus] = useState('');
  const [successGroupEmailStatus, setSuccessGroupEmailStatus] = useState('');
  const [loadingUserEmails, setLoadingUserEmails] = useState(true);
  const [loadingUserEmailsError, setLoadingUserEmailsError] = useState('');
  const getSmsFieldName = (name: keyof GeneralSmsAlert) => name;
  const getEmailFieldName = (name: keyof GroupEmail) => name;

  useMount(async () => {
    try {
      await loadUserEmailsData();
      setLoadingUserEmails(false);
    } catch (e: AxiosError | any) {
      setLoadingUserEmailsError(handleAxiosError(e));
    }
  });

  const processGeneralSmsAlertSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: GeneralSmsAlert,
    validate: (values: GeneralSmsAlert) => Promise<FormikErrors<GeneralSmsAlert>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setSubmittingSms(true);
    setSuccessGeneralSmsStatus('');
    setGeneralSmsErrorMessage('');
    e.persist();
    e.preventDefault();
    const errors = await validate(association);
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setSubmittingSms(false);
    } else {
      try {
        await sendGeneralAlertSms(association);
        setSuccessGeneralSmsStatus('Text Message successfully sent.');
      } catch (e: AxiosError | any) {
        setGeneralSmsErrorMessage(handleAxiosError(e));
      }
    }
    setSubmittingSms(false);
  };

  const processGroupEmailSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: GroupEmail,
    validate: (values: GroupEmail) => Promise<FormikErrors<GroupEmail>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setSubmittingEmail(true);
    setSuccessGroupEmailStatus('');
    setGroupEmailErrorMessage('');
    e.persist();
    e.preventDefault();
    const errors = await validate(association);
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setSubmittingSms(false);
    } else {
      try {
        await sendGroupEmail(association);
        setSuccessGroupEmailStatus('Group email successfully sent.');
      } catch (e: AxiosError | any) {
        setGroupEmailErrorMessage(handleAxiosError(e));
      }
    }
    setSubmittingEmail(false);
  };

  const shelterStatusNotification = async (status: string) => {
    setSubmittingSms(true);
    setErrorMessage('');
    setSuccessStatus('');
    try {
      await sendShelterStatusSms(status);
      setSuccessStatus(`Shift Volunteers have been sent a text. Status: ${status.toUpperCase()}`);
    } catch (e: AxiosError | any) {
      setErrorMessage(getErrorResponseMessage(e));
      setSuccessStatus('');
    }
    setConfirmStatusUpdate('');
    setSubmittingSms(false);
  };


  const renderContent = () => {

    return (
      <Row style={{flexGrow: 1, height: '100%'}}>
        <Col>
          <Card className={styles['page-body']} style={{borderLeftWidth: 0, borderRightWidth: 0}}>
            <Card.Body style={{display: 'flex', justifyContent: 'center'}}>
              <Col style={{display: 'flex', flex: '0.75', flexDirection: 'column'}}>
                <Row style={{display: 'flex'}}>
                  <Col style={{padding: '0'}}>
                    <h1 style={{display: 'flex', justifyContent: 'center'}}>Shelter Status</h1>
                    <Row style={{justifyContent: 'center', marginTop: '1rem', marginBottom: '1rem'}}>
                      <Button
                        onClick={() => setConfirmStatusUpdate('Closed')}
                        variant={'danger'}
                        className={styles['close-button']}
                        style={{marginRight: '1rem'}}
                        disabled={submittingSms}
                      >
                        Closed
                      </Button>
                      <Button
                        onClick={() => setConfirmStatusUpdate('Open')}
                        variant={'success'}
                        className={styles['close-button']}
                        disabled={submittingSms}
                      >
                        Open
                      </Button>
                    </Row>
                    {errorMessage ? <Alert style={{margin: 'auto', display: 'flex', justifyContent: 'center', width: '40%'}}
                                           variant='danger'>{errorMessage}</Alert> : null}
                    {successStatus ? <Alert style={{margin: 'auto', display: 'flex', justifyContent: 'center', width: '40%'}}
                                            variant='success'>{successStatus}</Alert> : null}
                  </Col>
                </Row>
                <Row style={{justifyContent: 'center', marginTop: '1rem', marginBottom: '10%'}}>
                  <Col>
                    <h1 style={{display: 'flex', justifyContent: 'center'}}>Custom Alert</h1>
                    <Formik initialValues={makeGeneralSmsAlert()} onSubmit={() => undefined}>
                      {({values, validateForm, handleSubmit}) => {
                        return (
                          <Form noValidate={true} onSubmit={(e) => processGeneralSmsAlertSubmit(e, values, validateForm, handleSubmit)}>
                            <InputRow label={'Start Date'} labelSize={2} columnSize={10} style={{paddingTop: '10px', justifyContent: 'center'}}>
                              <DatepickerInput
                                showTimeInput={false}
                                name={getSmsFieldName('startDate')}
                                minDateTime={now}
                                placeHolderText={'mm/dd/yyyy'}
                              />
                            </InputRow>
                            <InputRow label={'End Date'} labelSize={2} columnSize={10} style={{paddingTop: '10px', justifyContent: 'center'}}>
                              <DatepickerInput
                                showTimeInput={false}
                                name={getSmsFieldName('endDate')}
                                minDateTime={now}
                                placeHolderText={'mm/dd/yyyy'}
                              />
                            </InputRow>
                            <InputRow label={'Message'} labelSize={2} columnSize={10} style={{paddingTop: '10px', justifyContent: 'center'}}>
                              <Input name={getSmsFieldName('message')} type={'textarea'}/>
                            </InputRow>
                            <Row style={{justifyContent: 'flex-end'}}>
                              <BSForm.Group className={styles['form-buttons']}>
                                {!submittingSms ?
                                  <Button variant={'success'} type='submit'>
                                    Submit
                                  </Button> : null}
                              </BSForm.Group>
                            </Row>
                          </Form>
                        );
                      }}
                    </Formik>
                    {successGeneralSmsStatus ?
                      <Alert style={{margin: 'auto', display: 'flex', justifyContent: 'center', width: '40%'}}
                             variant='success'>{successGeneralSmsStatus}
                      </Alert> : null}
                    {generalSmsErrorMessage ? <Alert style={{margin: 'auto', display: 'flex', justifyContent: 'center', width: '40%'}}
                                           variant='danger'>{generalSmsErrorMessage}</Alert> : null}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col style={{padding: '0'}}>
                    <h1 style={{display: 'flex', justifyContent: 'center'}}>Send Email Alert</h1>
                    <Formik initialValues={makeGroupEmail()} validationSchema={GroupEmailSchema} onSubmit={() => undefined}>
                      {({values, validateForm, handleSubmit, setFieldValue}) => {
                        return (
                          <Form noValidate={true} onSubmit={(e) => processGroupEmailSubmit(e, values, validateForm, handleSubmit)}>
                            <InputRow label={'Users'} labelSize={2} columnSize={10} style={{paddingTop: '10px', justifyContent: 'center'}}>
                              {loadingUserEmails ?
                                <Spinner animation='border' role='status'>
                                  <span className='sr-only'>Loading...</span>
                                </Spinner> : <>
                                    <Select
                                    name={getEmailFieldName('userIds')}
                                    isMulti={true}
                                    onChange={(option: MultiValue<DropdownOption>) =>
                                      setFieldValue(getEmailFieldName('userIds'), option.map(item => item.value)
                                      )}
                                    options={users.map((user) => ({
                                      value: user.id,
                                      label: `${user.name} (${user.email})`
                                    }))}
                                  />
                                  <RedErrorMessage name={getEmailFieldName('userIds')}/>
                                </>
                              }
                            </InputRow>
                            <InputRow label={'Roles'} labelSize={2} columnSize={10} style={{paddingTop: '10px', justifyContent: 'center'}}>
                              {loadingUserEmails ?
                                <Spinner animation='border' role='status'>
                                  <span className='sr-only'>Loading...</span>
                                </Spinner> : <>
                                  <Select
                                    name={getEmailFieldName('roleIds')}
                                    isMulti={true}
                                    onChange={(option: MultiValue<DropdownOption>) =>
                                      setFieldValue(getEmailFieldName('roleIds'), option.map(item => item.value)
                                      )}
                                    options={roles.map((role) => ({value: role.id, label: role.roleName}))}
                                  />
                                  <RedErrorMessage name={getEmailFieldName('roleIds')}/>
                                </>
                              }
                            </InputRow>
                            <InputRow label={'Groups'} labelSize={2} columnSize={10} style={{paddingTop: '10px', justifyContent: 'center'}}>
                              {loadingUserEmails ?
                                <Spinner animation='border' role='status'>
                                  <span className='sr-only'>Loading...</span>
                                </Spinner> : <>
                                  <Select
                                    name={getEmailFieldName('groupIds')}
                                    isMulti={true}
                                    onChange={(option: MultiValue<DropdownOption>) =>
                                      setFieldValue(getEmailFieldName('groupIds'), option.map(item => item.value)
                                      )}
                                    options={groups.map((group) => ({value: group.id, label: group.name}))}
                                  />
                                  <RedErrorMessage name={getEmailFieldName('groupIds')}/>
                                </>
                              }
                            </InputRow>
                            <InputRow
                              label={'Subject'} requiredAsterisk={true}
                              labelSize={2} columnSize={10}
                              style={{paddingTop: '10px', justifyContent: 'center'}}
                            >
                              <Input name={getEmailFieldName('subject')}/>
                            </InputRow>
                            <InputRow label={'Title Text'} labelSize={2} columnSize={10} style={{paddingTop: '10px', justifyContent: 'center'}}>
                              <Input name={getEmailFieldName('title')}/>
                            </InputRow>
                            <InputRow
                              label={'Body'} requiredAsterisk={true}
                              labelSize={2} columnSize={10}
                              style={{paddingTop: '10px', justifyContent: 'center'}}
                            >
                              <Input name={getEmailFieldName('body')} type={'textarea'} rows={12}/>
                            </InputRow>
                            <Row style={{justifyContent: 'flex-end'}}>
                              <div style={{ display: 'flex', flex: '1', justifyContent: 'center', paddingTop: '10px', paddingLeft: '40px'}}>
                                {successGroupEmailStatus ?
                                  <Alert variant='success'>
                                    {successGroupEmailStatus}
                                  </Alert> : null}
                                {groupEmailErrorMessage ?
                                  <Alert variant='danger'>
                                    {groupEmailErrorMessage}
                                  </Alert> : null}
                              </div>
                              <BSForm.Group className={styles['form-buttons']}>
                                {submittingEmail ?
                                  <Spinner animation='border' role='status'>
                                    <span className='sr-only'>Loading...</span>
                                  </Spinner> :
                                  <Button variant={'success'} type='submit'>
                                    Submit
                                  </Button>
                                }
                              </BSForm.Group>
                            </Row>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Col>
                </Row>
              </Col>
            </Card.Body>
          </Card>
        </Col>
        {confirmStatusUpdate && (
          <ConfirmationDialog
            onAccept={async () => {
              await shelterStatusNotification(confirmStatusUpdate);
            }}
            onDecline={async () => {
              setConfirmStatusUpdate('');
              setSubmittingSms(false);
            }}
            open={confirmStatusUpdate !== ''}
            prompt={`Send all Volunteers signed up for shifts on ${new Date().toLocaleDateString()}
            a text alert informing them that the shelter is ${confirmStatusUpdate.toUpperCase()}?`}
            positiveText='Yes'
            negativeText='No'
            positiveVariant='success'
            negativeVariant='danger'
          />
        )}
      </Row>
    );
  };

  return errorMessage ? <CenteredErrorMessage message={errorMessage} /> : renderContent();
};

const mapStateToProps = (state: WebState) => ({
  users: userStore.selectors.getAsArray(state),
  roles: roleStore.selectors.getAsArray(state),
  groups: volunteerGroupStore.selectors.getAsArray(state),
  getRoleById: roleStore.selectors.getByIdGuid(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    updateUserPreferences: userStore.actions.updateUserPreferences,
    loadUserEmailsData: loadGroupEmailUserAndRoleData
  }, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(HcsiTextAlerts);
