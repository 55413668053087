import React, {FormEvent, useState} from 'react';
import styles from './ShiftCreationModal.module.scss';
import {Alert, Button, Col, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../../redux/types/WebState';
import {connect} from 'react-redux';
import {InputRow} from '../../../../../components/util/form-components/InputRow';
import Input from '../../../../../components/util/form-components/formik-inputs/Input/Input';
import {Form, Formik, FormikErrors} from 'formik';
import {ShiftCreationModalSchema} from './ShiftCreationModalSchema';
import {creationLabelSize} from '../index';
import DatepickerInput
  from '../../../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import {now, splitHTMLTimeInput} from '../../../../../util';
import {makeShiftCreationRequest} from '../../../../../common/util/factory';
import {shiftStore} from '../../../../../common/redux/entities/shift';
import {ShiftUpsertInterface} from '../../../../../api/shiftManagementApi';
import CheckBoxInput from '../../../../../components/util/form-components/formik-inputs/CheckBoxInput/CheckBoxInput';
import {ShiftType} from '../../../../../components/util/VolunteerCalendar/VolunteerCalendar';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../../../../common/util/http';

export interface ShiftCreationInterface {
  id: string;
  title: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  volunteersNeeded: number;
  catering: boolean;
  description: string;
  businessName: string;
}

type Props = {
  type: ShiftType;
  onSubmit: () => void;
  onCancel: () => void;
} & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const ShiftCreationModal = (props: Props) => {
  const {type, onSubmit, onCancel, actions: {createShift}} = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const getFieldName = (name: keyof ShiftCreationInterface) => name;
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');


  const setHoursAndMinutes = (date: Date, time: string) => {
    const parts = (time as any).split(':');
    date.setHours(parts[0]);
    date.setMinutes(parts[1]);
    return date;
  };

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    data: ShiftCreationInterface,
    validate: (data: ShiftCreationInterface) => Promise<FormikErrors<ShiftCreationInterface>>,
    handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void
  ) => {
    setIsSubmitting(true);
    setErrorMessage('');
    e.persist();
    e.preventDefault();
    const errors = await validate(data);
    if (Object.values(errors).length !== 0) {
      handleSubmit(e);
      setIsSubmitting(false);
    } else {
      try {
        const {startDate, endDate, startTime, endTime, ...filteredData} = data;
        const request: ShiftUpsertInterface = {
          ...filteredData,
          startDate: setHoursAndMinutes(startDate as any, startTime),
          endDate: setHoursAndMinutes(endDate as any, endTime)
        } as any as ShiftUpsertInterface;
        await createShift(request);
        await onSubmit();
      } catch (e: AxiosError | any) {
        setErrorMessage(handleAxiosError(e));
      }
    }
    setIsSubmitting(false);
  };

  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={onCancel} variant={'danger'}
                  className={styles['close-button']}>
            Cancel
          </Button>
        }
        {!isSubmitting ? <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };

  return (
    <Modal show={true} className={styles['on-top']} size={'lg'} centered={true}>
      <Modal.Body>
        <Formik<ShiftCreationInterface>
          initialValues={makeShiftCreationRequest(type)}
          validationSchema={ShiftCreationModalSchema}
          validateOnChange={true}
          onSubmit={() => undefined}
        >
          {({values, validateForm, handleSubmit}) => (
            <Form noValidate={true} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
              <Row>
                <Col>
                  <h4>Create a {type === 'volunteer' ? 'volunteer' : 'meal catering'} shift</h4>
                </Col>
              </Row>
              <InputRow
                label={'Shift Title'}
                labelSize={creationLabelSize}
                columnSize={5}
                style={{paddingTop: '10px'}}
              >
                <Input name={getFieldName('title')}/>
              </InputRow>
              <InputRow
                label={'Shift Date'}
                labelSize={creationLabelSize}
                columnSize={3}
                style={{paddingTop: '10px'}}
                requiredAsterisk={true}
              >
                <DatepickerInput
                  name={getFieldName('startDate')}
                  showTimeSelect={false}
                  minDateTime={now}
                  placeHolderText={'mm/dd/yyyy'}
                />
              </InputRow>
              <InputRow
                label={'Repeat Shift Until'}
                labelSize={creationLabelSize}
                columnSize={3}
                style={{paddingTop: '10px'}}
                colStyle={{paddingTop: '10px'}}
              >
                <DatepickerInput
                  name={getFieldName('endDate')}
                  showTimeSelect={false}
                  minDateTime={now}
                  placeHolderText={'mm/dd/yyyy'}
                />
              </InputRow>
              <InputRow label={'Shift Time'} labelSize={creationLabelSize} columnSize={8} style={{paddingTop: '10px'}} requiredAsterisk={true}>
                <Row style={{marginLeft: '0'}}>
                  <Col lg={4} style={{paddingLeft: '0'}}>
                    <Input name={getFieldName('startTime')} type={'time'}/>
                  </Col>
                  <div style={{paddingTop: '5px'}}>
                    to
                  </div>
                  <Col lg={4} style={{paddingRight: '0'}}>
                    <Input name={getFieldName('endTime')} type={'time'}/>
                  </Col>
                </Row>
              </InputRow>
              {type === 'volunteer' ?
                (<InputRow label={'Volunteers Needed'} labelSize={creationLabelSize} columnSize={2} style={{paddingTop: '10px'}}
                           requiredAsterisk={true}>
                    <Input name={getFieldName('volunteersNeeded')} type={'number'}/>
                  </InputRow> ) : null
              }
              {errorMessage !== '' ?
                <div style={{marginTop: '1rem'}}>
                  <Alert variant='danger'>{errorMessage}</Alert>
                </div>
                : null}
              <Row>
                <BSForm.Group className={styles['form-buttons']}>
                  {renderButtons()}
                </BSForm.Group>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    createShift: shiftStore.actions.create
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({ calendarEvents: shiftStore.selectors.getAsArray(state)});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftCreationModal);

