import {object} from 'yup';
import * as Yup from 'yup';

export const usernameAndPasswordFormSchema = object({
  email: Yup.string()
    .email('Email is not a valid format')
    .required('Email is required'),
  name: Yup.string()
    .required('Name is required'),
  password: Yup.string()
    .required('Password is required'),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .is([Yup.ref('password')], 'Passwords do not match')
});
