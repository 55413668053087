import { GetActions, placeholder, readonly} from '../utils';
import {
  createStandardSelector,
  getEntities,
} from '../selectors';
import {createAction, createReducer} from 'typesafe-actions';

export type HomeText = {
  value: string;
};

const actions = {
  set: createAction('HOME_TEXT/SET_HOME_TEXT')<HomeText>()
};

const selectors = createStandardSelector(placeholder<HomeText>(), s => getEntities(s).homeText);
export type HomeTextAction = GetActions<typeof actions>;
export const homeText = createReducer<HomeText, HomeTextAction>({value: ''})
  .handleAction(actions.set, (state, action) => action.payload);

export const homeTextStore = readonly({
  selectors: { ...selectors },
  actions: {
    ...actions
  }
});
