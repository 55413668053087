import {CheckBox} from '../../../components/util/form-components/CheckBox';
import React, {useEffect, useState} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {User, userStore} from '../../../common/redux/entities/user';
import {WebState} from '../../../redux/types/WebState';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';
import {ConfirmationDialog} from '../../../components/util/ConfirmationDialog/ConfirmationDialog';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const TwillioNotificationCheckBox = (props: Props) => {
  const {currentUser, actions: {toggleGenericTwillioNotifications}} = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [secondsUntilEnabled, setSecondsUntilEnabled] = useState(0);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (secondsUntilEnabled) {
      setTimeout(() => setSecondsUntilEnabled(secondsUntilEnabled - 1), 1000);
    }
  }, [secondsUntilEnabled]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await toggleGenericTwillioNotifications();
    // setTimeout used to prevent spamming
    setTimeout(() => setIsSubmitting(false), 3000);
    setSecondsUntilEnabled(3);
    setShowModal(false);
  };

  if (currentUser) {
    return (
      <>
        <CheckBox
          label={`I would like to receive text notifications on whether the shelter is opened or closed each day${secondsUntilEnabled ? ` (${secondsUntilEnabled} seconds before re-enabled)` : ''}`}
          style={{fontWeight: 'bold'}}
          onChange={() => setShowModal(true)}
          value={!showModal ? currentUser.receiveTextNotifications : !currentUser.receiveTextNotifications}
          disabled={isSubmitting}
        />
        {showModal ?
          <ConfirmationDialog
            open={showModal}
            onAccept={handleSubmit}
            onDecline={async () => setShowModal(false)}
            prompt={`By ${currentUser.receiveTextNotifications ? 'un' : ''}checking this box, you agree to ${currentUser.receiveTextNotifications ? 'no longer ' : ''}receive text notifications / updates on that status of White Flag. This does NOT indicate that you are registered to volunteer`}
            positiveText='Yes'
            negativeText='No'
            positiveVariant='success'
            negativeVariant='danger'
          /> : null}
      </>
    );
  }
  return null;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    toggleGenericTwillioNotifications: userStore.actions.toggleGenericTwillioNotifications
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  currentUser: userStore.selectors.getCurrentUser(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(TwillioNotificationCheckBox);
