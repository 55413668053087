import React, {FormEvent, useState} from 'react';
import {connect} from 'react-redux';
import styles from './VideoModal.module.scss';
import {Form, Formik, FormikErrors} from 'formik';
import {Button, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import {InputRow} from '../../../../../components/util/form-components/InputRow';
import Input from '../../../../../components/util/form-components/formik-inputs/Input/Input';
import {WebState} from '../../../../../redux/types/WebState';
import {makeRoleList, makeUpdateVideoRequest} from '../../../../../common/util/factory';
import {getBase64} from '../../../../../util';
import {UpdateVideoModalSchema} from './UpdateVideoModalSchema';
import {Role, roleStore} from '../../../../../common/redux/entities/role';
import CheckBoxInput from '../../../../../components/util/form-components/formik-inputs/CheckBoxInput/CheckBoxInput';
import {RoleAssignments} from './VideoModal';
import {useMount} from '../../../../../hooks/useMount';

export interface UpdateVideoInterface {
  id: string;
  title: string;
  description: string;
  thumbnailFile: string | null;
  videoRoleAssignments: RoleAssignments[];
}

type Props = {
  onCancel: () => void;
  onSubmit: (video: UpdateVideoInterface) => void;
  editable: boolean;
  onTop?: boolean;
  existingVideo: UpdateVideoInterface;
} & ReturnType<typeof mapStateToProps>;

function UpdateVideoModal(props: Props) {
  const {onTop, onSubmit, editable, onCancel, existingVideo, roles} = props;
  const getFieldName = (name: keyof UpdateVideoInterface) => name;
  const [thumbnailFileData, setThumbnailFileData] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assignedRoles, setAssignedRoles] = useState(makeRoleList());



  const mapAssignmentsToCheckboxes = () => {
    const assignments = (existingVideo.videoRoleAssignments.map(ar => {
      return {
        roleId: ar.roleId
      };
    }));
    setAssignedRoles(assignments);

  };
  useMount(() => {
    mapAssignmentsToCheckboxes();
  });

  async function encodeThumbnail() {
    if(thumbnailFileData !== '') {
      return await getBase64(thumbnailFileData as any as File);
    } else {
      return null;
    }
  }

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: UpdateVideoInterface,
    validate: (values: UpdateVideoInterface) => Promise<FormikErrors<UpdateVideoInterface>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setIsSubmitting(true);
    e.persist();
    e.preventDefault();
    const errors = await validate(association);
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setIsSubmitting(false);
    } else {
      const request: UpdateVideoInterface = {
        id: association.id,
        title: association.title,
        description: association.description,
        thumbnailFile: await encodeThumbnail(),
        videoRoleAssignments: assignedRoles
      };
      onSubmit(request);
    }
  };

  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={onCancel} variant={editable ? 'danger' : 'info'}
                  className={styles['close-button']}>
            {editable ? 'Cancel' : 'Close'}
          </Button>
        }
        {!isSubmitting ? editable && <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };

  const isCheckboxChecked = (id: string) => {
    if (assignedRoles.filter(ar => ar.roleId === id).length > 0) {
      return true;
    }
    return false;
  };


  const renderRolesCheckboxes = () => roles.map((role: Role) => {
    if(role.roleName === 'Administrator') return null;
    return (
      <div key={role.id}>
        <CheckBoxInput name={role.id} type={'checkbox'} label={role.roleName}
                       checked={isCheckboxChecked(role.id)}
                       onChange={(checked: boolean) => {
          if(checked) {
            const newAssignedRole: RoleAssignments = {
              roleId: role.id
            };
            setAssignedRoles([newAssignedRole, ...assignedRoles]);
          } else {
            const newRoles = assignedRoles.filter((r) => r.roleId !== role.id);
            setAssignedRoles([...newRoles]);
          }
        }}/>
      </div>
    );
  });

  return (
    <Modal backdropClassName={onTop ? styles['on-top'] : ''} show={true} size='lg' centered={true} onHide={() => null}>
      <Modal.Body>
        <Formik initialValues={existingVideo !== null ? existingVideo as UpdateVideoInterface : makeUpdateVideoRequest()}
                validationSchema={UpdateVideoModalSchema}
                onSubmit={() => undefined}>
          {({values, validateForm, handleSubmit}) => {
            return (
              <Form noValidate={true} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                <Modal.Title>Update Video</Modal.Title>
                <InputRow label={'Title'} columnSize={5} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('title')}/>
                </InputRow>
                <InputRow label={'Description'} columnSize={5} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('description')}/>
                </InputRow>
                <InputRow label={'Choose Thumbnail'} columnSize={5} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('thumbnailFile')} type={'file'} setFormDataState={setThumbnailFileData}/>
                </InputRow>
                <InputRow label={'Assign Roles Required To Watch'} labelSize={3} columnSize={5} style={{paddingTop: '10px'}}>
                  {renderRolesCheckboxes()}
                </InputRow>
                <Row>
                  <BSForm.Group className={styles['form-buttons']}>
                    {renderButtons()}
                  </BSForm.Group>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
const mapStateToProps = (state: WebState) => ({
  roles: roleStore.selectors.getAsArray(state)
});
export default connect(mapStateToProps)(UpdateVideoModal);
