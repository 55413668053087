import React, {FormEvent, useState} from 'react';
import styles from './ShiftDeletionModal.module.scss';
import {Alert, Button, Col, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../../redux/types/WebState';
import {connect} from 'react-redux';
import {InputRow} from '../../../../../components/util/form-components/InputRow';
import {Form, Formik, FormikErrors} from 'formik';
import {deletionLabelSize} from '../index';
import DatepickerInput
  from '../../../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import {makeShiftDeletionRequest} from '../../../../../common/util/factory';
import {Shift, shiftStore} from '../../../../../common/redux/entities/shift';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../../../../common/util/http';
import ShiftDeletionModalSchema from './ShiftDeletionModalSchema';
import InformationLabel from '../../../../../components/util/form-components/InformationLabel/InformationLabel';
import {format, parseISO} from 'date-fns';
import {now} from '../../../../../util';

export interface ShiftDeletionModalInterface {
  id: string;
  startDate: Date;
  endDate: string;
}

type Props = {
  existingShift: Shift;
  onSubmit: () => void;
  onCancel: () => void;
} & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const ShiftDeletionModal = (props: Props) => {
  const {existingShift, onSubmit, onCancel, actions: {deleteShift, deleteShiftRange}} = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const getFieldName = (name: keyof ShiftDeletionModalInterface) => name;
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');

  const shiftEndDateHours = () => {
    return parseISO(existingShift.endDate).getHours();
  };

  const shiftEndDateMinutes = () => {
    return parseISO(existingShift.endDate).getMinutes();
  };

  const getShiftEndDateTime = (endDate: Date) => {
    endDate.setHours(shiftEndDateHours());
    endDate.setMinutes(shiftEndDateMinutes());
    return endDate;
  };

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    data: ShiftDeletionModalInterface,
    validate: (data: ShiftDeletionModalInterface) => Promise<FormikErrors<ShiftDeletionModalInterface>>,
    handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void
  ) => {
    setIsSubmitting(true);
    setErrorMessage('');
    e.persist();
    e.preventDefault();
    const errors = await validate(data);
    if (Object.values(errors).length !== 0) {
      handleSubmit(e);
    } else {
      try {
        if (data.endDate && data.endDate !== '') {
          await deleteShiftRange({
            id: data.id, endDate: getShiftEndDateTime(data.endDate as any)
          });
        } else {
          await deleteShift(data.id);
        }
        await onSubmit();
      } catch (e: AxiosError | any) {
        setErrorMessage(handleAxiosError(e));
      }
    }
    setIsSubmitting(false);
  };

  const renderedDate = () => {
    return `${format(parseISO(existingShift.startDate), 'MM/dd/yyyy')} - ${format(parseISO(existingShift.endDate), 'MM/dd/yyyy')}`;
  };

  const renderedTime = () => {
    return `${format(parseISO(existingShift.startDate), 'p')} - ${format(parseISO(existingShift.endDate), 'p')}`;
  };

  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={onCancel} variant={'danger'}
                  className={styles['close-button']}>
            Cancel
          </Button>
        }
        {!isSubmitting ? <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };

  return (
    <Modal show={true} className={styles['on-top']} size={'lg'} centered={true}>
      <Modal.Body>
        <Formik<ShiftDeletionModalInterface>
          initialValues={makeShiftDeletionRequest(existingShift)}
          validationSchema={ShiftDeletionModalSchema}
          validateOnChange={true}
          onSubmit={() => undefined}
        >
          {({values, validateForm, handleSubmit}) => (
            <Form noValidate={true} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
              <Row>
                <Col>
                  <h4>Delete {existingShift.catering ? 'meal catering' : 'volunteer'} shifts</h4>
                </Col>
              </Row>
              <InformationLabel label={'Shift Date:'} labelSize={deletionLabelSize} data={renderedDate()} style={{paddingTop: '20px'}}/>
              <InformationLabel label={'Shift Time:'} labelSize={deletionLabelSize} data={renderedTime()} style={{paddingTop: '20px'}}/>
              <InputRow label={'Delete Until:'} labelSize={deletionLabelSize} columnSize={4} style={{paddingTop: '10px'}}>
                <DatepickerInput name={getFieldName('endDate')} minDateTime={now} placeHolderText={'mm/dd/yyyy'}/>
              </InputRow>
              {errorMessage !== '' ?
                <div style={{marginTop: '1rem'}}>
                  <Alert variant='danger'>{errorMessage}</Alert>
                </div>
                : null}
              <Row>
                <BSForm.Group className={styles['form-buttons']}>
                  {renderButtons()}
                </BSForm.Group>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    deleteShift: shiftStore.actions.delete,
    deleteShiftRange: shiftStore.actions.deleteRange
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  getShift: shiftStore.selectors.getByIdGuid(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftDeletionModal);

