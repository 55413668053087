import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../../redux/types/WebState';
import {ConfirmationDialog} from '../../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import {Button, TableProps} from 'react-bootstrap';
import BootstrapTable, {ColumnDescription, SearchProps} from 'react-bootstrap-table-next';
// @ts-ignore
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.js';
import {AccountStatus, User, userStore} from '../../../../../common/redux/entities/user';
import UserModal from '../UserModal/UserModal';
import {makeUser} from '../../../../../common/util/factory';
import {Navigate, useSearchParams} from 'react-router-dom';
import {RoutePaths} from '../../../../../router/RoutePaths';
import gridStyles from '../../../GridStyles.module.scss';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function UserGrid(props: Props) {
  const {archivedUsers, nonArchivedUsers, getUserById, actions: {archiveUser, restoreUser, upsertUser, approveUser}} = props;
  const { SearchBar } = Search;
  const [isDeleting, setIsDeleting] = useState('');
  const [isActivating, setIsActivating] = useState('');
  const [viewingArchived, setViewingArchived] = useState(false);
  const [viewUserModal, setViewUserModal] = useState(false);
  const [editingUser, setEditingUser] = useState(makeUser());
  const [searchParams] = useSearchParams();
  const [redirectUrl, setRedirectUrl] = useState('');
  const getFieldName = (name: keyof User) => name;

  const renderRedirect = () => {
    if (redirectUrl.length !== 0) {
      return <Navigate to={redirectUrl} />;
    }
    return null;
  };

  const approveHandle = async (user: User) => {
    await approveUser(user.id);
    setRedirectUrl(RoutePaths.userManagement);
  };

  const actionsFormatter = (cell: any, user: User) => {
    return (
      <>
        {viewingArchived ?
            <div>
              <IconButton
                icon={'plus'}
                styles={{marginLeft: '.5rem'}}
                size={'2x'}
                iconToolTipText={'Activate User'}
                onClick={() => setIsActivating(user.id.toString())}
                color={'green'}
              />
            </div>
          : (
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              {user.accountStatus === AccountStatus.PendingApproval ?
                <IconButton
                  icon='check'
                  size={'2x'}
                  onClick={() => approveHandle(user)}
                  color={'green'}
                />
                : null}
              <div>
                <IconButton
                  icon='edit'
                  size={'2x'}
                  styles={{marginLeft: '.5rem'}}
                  onClick={() => {
                    setEditingUser(getUserById(user.id));
                    setViewUserModal(true);
                  }}
                  color={'#005A9C'}
                />
              </div>
              <div>
                <IconButton
                  icon={'trash-alt'}
                  styles={{color: 'red', marginLeft: '.5rem'}}
                  size={'2x'}
                  iconToolTipText={'Deactivate User'}
                  onClick={() => setIsDeleting(user.id.toString())}
                />
              </div>
            </div>
          )
        }
      </>
    );
  };

  const accountStatusFormatter = (cell: any, user: User) => {
    return (
      <>
        {AccountStatus[user.accountStatus].replace(/([A-Z])/g, ' $1').trim()}
      </>
    );
  };

  const tosStatusFormatter = (cell: any, user: User) => {
    return (
      <>
        {user.tosAccepted ? 'Yes' : 'No'}
      </>
    );
  };

  const trainingStatusFormatter = (cell: any, user: User) => {
    return (
      <>
        {user.videosToWatch === null ? null : user.videosToWatch.length === 0 ? <>Complete</> : <>Incomplete</>}
      </>
    );
  };


  const filterActivatingUser = () => {
    return nonArchivedUsers.filter(u => u.email === searchParams.get('edit')!);
  };

  const columns: ColumnDescription[] = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true
    },
    {
      dataField: 'phoneNumber',
      text: 'Phone Number'
    },
    {
      dataField: 'role',
      formatter: (cell: any, user: User) => (user.role.roleName),
      text: 'Role',
      sort: true
    },
    {
      dataField: 'trainingStatus',
      text: 'Training Status',
      formatter: trainingStatusFormatter,
      sort: true
    },
    {
      dataField: 'accountStatus',
      text: 'Status',
      formatter: accountStatusFormatter,
      sort: true
    },
    {
      dataField: 'tosAccepted',
      text: 'Tos Accepted',
      formatter: tosStatusFormatter,
      sort: true
    },
    {
      dataField: 'archivedAt',
      hidden: !viewingArchived,
      text: 'Deactivated On'
    },
    {
      dataField: 'actionsColumn',
      text: '',
      isDummyField: true,
      formatter: actionsFormatter,
      headerStyle: () => {
        return { width: '8rem'};
      },
      style: () => {
        return { width: '8rem'};
      }
    }
  ];

  return (
    <ToolkitProvider
      keyField='id'
      data={searchParams.get('edit')! !== null ? filterActivatingUser() : viewingArchived ? archivedUsers : nonArchivedUsers}
      columns={columns}
      search={{
        searchFormatted: true
      }}
    >
      {(tableProps: any) => (
        <>
          <div style={{marginRight: '10px', display: 'flex', flexGrow: 1}}>
            <div style={{marginRight: '10px', display: 'flex', justifyContent: 'flex-end', marginBottom: '10px'}}>
              <div style={{marginRight: '10px'}}>
                <Button
                  onClick={() => setViewingArchived(!viewingArchived)}
                  style={{backgroundColor: '#005A9C'}}
                >
                  {viewingArchived ? 'View Active Users' : 'View Archived Users'}
                </Button>
              </div>
              <Button
                onClick={() => {
                  setEditingUser(makeUser());
                  setViewUserModal(true);
                }}
                style={{backgroundColor: '#005A9C'}}
              >
                Add User
              </Button>
            </div>
          </div>
          <SearchBar {...tableProps.baseProps} placeholder={'Search'} />
          {renderRedirect()}
          <BootstrapTable
            wrapperClasses={gridStyles['responsive-table-wrapper']}
            classes={gridStyles['table-auto']}
            rowStyle={{height: '100%'}}
            {...tableProps.baseProps}
          />
          {isDeleting !== '' && (
            <ConfirmationDialog
              onAccept={async () => {
              await archiveUser(isDeleting);
              setIsDeleting('');
              }}
              onDecline={async () => { setIsDeleting(''); }}
              open={isDeleting !== ''}
              prompt='Are you sure you want to archive this User? This WILL delete any associated Volunteer Groups or Group Memberships.'
              positiveText='Yes'
              negativeText='No'
              positiveVariant='success'
              negativeVariant='danger'
            />
            )}
          {isActivating !== '' && (
            <ConfirmationDialog
              onAccept={async () => {
                await restoreUser(isActivating);
                setIsActivating('');
              }}
              onDecline={async () => { setIsActivating(''); }}
              open={isActivating !== ''}
              prompt='Are you sure you want to Activate this User?'
              positiveText='Yes'
              negativeText='No'
              positiveVariant='success'
              negativeVariant='danger'
            />
          )}
          {viewUserModal ?
            <UserModal
              editable={true}
              onSubmit={() => {
                setViewUserModal(false);
              }}
              onCancel={() => setViewUserModal(false)}
              existingUser={editingUser}
            />
            : null}
        </>
      )}
    </ToolkitProvider>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    archiveUser: userStore.actions.archiveUser,
    restoreUser: userStore.actions.restoreUser,
    upsertUser: userStore.actions.upsert,
    approveUser: userStore.actions.approve
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  nonArchivedUsers: userStore.selectors.getNonArchivedUsers(state),
  archivedUsers: userStore.selectors.getArchivedUsers(state),
  getUserById: userStore.selectors.getByIdGuid(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(UserGrid);
