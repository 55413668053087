import React, {FormEvent, useState} from 'react';
import {connect} from 'react-redux';
import styles from './UserModal.module.scss';
import {Form, Formik, FormikErrors} from 'formik';
import {Alert, Button, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import {InputRow} from '../../../../../components/util/form-components/InputRow';
import Input from '../../../../../components/util/form-components/formik-inputs/Input/Input';
import {WebState} from '../../../../../redux/types/WebState';
import {UserModalSchema} from './UserModalSchema';
import {User, userStore} from '../../../../../common/redux/entities/user';
import {DropdownInput} from '../../../../../components/util/form-components/formik-inputs/DropdownInput/DropdownInput';
import {roleStore} from '../../../../../common/redux/entities/role';
import {convertToDropDownOptions} from '../../../../../common/util';
import {makeUser} from '../../../../../common/util/factory';
import {AxiosError} from 'axios';
import {getErrorResponseMessage, handleAxiosError} from '../../../../../common/util/http';
import {bindActionCreators, Dispatch} from 'redux';


type Props = {
  onCancel: () => void;
  onSubmit: () => void;
  editable: boolean;
  onTop?: boolean;
  existingUser?: User;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function UserModal(props: Props) {
  const {onTop, onSubmit, editable, onCancel, rolesOptions, existingUser, actions: {upsertUser}} = props;
  const getFieldName = (name: keyof User) => name;
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: User,
    validate: (values: User) => Promise<FormikErrors<User>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setIsSubmitting(true);
    setErrorMessage('');
    e.persist();
    e.preventDefault();
    const errors = await validate(association);
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setIsSubmitting(false);
    } else {
      try {
        await upsertUser(association);
        onSubmit();
      } catch (e: AxiosError | any) {
        setErrorMessage(getErrorResponseMessage(e));
      }
    }
    setIsSubmitting(false);
  };


  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={onCancel} variant={editable ? 'danger' : 'info'} className={styles['close-button']}>
            {editable ? 'Cancel' : 'Close'}
          </Button>
        }
        {!isSubmitting ? editable && <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };


  return (
    <Modal backdropClassName={onTop ? styles['on-top'] : ''} show={true} size='lg' centered={true} onHide={() => null}>
      <Modal.Body>
        <Formik initialValues={existingUser !== null ? existingUser as User : makeUser()} validationSchema={UserModalSchema} onSubmit={() => undefined}>
          {({values, validateForm, handleSubmit}) => {
            return (
              <Form noValidate={false} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                <Modal.Title>{existingUser?.id !== '' ? 'Update User' : 'Add User'}</Modal.Title>
                <InputRow label={'Email'} columnSize={5} labelSize={3} style={{paddingTop: '10px'}} requiredAsterisk={true}>
                  <Input name={getFieldName('email')}/>
                </InputRow>
                <InputRow label={'Name'} columnSize={5} labelSize={3} style={{paddingTop: '10px'}} requiredAsterisk={true}>
                  <Input name={getFieldName('name')}/>
                </InputRow>
                <InputRow label={'Phone Number'} columnSize={5} labelSize={3} style={{paddingTop: '10px'}} requiredAsterisk={true}>
                  <Input name={getFieldName('phoneNumber')}/>
                </InputRow>
                <InputRow label={'Address'} columnSize={5} labelSize={3} style={{paddingTop: '10px'}} requiredAsterisk={true}>
                  <Input name={getFieldName('street')}/>
                </InputRow>
                <InputRow label={'City'} columnSize={5} labelSize={3} style={{paddingTop: '10px'}} requiredAsterisk={true}>
                  <Input name={getFieldName('city')}/>
                </InputRow>
                <InputRow label={'State'} columnSize={5} labelSize={3} style={{paddingTop: '10px'}} requiredAsterisk={true}>
                  <Input name={getFieldName('state')}/>
                </InputRow>
                <InputRow label={'Zip Code'} columnSize={2} labelSize={3} style={{paddingTop: '10px'}} requiredAsterisk={true}>
                  <Input name={getFieldName('zip')}/>
                </InputRow>
                <InputRow label={'Role'} columnSize={4} labelSize={3} style={{paddingTop: '10px'}} requiredAsterisk={true}>
                  <DropdownInput dropdownData={rolesOptions}  name={getFieldName('roleId')} />
                </InputRow>
                {errorMessage !== '' ?
                  <div style={{marginTop: '1rem'}}>
                    <Alert variant='danger'>{errorMessage}</Alert>
                  </div>
                  : null}
                <Row>
                  <BSForm.Group className={styles['form-buttons']}>
                    {renderButtons()}
                  </BSForm.Group>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertUser: userStore.actions.upsert
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  rolesOptions: convertToDropDownOptions(roleStore.selectors.getAsArray(state), 'roleName')
});
export default connect(mapStateToProps, mapDispatchToProps)(UserModal);
