import styles from './CalendarTitleAndLegendRow.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AppTheme} from '../../../../appTheme';
import React, {CSSProperties} from 'react';
import {Col, Row} from 'react-bootstrap';

interface Props {
  title: string;
  showOpen?: boolean;
  showCoveredByUser?: boolean;
  showClosed?: boolean;
  childrenStyle?: CSSProperties;
  children?: JSX.Element;
}

export const CalendarTitleAndLegendRow = (props: Props) => (
    <>
      <Row className={styles['page-title-legend-container']}>
        <Col lg={'auto'} style={{paddingLeft: '0'}}>
          <h2>{props.title}</h2>
        </Col>
        <Col lg={true} className={styles['children']}>
          {props.children}
        </Col>
        <Col lg={'auto'} className={styles['legend-container']}>
          <Row>
            {props.showOpen ?
              <>
                <div style={{paddingLeft: '0'}}>
                  <FontAwesomeIcon icon={'circle'} color={AppTheme.colors.calendarEventColors.open}/>
                </div>
                Open
              </> : null}
            {props.showClosed ?
              <>
                <div>
                  <FontAwesomeIcon icon={'circle'} color={AppTheme.colors.calendarEventColors.covered}/>
                </div>
                Covered
              </> : null}
            {props.showCoveredByUser ?
              <>
                <div>
                  <FontAwesomeIcon icon={'circle'} color={AppTheme.colors.calendarEventColors.currentUserCovered}/>
                </div>
                Assigned to you
              </> : null}
          </Row>
        </Col>
      </Row>
    </>
);

export default CalendarTitleAndLegendRow;
