import React from 'react';
import {RoutePaths} from '../../../router/RoutePaths';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Sidebar} from 'react-pro-sidebar';
import styles from './SidebarPanel.module.scss';
import {GetMenuItemsForRole} from './MenuItemsForRole';
import {AppTheme, getLogoWhiteTextLink} from "../../../appTheme";
import {WebState} from "../../../redux/types/WebState";
import {roleStore} from "../../../common/redux/entities/role";
import {userStore} from "../../../common/redux/entities/user";

type Props = & ReturnType<typeof mapStateToProps>;


const SidebarPanel = (props: Props) => {
  const {currentUser} = props;
  return (
    <Sidebar customBreakPoint='1024px' backgroundColor={AppTheme.colors.primary} color={'#ffffff'}>
      <a href={RoutePaths.hcsiWebsitePath} style={{display: 'flex', margin: '10px'}}>
        <img src={getLogoWhiteTextLink()} alt='HCSI Portal' className={styles['logo']}/>
      </a>
      {GetMenuItemsForRole(currentUser)}
    </Sidebar>
  );

};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({}, dispatch)});
const mapStateToProps = (state: WebState) => ({
  currentUser: userStore.selectors.getCurrentUser(state),
  roleName: roleStore.selectors.getById(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarPanel);
