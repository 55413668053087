import React, {useEffect, useState} from 'react';
import styles from './Register.module.scss';
import {Alert, Image} from 'react-bootstrap';
import {Link, Navigate, useParams, useSearchParams} from 'react-router-dom';
import {isAuthenticated, userStore} from '../../common/redux/entities/user';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {RoutePaths} from '../../router/RoutePaths';
import {WebState} from '../../redux/types/WebState';
import {getLogoLink} from '../../appTheme';
import {validateEmailVerificationToken} from '../../api/authApi';
import {handleAxiosError, isBadRequest} from '../../common/util/http';
import {CenteredSpinner} from '../../components/util/widgets/CenteredSpinner/CenteredSpinner';


type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const EmailVerification = (props: Props) => {
  const {authenticated} = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if(searchParams.get('token') === null) {
      setErrorMessage('Token is invalid');
      setLoading(false);
      return;
    }

    const validateEmailToken = async () => {
      try {
        await validateEmailVerificationToken(searchParams.get('token')!);
        setLoading(false);
      } catch (e: any) {
        if (isBadRequest(e)) {
          setRedirectUrl(RoutePaths.login);
        }
        setErrorMessage(handleAxiosError(e));
        setLoading(false);
      }
    };
    validateEmailToken();
  }, []);

  const renderRedirect = () => {
    if (redirectUrl.length !== 0) {
      return <Navigate to={redirectUrl} />;
    }
    return null;
  };

  const renderContent = () => {
    if(errorMessage !== '') {
        return (<Alert style={{marginTop: '1rem'}} variant='danger'>{errorMessage}</Alert>);
    }
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <label>
          Your Email has been verified.
        </label>
      </div>
    );
  };

  const renderContainer = () => (
    <div>
      {renderRedirect()}
      <div className={styles['logo']}>
        <Image alt='HCSI Logo' src={getLogoLink()}/>
      </div>
      <div className={styles['login-form']}>
        {renderContent()}
      </div>
      <div className={styles['forgot-password']}>
        <Link to={RoutePaths.login}>Return to login</Link>
      </div>
    </div>
  );

  return (
    <>
      {loading ?  <CenteredSpinner/> : renderContainer()}
    </>
  );

};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({setCurrentUser: userStore.actions.setCurrentUser}, dispatch)});
const mapStateToProps = (state: WebState) => ({ authenticated: isAuthenticated(state)});

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
