import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import styles from './UserManagement.module.scss';
import gridStyles from '../GridStyles.module.scss';
import {connect} from 'react-redux';
import {WebState} from '../../../redux/types/WebState';
import {bindActionCreators, Dispatch} from 'redux';
import {CenteredSpinner} from '../../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {handleAxiosError, isBadRequest} from '../../../common/util/http';
import {useMount} from '../../../hooks/useMount';
import {loadUserManagementData} from '../../../common/redux/userManagement';
import UserGrid from './components/UserGrid/UserGrid';
import {AxiosError} from 'axios';
import {useSearchParams} from 'react-router-dom';


type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function UserManagement(props: Props) {
  const {actions: {loadUserData}} = props;
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [searchParams] = useSearchParams();

  useMount(async () => {
    try {
      await loadUserData();
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load users'}));
    }
    setLoading(false);
  });

  return (
    <div>
      {loading ?  <CenteredSpinner/> : (
        errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
          <UserGrid/>
      )}
    </div>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) =>
  ({actions: bindActionCreators({loadUserData: loadUserManagementData}, dispatch)});
const mapStateToProps = (state: WebState) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
