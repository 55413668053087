import React from 'react';

import {ArrayHelpers, FieldArray} from 'formik';
import {convertToDropDownOptions} from '../../../../common/util';
import {User, userStore} from '../../../../common/redux/entities/user';
import {DeletableListRow} from '../../../../components/util/lists/ListTable/ListTableRow';
import {makeVolunteerGroupUser} from '../../../../common/util/factory';
import {
  ListHeaderWithDropDown,
  ResetValueFunc
} from '../../../../components/util/lists/ListHeaderWithDropDown/ListHeaderWithDropDown';
import {ListTable} from '../../../../components/util/lists/ListTable/ListTable';
import {VolunteerGroupUser} from '../../../../common/redux/entities/volunteerGroup';
import {selector} from '../../../../common/redux/selectors';
import {DropdownOption} from '../../../../components/util/form-components/SearchableDropdown/SearchableDropdown';
import {propertyOf} from '../../../../common/util/object';
import {connect} from 'react-redux';
import {InputColumn} from '../../../../components/util/form-components/InputColumn';

type Props = {
  users: VolunteerGroupUser[];
  userDropDownOptions: DropdownOption[];
  prefix: string;
  editable: boolean;
} & ReturnType<typeof mapStateToProps>;

function UserSelection(props: Props) {
  const {prefix, getUser, editable, users, userDropDownOptions} = props;
  const onAdd = (helpers: ArrayHelpers, id: string, resetValue: ResetValueFunc) => {
    helpers.push(makeVolunteerGroupUser(getUser(id)) as VolunteerGroupUser);
    resetValue();
  };
  return (
    <FieldArray
      name={prefix}
      validateOnChange={false}
      render={helpers=> {
        return (
          <ListTable
            style={{marginBottom: '1rem'}}
            noItemsLabel={'No Members Added'}
            items={users}
            renderHeader={() => (
              <ListHeaderWithDropDown
                label={'Members'}
                dropDownOptions={userDropDownOptions.filter(u => users.every(us => u.value !== us.id))
                  .filter(u => getUser(u.value).volunteerGroups?.length === 0)}
                onAdd={(v, r) => onAdd(helpers, v, r)}
                disableAddButton={!editable}
                addOnSelect={true}
              />
            )}
            renderItem={(item, index) => (
              <DeletableListRow
                iconSize={'2x'}
                key={index}
                deleteColumnSize={4}
                render={() => (
                  <UserRow
                    prefix={`${prefix}.${index}`}
                    editable={editable}
                    user={item}
                    userDropDownOptions={userDropDownOptions}
                  />
                )}
                editable={editable}
                onDelete={() => helpers.remove(index)}
              />
            )}
          />
        );
      }}
    />
  );
}

type UserRowProps = {
  editable: boolean;
  user: VolunteerGroupUser;
  prefix: string;
  userDropDownOptions: DropdownOption[];
};
function UserRow({editable, user, prefix, userDropDownOptions}: UserRowProps) {
  const getFieldName = (name: keyof VolunteerGroupUser) => `${prefix}.${propertyOf<VolunteerGroupUser>(name)}`;
  return  (
    <div style={{display: 'flex', flexGrow: 1}}>
      <InputColumn label={'Name'} columnSize={4} className='pl-2'>
        <h4>{user.name}</h4>
      </InputColumn>
      <InputColumn label={'Email'} columnSize={4} className='pl-2'>
        <h4>{user.email}</h4>
      </InputColumn>
      <InputColumn label={'Phone Number'} columnSize={4} className='pl-2'>
        <h4>{user.phoneNumber}</h4>
      </InputColumn>
    </div>
  );
}
const mapStateToProps = selector(s => ({
  getUser: userStore.selectors.getByIdGuid(s)
}));
const mapToDispatch = () => ({});
export default connect(mapStateToProps, mapToDispatch)(UserSelection);
