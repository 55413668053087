import {api} from './index';
import {Video} from '../common/redux/entities/video';
import {UpdateVideoInterface} from '../pages/Configuration/VideoManagement/components/VideoModal/UpdateVideoModal';
import {VideoManagementState} from '../common/redux/videoConfiguration';
import {RoleAssignments} from '../pages/Configuration/VideoManagement/components/VideoModal/VideoModal';

export async function getVideoManagementState() {
  return (await api.get<VideoManagementState>('/video/management')).data;
}

export interface CreateVideoRequest {
  title: string;
  description: string;
  encodedThumbnailFile: string | null;
  videoRoleAssignments: RoleAssignments[];
}

export async function createVideo(value: CreateVideoRequest) {
  return (await api.post<CreateVideoRequest>('/video', value)).data as any as Video;
}

export async function updateVideo(id: string, value: UpdateVideoInterface) {
  return (await api.post<CreateVideoRequest>('/video/update/' + id, value)).data as any as Video;
}

export async function uploadVideoFile(id: string, video: FormData) {
  return (await api.post<CreateVideoRequest>(`/video/${id}`, video, {headers: {'Content-Type': 'multipart/form-data;'}})).data as any as Video;
}

export async function setVideoWatched(id: string) {
  return (await api.post<Video>(`/video/${id}/watch`)).data;
}

export async function deleteVideo(id: string) {
  return (await api.delete<string>(`/video/${id}` )).data as any as Video;
}
