import React, {useState} from 'react';
import styles from './VolunteerSignup.module.scss';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../redux/types/WebState';
import {connect} from 'react-redux';
import {Alert, Button, Card, Container, Modal, Row} from 'react-bootstrap';
import {CenteredSpinner} from '../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {useMount} from '../../hooks/useMount';
import VolunteerCalendar, {ShiftType} from '../../components/util/VolunteerCalendar/VolunteerCalendar';
import {Shift, shiftStore} from '../../common/redux/entities/shift';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../common/util/http';
import ShiftSignupModal from './VolunteerShiftSignupModal/ShiftSignupModal';
import {CalendarEventProps} from '../../components/util/VolunteerCalendar/CalendarEventProps';
import {now} from '../../util';
import CalendarTitleAndLegendRow
  from '../../components/util/VolunteerCalendar/CalendarTitleAndLegendRow/CalendarTitleAndLegendRow';
import {userStore} from '../../common/redux/entities/user';
import {RoutePaths} from '../../router/RoutePaths';
import {Navigate, useNavigate} from 'react-router-dom';
import {ConfirmationDialog} from '../../components/util/ConfirmationDialog/ConfirmationDialog';
import {LoadingButton} from '../../components/util/widgets/LoadingButton/LoadingButton';

interface InitialStateProps {
  initialType: ShiftType;
}

type Props = InitialStateProps & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const VolunteerSignup = (props: Props) => {
  const {initialType, shifts, getShift, currentUser, actions: {loadMonth, loadVolunteerGroupUsers}} = props;

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [editingShift, setEditingShift] = useState<Shift>();
  const [currentShiftType, setCurrentShiftType] = useState(initialType);
  const [showCateringGuidelines, setShowCateringGuidelines] = useState(initialType === 'catering');
  const [showVideoReminderModal, setShowVideoReminderModal] = useState(
    !!(currentShiftType === 'volunteer' && currentUser && currentUser.videosToWatch?.length > 0));
  const navigate = useNavigate();

  useMount(async () => {
    try {
      await loadMonth(now.toISOString());
      await loadVolunteerGroupUsers();
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load volunteer signup calendar'}));
    }
    setLoading(false);
  });

  const renderRedirectModal = () => (
      <ConfirmationDialog
        open={showVideoReminderModal}
        onAccept={async () => await navigate(RoutePaths.trainingVideos)}
        positiveVariant={'success'}
        positiveText={'Trainings'}
        onDecline={async () => setShowVideoReminderModal(false)}
        negativeVariant={'danger'}
        negativeText={'Continue'}
        prompt={'It is strongly encouraged you complete all of your trainings before proceeding to volunteer.'}
      />
);

  const renderCateringGuidelinesModal = () => (
    <Modal
      show={showCateringGuidelines}
      aria-labelledby='contained-modal-title-vcenter'
      centered={true}
      size={'lg'}
    >
      <Modal.Title style={{margin: '1rem 0 0 1rem'}}>Catering Guidelines</Modal.Title>
      <Modal.Body>
        <Row>
          <h6>Number of guests to account for:</h6>
        </Row>
        <Row>
          Nov-Dec: 30 guests
        </Row>
        <Row>
          Dec-Feb: 50 guests
        </Row>
        <Row>
          Feb-April: 30 guests
        </Row>
        <Row style={{paddingTop: '1rem'}}>
          <h6>Foods recommended:</h6>
        </Row>
        <Row>
          Foods such as casseroles, soups, spaghetti bakes, pulled pork, fruit cups, and soft fruits like berries, oranges, and bananas are recommended.
        </Row>
        <Row>
          <h6>Foods not recommended:</h6>
        </Row>
        <Row>
          Hard foods such as granola, nuts, cold cereal, apples, dried fruit are not recommended.
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='success' onClick={() => setShowCateringGuidelines(false)}>
          I understand
        </Button>
      </Modal.Footer>
      <Alert show={!!errorMessage} variant='danger'>{errorMessage}</Alert>
    </Modal>
  );

  const renderedShifts = () => {
    return shifts.filter(s => s.shiftVolunteers.length < s.volunteersNeeded);
  };

  const onSubmit = async () => {
    setEditingShift(undefined);
  };

  const onEventClick = (shift: CalendarEventProps) => {
    setEditingShift(getShift(shift.id));
  };

  const renderContent = () => (
    <Card className={styles['page-body']}>
      {renderRedirectModal()}
      {renderCateringGuidelinesModal()}
      <CalendarTitleAndLegendRow
        title={currentShiftType === 'volunteer' ? 'Volunteer Signup' : 'Meal Signup'}
        showOpen={true}
        showClosed={false}
        showCoveredByUser={true}
      />
      <VolunteerCalendar
        shiftType={currentShiftType}
        showCovered={false}
        onEventClick={onEventClick}
      />
      {editingShift ?
        <ShiftSignupModal
          shiftType={currentShiftType}
          existingShift={editingShift}
          onSubmit={onSubmit}
          onCancel={() => setEditingShift(undefined)}/> : null
      }
    </Card>
  );

  return (
    <div>
      {loading ?  <CenteredSpinner/> : (
        errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
          renderContent()
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadMonth: shiftStore.actions.loadMonth,
    loadVolunteerGroupUsers: userStore.actions.loadVolunteerGroupUsers
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  shifts: shiftStore.selectors.getAsArray(state),
  getShift: shiftStore.selectors.getByIdGuid(state),
  currentUser: userStore.selectors.getCurrentUser(state),
  mealsProvided: shiftStore.selectors.getAsArray(state),
  getMealsProvided: shiftStore.selectors.getByIdGuid(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(VolunteerSignup);
