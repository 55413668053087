import {Row} from 'react-bootstrap';
import React, {CSSProperties} from 'react';

export function ListRow({children}: { children: any}) {
  return (
    <Row style={{border: '1px solid rgba(0,0,0,.125)', padding: '1rem 1.25rem'}}>
      {children}
    </Row>
  );
}

export function DivListRow({children}: { children: any}) {
  return (
    <div style={{border: '1px solid rgba(0,0,0,.125)', padding: '1rem 1.25rem'}}>
      {children}
    </div>
  );
}
