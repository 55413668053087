import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {createStandardSelectors, getEntities} from '../selectors';
import {combineReducers} from 'redux';
import {CommonDispatch} from '../index';
import {getGroupVolunteersOnShift} from '../../../api/shiftManagementApi';

export interface ShiftVolunteer {
  volunteerId: string;
  shiftId: string;
}

const actions = createStandardActions(placeholder<ShiftVolunteer>(), 'SHIFT_VOLUNTEER/SET', 'SHIFT_VOLUNTEER/SAVE');
const selectors = createStandardSelectors(placeholder<ShiftVolunteer>(), s => getEntities(s).shiftVolunteers);
export type shiftVolunteerActions = GetActions<typeof actions>;
export const shiftVolunteers = combineReducers({items: standardItemsReducer<ShiftVolunteer, shiftVolunteerActions>(actions)});
export const shiftVolunteerStore = readonly({
  selectors: {
    ...selectors
  },
  actions: {
    ...actions,
    getCurrentShiftGroupVolunteers: (id: string) => async (dispatch: CommonDispatch) => {
      const response = await getGroupVolunteersOnShift(id);
      await dispatch(actions.set(response));
      return response;
    }
  }
});
