import {
  array,
  boolean,
  number,
  object,
  string,
  StringSchema
} from 'yup';
import {VolunteerGroup, VolunteerGroupUser} from '../../../../common/redux/entities/volunteerGroup';


// @ts-ignore
export const VolunteerGroupUserSchema = () => object<VolunteerGroupUser>({
  id: string(),
  name: string(),
  email: string(),
  phoneNumber: string().nullable()
});


// @ts-ignore
export const VolunteerGroupModalSchema = object<VolunteerGroup>({
  id: string(),
  leaderId: string().nullable(),
  name: string().required(),
  members: array().of(VolunteerGroupUserSchema())
});
