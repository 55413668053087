import {Dispatch} from 'redux';
import {getHomeText, postHomeText} from '../../api/homeTextApi';
import {homeTextStore} from './entities/homeText';

export const loadHomeTextData = () => async (dispatch: Dispatch) => {
  const response = await getHomeText();
  await dispatch(homeTextStore.actions.set(response));
};

export const setHomeTextData = (value: string) => async (dispatch: Dispatch) => {
  const response = await postHomeText(value);
  await dispatch(homeTextStore.actions.set(response.data));
};
