import React, {CSSProperties} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Video} from '../../../../common/redux/entities/video';
import {WebState} from '../../../../redux/types/WebState';
import styles from './TrainingVideoThumbnail.module.scss';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & {
  video: Video;
  style?: CSSProperties;
};

const TrainingVideoThumbnail = (props: Props) => {
  const {video, style} = props;

  const thumbnailUrl = () => video.thumbnail !== '' ? video.thumbnail : './Storage/Public/Images/no-thumbnail.png';
  return (
    <div>
      <img src={`${process.env.REACT_APP_STORAGE_URL + thumbnailUrl()}`} style={style}/>
    </div>

  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({}, dispatch)});
const mapStateToProps = (state: WebState) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingVideoThumbnail);
