import React, {FormEvent, useState} from 'react';
import {connect} from 'react-redux';
import styles from './DocumentModal.module.scss';
import {DocumentModalSchema} from './DocumentModalSchema';
import {ArrayHelpers, FieldArray, Form, Formik, FormikErrors} from 'formik';
import {Button, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import {InputRow} from '../../../../../components/util/form-components/InputRow';
import Input from '../../../../../components/util/form-components/formik-inputs/Input/Input';
import {WebState} from '../../../../../redux/types/WebState';
import {getBase64} from '../../../../../util';
import CheckBoxInput from '../../../../../components/util/form-components/formik-inputs/CheckBoxInput/CheckBoxInput';
import {Role, roleStore} from '../../../../../common/redux/entities/role';
import {DocumentModel} from '../../../../../common/redux/entities/document';
import {makeDocumentRequest} from '../../../../../common/util/factory';
import {useMount} from '../../../../../hooks/useMount';

type Props = {
  initialDocument: DocumentModel;
  onCancel: () => void;
  onSubmit: (document: FormData) => void;
  editable: boolean;
  onTop?: boolean;
} & ReturnType<typeof mapStateToProps>;

function DocumentModal(props: Props) {
  const {onTop, onSubmit, editable, onCancel, roles} = props;
  const getFieldName = (name: keyof DocumentModel) => name;
  const [documentFileData, setDocumentFileData] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assignedRoles, setAssignedRoles] = useState<string[]>([]);


  useMount(() => {
    setAssignedRoles(props.initialDocument.documentRoleAssignments);
  });

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: DocumentModel,
    validate: (values: DocumentModel) => Promise<FormikErrors<DocumentModel>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setIsSubmitting(true);
    e.persist();
    e.preventDefault();
    const errors = await validate(association);
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setIsSubmitting(false);
    } else {
      const documentData = new FormData();
      documentData.append('id', association.id);
      documentData.append('name', association.name);
      documentData.append('file', documentFileData);
      documentData.append('url', association.url!);
      documentData.append('documentRoleAssignments', JSON.stringify(assignedRoles));
      onSubmit(documentData);
    }
  };

  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={onCancel} variant={editable ? 'danger' : 'info'}
                  className={styles['close-button']}>
            {editable ? 'Cancel' : 'Close'}
          </Button>
        }
        {!isSubmitting ? editable && <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };

  const renderRolesCheckboxes = () => roles.map((role: Role) => {
    if(role.roleName === 'Administrator') return null;
    return (
      <div key={role.id}>
        <CheckBoxInput
          name={role.id}
          checked={assignedRoles.includes(role.id)}
          type={'checkbox'}
          label={role.roleName}
          onChange={(checked: boolean) => {
            if(checked) {
              setAssignedRoles([role.id, ...assignedRoles]);
            } else {
              const newRoles = assignedRoles.filter((r: string) => r !== role.id);
              setAssignedRoles([...newRoles]);
            }
        }}/>
      </div>
    );
  });

  return (
    <Modal backdropClassName={onTop ? styles['on-top'] : ''} show={true} size='lg' centered={true} onHide={() => null}>
      <Modal.Body>
        <Formik initialValues={makeDocumentRequest(props.initialDocument)} // validationSchema={DocumentModalSchema}
                onSubmit={() => undefined}>
          {({values, validateForm, handleSubmit}) => {
            return (
              <Form noValidate={true} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                <Modal.Title>Add Document</Modal.Title>
                <InputRow label={'Name'} columnSize={5} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('name')}/>
                </InputRow>
                <InputRow label={'Upload a file or enter a Url'} columnSize={5} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('url')}/>
                  <Input name={getFieldName('file')} type={'file'} setFormDataState={setDocumentFileData}/>
                </InputRow>
                <InputRow label={'Assign Roles'} labelSize={3} columnSize={5} style={{paddingTop: '10px'}}>
                  {renderRolesCheckboxes()}
                </InputRow>
                <Row>
                  <BSForm.Group className={styles['form-buttons']}>
                    {renderButtons()}
                  </BSForm.Group>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
const mapStateToProps = (state: WebState) => ({
  roles: roleStore.selectors.getAsArray(state)
});
export default connect(mapStateToProps)(DocumentModal);
