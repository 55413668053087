import {api} from './index';
import {HomeText} from '../common/redux/entities/homeText';

export async function getHomeText() {
  return (await api.get<HomeText>('/homeText')).data;
}

export async function postHomeText(newText: string) {
  return (await api.post<HomeText>('/homeText', {value: newText}));
}
