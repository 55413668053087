import styles from './UserInfo.module.scss';
import React from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {RoutePaths} from '../../../router/RoutePaths';
import IconButton from '../../util/widgets/IconButton/IconButton';
import {WebState} from "../../../redux/types/WebState";
import {userStore} from "../../../common/redux/entities/user";
type Props = {} & ReturnType<typeof mapStateToProps>;

const UserInfo = (props: Props) => {
  const {currentUser} = props;
  return (
    <div className={styles['links-profile-wrapper']}>
      <div className={styles['profile-logout']}>
        <div style={{display: 'flex', flexDirection: 'column', marginRight: '1rem'}}>
          <Link to={RoutePaths.profilePaths.View}>
            <h5 style={{padding: 0, margin: 0}}>
              {currentUser?.name}
            </h5>
          </Link>
          <div style={{fontWeight: 'bold', marginBottom: 'auto'}}>
            {currentUser?.role.roleName}
          </div>
        </div>
        <IconButton styles={{marginLeft: '1rem'}} icon='sign-out-alt' link={RoutePaths.logout} size={'2x'} color={'#005A9C'}/>
      </div>
    </div>
  );

};


const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({}, dispatch)});
const mapStateToProps = (state: WebState) => ({
  currentUser: userStore.selectors.getCurrentUser(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
