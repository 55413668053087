import * as React from 'react';
import { Route, Routes} from 'react-router-dom';
import NotFound from '../pages/NotFound/NotFound';
import {PrivateRoute} from '../components/util/PrivateRoute/PrivateRoute';
import Login from '../pages/Login/Login';
import {RoutePaths} from './RoutePaths';
import {LogOut} from '../pages/Logout/Logout';
import Home from '../pages/Home/Home';
import Register from '../pages/Register/Register';
import TrainingVideos from '../pages/TrainingVideos/TrainingVideos';
import UserManagement from '../pages/Configuration/UserManagement/UserManagement';
import VideoManagement from '../pages/Configuration/VideoManagement/VideoManagement';
import VolunteerCalendar from '../pages/VolunteerSignup/VolunteerSignup';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import CalendarManagement from '../pages/Configuration/CalendarManagement/CalendarManagement';
import Profile from '../pages/Profile/Profile';
import GroupManagement from '../pages/Configuration/GroupManagement/GroupManagement';
import EmailVerification from '../pages/Register/EmailVerification';
import HcsiTextAlerts from '../pages/Export/HcsiTextAlerts';
import {RoleProtectedRoute} from '../components/util/RoleProtectedRoute/RoleProtectedRoute';
import TermsOfService from '../pages/TermsOfService/TermsOfService';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import DocumentManagement from '../pages/Configuration/DocumentManagement/DocumentManagement';
import UserDocuments from '../pages/Documents/UserDocuments';
import AppLayout from "./AppLayout";

const AppRoutes = () => {
    const roleProtectedRoute = (routeComponent: JSX.Element, roles: string[]) => {
      return (
        <RoleProtectedRoute allowedRoles={roles}>
          {routeComponent}
        </RoleProtectedRoute>
      );
    };

    return (<Routes>
        <Route path={RoutePaths.login} element={<Login/>}/>
        <Route path={RoutePaths.logout} element={<LogOut/>}/>
        <Route path={RoutePaths.register} element={<Register/>}/>
        <Route path={RoutePaths.forgotPassword} element={<ForgotPassword/>}/>
        <Route path={RoutePaths.resetPassword} element={<ResetPassword/>}/>
        <Route path={RoutePaths.verifyEmail} element={<EmailVerification/>}/>
        <Route path={RoutePaths.termsOfService} element={<TermsOfService/>}/>
        <Route path={RoutePaths.privacyPolicy} element={<PrivacyPolicy/>}/>
        <Route
          path={RoutePaths.root}
          element={
            <AppLayout>
              <PrivateRoute>
                  <Routes>
                    <Route path={RoutePaths.home} element={<Home/>}/>
                    <Route path={RoutePaths.userManagement} element={
                        roleProtectedRoute(<UserManagement/>, ['Administrator'])}
                    />
                    <Route path={RoutePaths.videoManagement} element={
                      roleProtectedRoute(<VideoManagement/>, ['Administrator'])}
                    />
                    <Route path={RoutePaths.groupManagement} element={
                      roleProtectedRoute(<GroupManagement/>, ['Administrator', 'Shelter Manager'])}
                    />
                    <Route path={RoutePaths.calendarManagement} element={
                      roleProtectedRoute(<CalendarManagement initialType={'volunteer'} key={'management'}/>, ['Administrator', 'Shelter Manager'])}
                    />
                    <Route path={RoutePaths.alerts} element={
                      roleProtectedRoute(<HcsiTextAlerts/>, ['Administrator', 'Shelter Manager'])}
                    />
                    <Route path={RoutePaths.documentManagement} element={
                      roleProtectedRoute(<DocumentManagement/>, ['Administrator'])}
                    />
                    <Route path={RoutePaths.trainingVideos} element={<TrainingVideos/>}/>
                    <Route path={RoutePaths.profilePaths.Edit} element={<Profile/>}/>
                    <Route path={RoutePaths.profilePaths.View} element={<Profile/>}/>
                    <Route path={RoutePaths.documents} element={<UserDocuments/>}/>
                    <Route path={RoutePaths.volunteerCalendar} element={<VolunteerCalendar initialType={'volunteer'} key={'volunteer'}/>}/>
                    <Route path={RoutePaths.cateringCalendar} element={<VolunteerCalendar initialType={'catering'} key={'catering'}/>}/>
                    <Route path={RoutePaths.all} element={<NotFound/>}/>
                  </Routes>
              </PrivateRoute>
            </AppLayout>
          }
        />
        <Route path={RoutePaths.all} element={<NotFound/>}/>
      </Routes>
    );
};

export default AppRoutes;
