import React from 'react';
import {Col} from 'react-bootstrap';
import IconButton from '../../widgets/IconButton/IconButton';
import {ListRow} from '../ListRow/ListRow';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';

interface Props {
  editable: boolean;
  onDelete?: () => void;
  disableDelete?: boolean;
  disabled?: boolean;
  disabledHoverText?: string;
  renderContent?: () => JSX.Element | string | number;
  render?: () => JSX.Element | string | number;
  deleteColumnSize?: number;
  iconSize? : SizeProp;
}

export function DeletableListRow(
  {editable, renderContent, onDelete, disableDelete,
    deleteColumnSize, render, iconSize, disabled, disabledHoverText}: Props
) {
  return (
    <ListRow>
      {render && render()}
      {!render && renderContent && (
        <Col xs={4}>
          {renderContent()}
        </Col>
      )}
      {onDelete &&
        <Col className='d-flex justify-content-end align-items-center' xs={deleteColumnSize ?? 8}>
          <IconButton
            icon={'trash-alt'}
            hideIcon={!editable}
            size={iconSize !== null ? iconSize : '1x'}
            styles={{color: editable && !disableDelete ? 'red' : 'grey'}}
            onClick={() => editable && !disableDelete ? onDelete() : null}
            disabled={disabled}
            iconToolTipText={disabled ? disabledHoverText : ''}
          />
        </Col>
      }
    </ListRow>
  );
}
