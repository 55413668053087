import React, {useState} from 'react';
import styles from './SidebarPanel.module.scss';
import {RoutePaths} from '../../../router/RoutePaths';
import IconButton from '../../util/widgets/IconButton/IconButton';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Sidebar} from 'react-pro-sidebar';
import {GetMenuItemsForRole} from './MenuItemsForRole';
import {AppTheme, getLogoWhiteTextLink} from "../../../appTheme";
import {WebState} from "../../../redux/types/WebState";
import {userStore} from "../../../common/redux/entities/user";
import {roleStore} from "../../../common/redux/entities/role";

type Props = ReturnType<typeof mapStateToProps>;


const SidebarPanelMobile = (props: Props) => {
  const {currentUser} = props;
  const [toggled, setToggled] = useState(false);
  return (
    <div>
      <Sidebar onBackdropClick={() => setToggled(false)} toggled={toggled} breakPoint='all' backgroundColor={AppTheme.colors.primary} color={'#ffffff'}>
        <a href={RoutePaths.hcsiWebsitePath} style={{display: 'flex', margin: '10px'}}>
          <img src={getLogoWhiteTextLink()} alt='HCSI Portal' className={styles['logo']}/>
        </a>
        {GetMenuItemsForRole(currentUser)}
      </Sidebar>
      <div className={styles['sidebar-burger-icon']}>
        <IconButton icon='bars' size={'3x'} onClick={() => setToggled(!toggled)}/>
      </div>
    </div>
  );

};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({}, dispatch)});
const mapStateToProps = (state: WebState) => ({
  currentUser: userStore.selectors.getCurrentUser(state),
  roleName: roleStore.selectors.getById(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarPanelMobile);
