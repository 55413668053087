import {api} from './index';
import {DocumentModel} from '../common/redux/entities/document';
import {DocumentManagementState} from '../common/redux/documentConfiguration';
import {Dictionary} from '../common/util';


export async function getDocumentManagementState() {
  return (await api.get<DocumentManagementState>('/document/management')).data;
}

export async function getAssignedDocuments() {
  return (await api.get<Dictionary<DocumentModel>>('/document/assigned')).data;
}

export async function upsertDocument(value: FormData) {
  return (await api.post<DocumentModel>('/Document', value)).data;
}

export async function deleteDocument(id: string) {
  return (await api.delete<string>(`/Document/${id}` )).data as any as DocumentModel;
}

export async function openDocument(id: string) {
  const documentPath = (await api.get<string>(`Document/open/${id}`)).data;
  window.open(`${process.env.REACT_APP_STORAGE_URL}${documentPath}`);
}
