import {combineReducers, Dispatch} from 'redux';
import {CommonDispatch, CommonState} from '../index';
import jwt_decode from 'jwt-decode';
import {AuthToken} from '../../types/AuthToken';
import storage from 'redux-persist/es/storage';
import {
  createStandardActions,
  GetActions,
  placeholder,
  readonly,
  standardItemsReducer
} from '../utils';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {createAction, createReducer} from 'typesafe-actions';
import {
  acceptTos,
  approveUser,
  archiveUser,
  createUser,
  getVideosToWatch, getVolunteerCalendarUsersData, getVolunteerGroupUsers,
  restoreUser, toggleGenericTwiliioNotificationStatus, updateUserPreferences,
  upsertUser
} from '../../../api/userManagementApi';
import {Video} from './video';
import {setVideoWatched} from '../../../api/videoManagementApi';
import {VolunteerGroupUser} from './volunteerGroup';
import {Role} from './role';
import {Dictionary} from '../../util';

export interface LoginRequest {
  email: string;
  password: string;
}

export interface UserRegistrationForm extends User {
  password: string;
  confirmPassword: string;
}

export interface User extends UserPreferences{
  id: string;
  email: string;
  archivedAt: string | null;
  roleId: string;
  role: Role;
  name: string;
  tosAccepted: boolean;
  receiveTextNotifications: boolean;
  accountStatus: AccountStatus;
  videosToWatch: Video[];
  videosWatched: Video[];
  volunteerGroups: VolunteerGroupUser[];
}

// TODO add text notifications and possibly tos accepted status in user preferences instead of user
export interface UserPreferences {
  phoneNumber: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}


export interface UserNameAndRole extends User {
  roleName: string;
}


export enum AccountStatus {
  EmailValidationRequired = 0,
  PendingApproval = 1,
  Active = 2
}

export interface UserWithToken extends User  {
  token: string;
}

export interface UserState {
  currentUser: User | null;
  items: UserItems;
}

export interface UserItems {
  [key: number]: User;
}

export const userPersistConfig = {
  key: 'users',
  storage: storage,
  whitelist: ['currentUser']
};

const currentUserActions = {
  setCurrentUser: createAction('USER/SET_CURRENT_USER')<User | null>()
};

const partialUserDataActions = {
  overwriteIdNameAndRoleAction: createAction('USER/SET_IDS_AND_NAMES')<Dictionary<User>>(),
  overwriteIdNameAndEmailAction: createAction('USER/SET_IDS_NAMES_AND_EMAILS')<Dictionary<User>>()
};

const actions = createStandardActions(placeholder<User>(), 'USER/SET', 'USER/SAVE');
const selectors = createStandardSelectors(placeholder<User>(), s => getEntities(s).users);

export type UserActions = GetActions<typeof actions> | GetActions<typeof partialUserDataActions>;
type CurrentUserActions = GetActions<typeof currentUserActions>;

export const users = combineReducers<UserState>({
  currentUser: createReducer<User|null, CurrentUserActions>(null)
    .handleAction(currentUserActions.setCurrentUser, (state, action) => action.payload),
  items: standardItemsReducer<User, UserActions>(actions)
    .handleAction(partialUserDataActions.overwriteIdNameAndRoleAction, (state, action) => {
      let returnedState = {...state};
      Object.entries(action.payload).forEach(([key, value]) => {
        const previousUserState = returnedState[key as any];
        returnedState =  {...returnedState, [key as any]: {...previousUserState, id: value.id, name: value.name, role: value.role}};
      });
      return ({...returnedState}) as Dictionary<User>;
    })
    .handleAction(partialUserDataActions.overwriteIdNameAndEmailAction, (state, action) => {
      let returnedState = {...state};
      Object.entries(action.payload).forEach(([key, value]) => {
        const previousUserState = returnedState[key as any];
        returnedState =  {...returnedState, [key as any]: {...previousUserState, id: value.id, name: value.name, email: value.email}};
      });
      return ({...returnedState}) as Dictionary<User>;
    })
});

export const userStore = readonly({
  selectors: {
    ...selectors,
    getCurrentUser: selector(s => selectors.getState(s).currentUser),
    getNonArchivedUsers: selector(s => selectors.getAsArray(s).filter(u => u.archivedAt === null)),
    getNonArchivedActiveUsers: selector(s => selectors.getAsArray(s).filter(u => u.archivedAt === null && u.accountStatus === AccountStatus.Active)),
    getArchivedUsers: selector(s => selectors.getAsArray(s).filter(u => u.archivedAt !== null)),
    isAdministrator: selector(s => (selectors.getState(s).currentUser)?.role?.roleName === 'Administrator'),
    isShelterManager: selector(s => (selectors.getState(s).currentUser)?.role?.roleName === 'Shelter Manager'),
    isUser: selector(s =>
      (selectors.getState(s).currentUser)?.role.roleName === 'Staff' ||
      (selectors.getState(s).currentUser)?.role.roleName === 'Volunteer' ||
      (selectors.getState(s).currentUser)?.role.roleName === 'Site Captain'),
    getNumberOfVideosToWatch: selector(s => selectors.getState(s).currentUser?.videosToWatch?.length),
    getVideosToWatch: selector(s => selectors.getState(s).currentUser?.videosToWatch),
    getVideosWatched: selector(s => selectors.getState(s).currentUser?.videosWatched)
  },
  actions: {
    ...actions,
    ...currentUserActions,
    ...partialUserDataActions,
    upsert: (form: User) => async (dispatch: CommonDispatch) => {
      let response;
      if(form.id === '') {
        response = await createUser(form);
      } else {
        response = await upsertUser(form);
      }
      dispatch(userStore.actions.save(response));
      return response;
    },
    archiveUser: (id: string) => async (dispatch: CommonDispatch) => {
      const user: User = await archiveUser(id);
      dispatch(userStore.actions.save(user));
    },
    restoreUser: (id: string) => async (dispatch: CommonDispatch) => {
      const user: User = await restoreUser(id);
      dispatch(userStore.actions.save(user));
    },
    loadVideosToWatch: () => async (dispatch: Dispatch) => {
      const user: User | null = await getVideosToWatch();
      dispatch(userStore.actions.setCurrentUser(user));
    },
    setVideoWatched: (id: string) => async (dispatch: Dispatch) => {
      await setVideoWatched(id);
      const user: User | null = await getVideosToWatch();
      dispatch(userStore.actions.setCurrentUser(user));
    },
    updateUserPreferences: (form: UserPreferences) => async (dispatch: Dispatch) => {
      const response = await updateUserPreferences(form);
      dispatch(userStore.actions.save(response));
      dispatch(userStore.actions.setCurrentUser(response));
      return response;
    },
    approve: (id: string) => async (dispatch: CommonDispatch) => {
      const user: User = await approveUser(id);
      dispatch(userStore.actions.save(user));
    },
    loadUsersVolunteerCalendarInformation: () => async (dispatch: CommonDispatch) => {
      const response = await getVolunteerCalendarUsersData();
      await dispatch(userStore.actions.overwriteIdNameAndRoleAction(response));
    },
    loadVolunteerGroupUsers: () => async (dispatch: Dispatch) => {
      const groupUsers = await getVolunteerGroupUsers();
      dispatch(userStore.actions.set(groupUsers));
    },
    toggleGenericTwillioNotifications: () => async (dispatch: Dispatch) => {
      const response = await toggleGenericTwiliioNotificationStatus();
      dispatch(userStore.actions.setCurrentUser(response));
    },
    acceptTos: () => async (dispatch: Dispatch) => {
      const response = await acceptTos();
      dispatch(userStore.actions.setCurrentUser(response));
    }
  }
});

export function isAuthenticated(state: CommonState) {
  const localUser: User | null = userStore.selectors.getCurrentUser(state);
  const token = localStorage.getItem('token');
  if (token && localUser) {
    const time = Math.ceil((new Date()).getTime() / 1000);
    const decoded = jwt_decode<AuthToken>(token);
    return time < decoded['exp'];
  }
  return false;
}

export function getRole(state: CommonState) {
  // @ts-ignore
  const localUser: User = userStore.selectors.getCurrentUser(state);
  return localUser.role.roleName;
}

export type mapIsAuthenticatedToPropsType = ReturnType<typeof mapIsAuthenticatedToProps>;
export const mapIsAuthenticatedToProps = (state: CommonState) => ({ authenticated: isAuthenticated(state)});

export type mapIsAdministratorToPropsType = ReturnType<typeof mapIsAdministratorToProps>;
export const mapIsAdministratorToProps = (state: CommonState) => ({ administrator: userStore.selectors.isAdministrator(state)});
export type mapIsVolunteerToPropsType = ReturnType<typeof mapIsShelterManagerToProps>;
export const mapIsShelterManagerToProps = (state: CommonState) => ({ shelterManager: userStore.selectors.isShelterManager(state)});
export type mapIsUserToPropsType = ReturnType<typeof mapIsUserToProps>;
export const mapIsUserToProps = (state: CommonState) => ({ user: userStore.selectors.isUser(state)});

export type mapRoleToPropsType = ReturnType<typeof mapRoleToProps>;
export const mapRoleToProps = (state: CommonState) => ({ roleName: getRole(state)});
