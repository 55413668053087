import {boolean, date, number, object, string} from 'yup';
import {ShiftCreationInterface} from './ShiftCreationModal';

const dayBefore = () => {
  const d = new Date();
  return new Date(d.setDate(d.getDate()-1));
};

// @ts-ignore
export const ShiftCreationModalSchema = object<ShiftCreationInterface>({
  startDate: date().nullable()
    .required('A Start Date is required').min(dayBefore(), 'Start date and time must be sometime in the future'),
  endDate: date().nullable().required('End date is required')
    .when('startDate', (startDate, schema) =>
        startDate && schema.min(startDate, 'End date must be the same as or after the shift start date')),
  startTime: string().nullable().required('You must enter a start time'),
  endTime: string().nullable().required('You must enter a end time')
    .test('startTime', 'End time must be before Start time', (endTime, context) => {
      if(endTime !== undefined && context.parent.startTime !== undefined) {
        const startParts = context.parent.startTime.split(':');
        const endParts = (endTime as any).split(':');
        const startHours = startParts[0];
        const startMinutes = startParts[1];
        const endHours = endParts[0];
        const endMinutes = endParts[1];
        if(endHours > startHours) {
          return true;
        } else if(endHours === startHours) {
          if(endMinutes > startMinutes) {
            return true;
          }
        }
      }
      return false;
    }),
  catering: boolean(),
  volunteersNeeded: number().required().min(1, 'A shift must require at least 1 volunteer')
});

