import {Dispatch} from 'redux';
import {Dictionary} from '../util';
import {Role, roleStore} from './entities/role';
import {batchActions} from 'redux-batched-actions';
import {getAssignedDocuments, getDocumentManagementState} from '../../api/documentManagementApi';
import {DocumentModel, documentStore} from './entities/document';

export interface DocumentManagementState {
  documents: Dictionary<DocumentModel>;
  roles: Dictionary<Role>;
}

export const loadDocumentManagementData = () => async (dispatch: Dispatch) => {
  const response: DocumentManagementState = await getDocumentManagementState();
  await dispatch(batchActions([
    dispatch(documentStore.actions.set(response.documents)),
    dispatch(roleStore.actions.set(response.roles))
  ], 'LOAD_USER_MANAGEMENT_STATE'));
};

export const loadAssignedDocumentsData = () => async (dispatch: Dispatch) => {
  const response: Dictionary<DocumentModel> = await getAssignedDocuments();
  await dispatch(documentStore.actions.set(response));
};
