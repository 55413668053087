import React, {FormEvent, useState} from 'react';
import styles from './ShiftSignupModal.module.scss';
import {Button, Modal, Row, Form as BSForm, Spinner, Col, Alert} from 'react-bootstrap';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../redux/types/WebState';
import {connect} from 'react-redux';
import {Form, Formik, FormikErrors} from 'formik';
import {ShiftSignupModalSchema} from './ShiftSignupModalSchema';
import {Shift, shiftStore} from '../../../common/redux/entities/shift';
import {format, getDate, parseISO} from 'date-fns';
import InformationLabel from '../../../components/util/form-components/InformationLabel/InformationLabel';
import {userStore} from '../../../common/redux/entities/user';
import {VolunteerGroupUser} from '../../../common/redux/entities/volunteerGroup';
import {convertToDropDownOptions} from '../../../common/util';
import {makeCateringShiftSignup, makeVolunteerShiftSignup} from '../../../common/util/factory';
import ShiftSignupUserSelection from './ShiftSignupGroupUserSelection';
import {useMount} from '../../../hooks/useMount';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../../common/util/http';
import {CenteredSpinner} from '../../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {shiftVolunteerStore} from '../../../common/redux/entities/shiftVolunteer';
import {ConfirmationDialog} from '../../../components/util/ConfirmationDialog/ConfirmationDialog';
import {ShiftType} from '../../../components/util/VolunteerCalendar/VolunteerCalendar';
import Input from '../../../components/util/form-components/formik-inputs/Input/Input';
import {InputRow} from '../../../components/util/form-components/InputRow';
import {ListTable} from '../../../components/util/lists/ListTable/ListTable';

export interface VolunteerSignupInterface {
  users: VolunteerGroupUserSignUpInterface[];
}

export interface VolunteerGroupUserSignUpInterface extends VolunteerGroupUser {
  initialValue: boolean;
}

export interface CateringSignupInterface {
  businessName: string;
  description: string;
}

type Props = {
  shiftType: ShiftType;
  existingShift: Shift;
  onSubmit: () => void;
  onCancel: () => void;
} & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const ShiftSignupModal = (props: Props) => {
  const {existingShift, onSubmit, onCancel, volunteerGroupUsers, shiftVolunteersInGroup, getUserByGuid,
    currentUser, shiftType, actions: {loadCurrentGroupVolunteersForShift, shiftSignup, cateringSignup, leaveShift}} = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);
  const getFieldName = (name: keyof VolunteerSignupInterface | keyof CateringSignupInterface) => name;
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [confirmGroupSignup, setConfirmGroupSignup] = useState<VolunteerSignupInterface>({users: []});

  useMount(async () => {
    try {
      await loadCurrentGroupVolunteersForShift(existingShift.id);
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load volunteer group members'}));
    }
    setLoading(false);
  });


  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    data: VolunteerSignupInterface | CateringSignupInterface ,
    validate: (data: VolunteerSignupInterface | CateringSignupInterface) => Promise<FormikErrors<VolunteerSignupInterface | CateringSignupInterface>>,
    handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void
  ) => {
    setIsSubmitting(true);
    setErrorMessage('');
    e.persist();
    e.preventDefault();
    const errors = await validate(data);
    if (Object.values(errors).length !== 0) {
      handleSubmit(e);
      setIsSubmitting(false);
    } else {
      if(shiftType === 'volunteer' && ((data as VolunteerSignupInterface).users.length > 0)) {
        await setConfirmGroupSignup(data as VolunteerSignupInterface);
        return;
      }
      finishSubmit(data);
    }
  };

  const finishSubmit = async (data: VolunteerSignupInterface | CateringSignupInterface) => {
    try {
      if(shiftType === 'volunteer') {
        await shiftSignup(existingShift.id, data as VolunteerSignupInterface);
      } else {
        await cateringSignup(existingShift.id, data as CateringSignupInterface);
      }
      await onSubmit();
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e));
    }
    setConfirmGroupSignup({users: []});
    setIsSubmitting(false);
  };

  const onLeave = async () => {
    setIsSubmitting(true);
    setErrorMessage('');
    try {
      await leaveShift(existingShift.id);
      await onSubmit();
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e));
    }
    setIsSubmitting(false);
  };

  const renderEndDate = () => {
    const start = parseISO(existingShift.startDate);
    const end = parseISO(existingShift.endDate);
    if (getDate(start) < getDate(end)){
      return ` - ${dayFormatter(existingShift.endDate)}`;
    }
    return '';
  };

  const dayFormatter = (date: string) => {
    return format(parseISO(date), 'MMMM d, yyyy');
  };

  const timeFormatter = (date: string) => {
    return format(parseISO(date), 'p');
  };

  const isSignedUp = () => {
    return existingShift.shiftVolunteers.find(sv => sv.volunteerId === currentUser!.id);
  };

  const getMakeShiftType = () => {
    if(shiftType === 'volunteer') {
      return makeVolunteerShiftSignup(existingShift, shiftVolunteersInGroup, volunteerGroupUsers, true);
    } else {
      return makeCateringShiftSignup(existingShift);
    }
  };

  const getHeader = () => {
    if(isSignedUp() && !existingShift.catering) {
      return (<h4>Are you sure you want to leave the shift "{existingShift.title}?"</h4>);
    } else if(isSignedUp() && existingShift.catering) {
      return (<h4>Are you sure you want to leave the shift "{existingShift.title}?"</h4>);
    }
    if(!existingShift.catering) {
      return (<h4>Are you sure you want to sign up for the shift "{existingShift.title}?"</h4>);
    } else {
      return (<h4>Are you sure you want to cater for the shift "{existingShift.title}?"</h4>);
    }
  };

  const renderContent = () => (
    <Modal show={true} className={styles['on-top']} size={'lg'} centered={true}>
      <Modal.Body>
        <Formik<VolunteerSignupInterface | CateringSignupInterface>
          initialValues={getMakeShiftType()}
          enableReinitialize={true}
          validationSchema={ShiftSignupModalSchema} onSubmit={() => undefined}>
          {({values, validateForm, handleChange, handleSubmit}) => (
            <Form noValidate={true} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
              <Row style={{paddingBottom: '1rem'}}>
                <Col>
                  {getHeader()}
                </Col>
              </Row>
              <InformationLabel label={'Date:'} data={`${dayFormatter(existingShift.startDate)}${renderEndDate()}`}/>
              <InformationLabel label={'Time:'} data={`${timeFormatter(existingShift.startDate)} - ${timeFormatter(existingShift.endDate)}`}/>
              <InformationLabel label={'Volunteers:'} data={`${existingShift.shiftVolunteers?.length ?? 0} / ${existingShift.volunteersNeeded}`}/>
              {shiftType !== 'volunteer' ?
                <>
                  <InputRow label={`Business Name`} hintText={'(optional)'} labelSize={3} columnSize={4} style={{paddingTop: '10px'}}>
                    <Input name={getFieldName('businessName')} type={'text'}/>
                  </InputRow>
                  <InputRow label={'Meal Description'} labelSize={3} columnSize={6} style={{paddingTop: '10px'}}>
                    <Input name={getFieldName('description')} type={'textarea'}/>
                  </InputRow>
                </>
                : null}
              {shiftType === 'volunteer' && existingShift.shiftVolunteers.length ?
                <Col>
                  <h6>Current Volunteers</h6>
                  <ol type={'1'} style={{paddingLeft: '15px'}}>
                    {existingShift.shiftVolunteers.map((volunteer, index) =>
                      <li key={index} style={{paddingTop: '5px'}}>
                        <Row>{getUserByGuid(volunteer.volunteerId)?.name ?? 'Name not found'}</Row>
                      </li>
                    )}
                  </ol>
                </Col> : null
              }
              <Row>
                <BSForm.Group className={styles['form-buttons']}>
                  <Button onClick={onCancel} variant={'danger'} className={styles['close-button']} disabled={isLeaving || isSubmitting}>
                    Cancel
                  </Button>
                  {isSignedUp() ?
                    <Button onClick={() => setIsLeaving(true)} variant={'danger'} style={{marginRight: '5px'}} disabled={isLeaving || isSubmitting}>
                      {!isLeaving ? 'Leave Shift' :
                        <Spinner animation='border' role='status'>
                          <span className='sr-only'>Loading...</span>
                        </Spinner>
                      }
                    </Button>
                    : null}
                  <Button type={'submit'} variant={'success'} disabled={isLeaving || isSubmitting}>
                    {!isSubmitting ? 'Submit' :
                      <Spinner animation='border' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </Spinner>
                    }
                  </Button>
                </BSForm.Group>
              </Row>
              {isLeaving && (
                <ConfirmationDialog
                  onAccept={async () => {
                    setIsLeaving(false);
                    await onLeave();
                  }}
                  onDecline={async () => { setIsLeaving(false); }}
                  open={isLeaving}
                  prompt='Are you sure you want leave this shift?'
                  positiveText='Yes'
                  negativeText='No'
                  positiveVariant='success'
                  negativeVariant='danger'
                />
              )}
              {(shiftType === 'volunteer' && confirmGroupSignup?.users.length !== 0) ? (
                <ConfirmationDialog
                  onAccept={async () => {
                    await finishSubmit(confirmGroupSignup!);
                  }}
                  onDecline={async () => {
                    await setConfirmGroupSignup({users: []});
                    await setIsSubmitting(false);

                  }}
                  open={confirmGroupSignup?.users.length !== 0}
                  prompt='Are you sure you want to assign these group members to this shift?
                  ONLY group members can remove themselves from this shift.'
                  positiveText='Yes'
                  negativeText='No'
                  positiveVariant='success'
                  negativeVariant='danger'
                />
              ) : null}
              {errorMessage !== '' ?
                <div style={{marginTop: '1rem'}}>
                  <Alert variant='danger'>{errorMessage}</Alert>
                </div>
                : null}
              {loading ?  <CenteredSpinner/> : (
                volunteerGroupUsers.length > 0 && shiftType === 'volunteer' ?
                  <ShiftSignupUserSelection
                    userDropDownOptions={convertToDropDownOptions(volunteerGroupUsers)}
                    users={(values as VolunteerSignupInterface).users}
                    editable={true}
                    prefix={getFieldName('users')}
                  /> : null
              )}
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );

  return (
    <>
      {renderContent()}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadCurrentGroupVolunteersForShift: shiftVolunteerStore.actions.getCurrentShiftGroupVolunteers,
    shiftSignup: shiftStore.actions.signup,
    cateringSignup: shiftStore.actions.cateringSignup,
    leaveShift: shiftStore.actions.leaveShift
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  calendarEvents: shiftStore.selectors.getAsArray(state),
  volunteerGroupUsers: userStore.selectors.getNonArchivedActiveUsers(state),
  shiftVolunteersInGroup: shiftVolunteerStore.selectors.getAsArray(state),
  getUserByGuid: userStore.selectors.getByIdGuid(state),
  currentUser: userStore.selectors.getCurrentUser(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftSignupModal);

