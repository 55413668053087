import {Dispatch} from 'redux';
import {Dictionary} from '../util';
import {getVideoManagementState} from '../../api/videoManagementApi';
import {Video, videoStore} from './entities/video';
import {Role, roleStore} from './entities/role';
import {batchActions} from 'redux-batched-actions';

export interface VideoManagementState {
  videos: Dictionary<Video>;
  roles: Dictionary<Role>;
}

export const loadVideoManagementData = () => async (dispatch: Dispatch) => {
  const response: VideoManagementState = await getVideoManagementState();
  await dispatch(batchActions([
    dispatch(videoStore.actions.set(response.videos)),
    dispatch(roleStore.actions.set(response.roles))
  ], 'LOAD_USER_MANAGEMENT_STATE'));
};
