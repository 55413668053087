import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {CommonDispatch} from '../index';
import {
  deleteVolunteerGroup,
  deleteVolunteerGroupUsers,
  getUserVolunteerGroupState, leaveVolunteerGroup,
  upsertVolunteerGroup
} from '../../../api/userManagementApi';
import {createAction} from 'typesafe-actions';
import {userStore} from './user';


export interface VolunteerGroup {
  id: string;
  leaderId: string;
  name: string;
  members: VolunteerGroupUser[];
}

export interface VolunteerGroupUser {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
}

export interface VolunteerGroupMember {
  volunteerId: string;
  volunteerGroupId: string;
}

export const deleteAction = createAction('VOLUNTEER_GROUP/DELETE')<string>();
const actions = createStandardActions(placeholder<VolunteerGroup>(), 'VOLUNTEER_GROUP/SET', 'VOLUNTEER_GROUP/SAVE');
const selectors = createStandardSelectors(placeholder<VolunteerGroup>(), s => getEntities(s).volunteerGroups);
export type VolunteerGroupActions = GetActions<typeof actions> | ReturnType<typeof deleteAction>;;
export const volunteerGroups = combineReducers({
  items: standardItemsReducer<VolunteerGroup, VolunteerGroupActions>(actions)
    .handleAction(deleteAction, (state, action) => {
      const newState = {...state};
      delete newState[action.payload as any];
      return newState;
    })
});

export const volunteerGroupStore = readonly({
  selectors: {
    ...selectors,
    getGroupByLeaderId: selector(s => (id: string) => selectors.getAsArray(s).filter(v => v.leaderId === id))
  },
  actions: {
    ...actions,
    upsert: (form: VolunteerGroup) => async (dispatch: CommonDispatch) => {
      const response = await upsertVolunteerGroup(form);
      await dispatch(volunteerGroupStore.actions.save(response));
      const newUserState = await getUserVolunteerGroupState();
      await dispatch(userStore.actions.set(newUserState));
      return response;
    },
    delete: (id: string) => async (dispatch: CommonDispatch) => {
      const response = await deleteVolunteerGroup(id);
      dispatch(deleteAction(response));
      const newUserState = await getUserVolunteerGroupState();
      await dispatch(userStore.actions.set(newUserState));
      return response;
    },
    leave: (id: string) => async (dispatch: CommonDispatch) => {
      await leaveVolunteerGroup();
      dispatch(deleteAction(id));
      const newUserState = await getUserVolunteerGroupState();
      await dispatch(userStore.actions.set(newUserState));
    }
  }
});
