import React, {FormEvent, useState} from 'react';
import styles from '../RegistrationFormStyles.module.scss';
import {Alert, Col, Form, Row} from 'react-bootstrap';
import {LoadingButton} from '../../../../components/util/widgets/LoadingButton/LoadingButton';
import {Formik, FormikErrors} from 'formik';
import {UsernameAndPasswordFormInterface} from '../UsernameAndPasswordForm/UsernameAndPasswordForm';
import {usernameAndPasswordFormSchema} from '../UsernameAndPasswordForm/UsernameAndPasswordFormSchema';
import Input from '../../../../components/util/form-components/formik-inputs/Input/Input';
import {AxiosError} from 'axios';
import {IRegistrationRequest} from '../../Register';
import {contactInformationFormSchema} from './ContactInformationFormSchema';
import {RedErrorMessage} from '../../../../components/util/form-components/RedErrorMessage/RedErrorMessage';
import {makeContactInformationForm} from '../../../../common/util/factory';
import {handleAxiosError} from '../../../../common/util/http';

export interface ContactInformationFormInterface {
  phoneNumber: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

interface Props {
  errorMessage: string;
  onSubmit: (request: ContactInformationFormInterface) => void;
}

export const ContactInformationForm = (props: Props) => {
  const {onSubmit, errorMessage} = props;

  const getFieldName = (name: keyof ContactInformationFormInterface) => name;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    data: ContactInformationFormInterface,
    validate: (data: ContactInformationFormInterface) => Promise<FormikErrors<ContactInformationFormInterface>>,
    handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void
  ) => {
    setIsSubmitting(true);
    e.persist();
    e.preventDefault();
    const errors = await validate(data);
    if (Object.values(errors).length !== 0) {
      handleSubmit(e);
      setIsSubmitting(false);
    } else {
        const request = {
          ...data
        };
        await onSubmit(request);
    }
    setIsSubmitting(false);
  };

  return (
    <Formik<ContactInformationFormInterface>
      initialValues={makeContactInformationForm()}
      onSubmit={() => undefined}
      validationSchema={contactInformationFormSchema}
    >
      {({values,validateForm, handleSubmit}) => (
        <Form noValidate={true} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
          <Col lg={12}>
            <Form.Group as={Row}>
              <Col>
                <Input
                  type='text'
                  name={getFieldName('phoneNumber')}
                  placeholder='Phone number'
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} style={{paddingTop: '10px', marginTop: '1rem'}}>
              <Col>
                <Input
                  type='text'
                  name={getFieldName('street')}
                  placeholder='Street'
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} style={{paddingTop: '10px', marginTop: '1rem'}}>
              <Col>
                <Input
                  type='text'
                  name={getFieldName('city')}
                  placeholder='City'
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} style={{paddingTop: '10px', marginTop: '1rem'}}>
              <Col>
                <Input
                  type='text'
                  name={getFieldName('state')}
                  placeholder='State'
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} style={{paddingTop: '10px', marginTop: '1rem'}}>
              <Col>
                <Input
                  type='text'
                  name={getFieldName('zip')}
                  placeholder='ZIP code'
                />
              </Col>
            </Form.Group>
            <Row className={styles['login-button']}>
              <LoadingButton type='submit' loading={isSubmitting} label='Next'/>
            </Row>
          </Col>
        </Form>
      )}
    </Formik>
  );
};
