import React from 'react';
import {Container} from 'react-bootstrap';
import {PrivacyPolicyText} from './PrivacyPolicyText';

const PrivacyPolicy = () => {

  return (
    <>
      <div>
        <h2>Privacy Policy</h2>
      </div>
      <div>
        {PrivacyPolicyText}
      </div>
    </>
  );
};

export default PrivacyPolicy;
