import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {combineReducers} from 'redux';
import {CommonDispatch} from '../index';
import {
  deleteDocument,
  upsertDocument
} from '../../../api/documentManagementApi';
import {createAction} from 'typesafe-actions';


export interface DocumentModel {
  id: string;
  name: string;
  file: FormData | null;
  url: string | null;
  documentRoleAssignments: string[];
}

export const deleteDocumentAction = createAction('DOCUMENT/DELETE')<string>();
const actions = createStandardActions(placeholder<DocumentModel>(), 'DOCUMENT/SET', 'DOCUMENT/SAVE');
const selectors = createStandardSelectors(placeholder<DocumentModel>(), s => getEntities(s).documents);
export type DocumentActions = GetActions<typeof actions> | ReturnType<typeof deleteDocumentAction>;

export const documents = combineReducers({items:
    standardItemsReducer<DocumentModel, DocumentActions>(actions)
      .handleAction(deleteDocumentAction, (state, action) => {
        const newState = {...state};
        delete newState[action.payload as any];
        return newState;
      })
});
export const documentStore = readonly({
  selectors: {
    ...selectors,
    getDocumentById: selector(s => (documentId: string) => selectors.getAsArray(s).filter(d => d.id === documentId)[0])
  },
  actions: {
    ...actions,
    upsert: (value: FormData) => async (dispatch: CommonDispatch) => {
      const response = await upsertDocument(value);
      await dispatch(actions.save(response));
      return response;
    },
    delete: (id: string) => async (dispatch: CommonDispatch) => {
      await deleteDocument(id);
      await dispatch(deleteDocumentAction(id));
    }
  }
});
