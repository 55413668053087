import React, {useState} from 'react';
import {Card, Col, Container, Row} from 'react-bootstrap';
import {bindActionCreators, Dispatch} from 'redux';
import {userStore} from '../../common/redux/entities/user';
import {WebState} from '../../redux/types/WebState';
import {connect} from 'react-redux';
import styles from '../Configuration/DocumentManagement/DocumentManagement.module.scss';
import {CenteredSpinner} from '../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {DocumentModel, documentStore} from '../../common/redux/entities/document';
import {loadAssignedDocumentsData} from '../../common/redux/documentConfiguration';
import {useMount} from '../../hooks/useMount';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../common/util/http';
import {openDocument} from '../../api/documentManagementApi';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const UserDocuments = (props: Props) => {
  const {documents, actions: {loadDocuments}} = props;
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useMount(async () => {
    try {
      await loadDocuments();
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load documents'}));
    }
    setLoading(false);
  });


  const isLocalDocument = (url: string) => url.includes('https://' || 'http://' || '.com');

  const directLinkFormatter = (document: DocumentModel, index: number) => {
    return (
      <div key={`link-${index}`} style={{marginRight: '10px', display: 'flex', justifyContent: 'center'}}>
        {isLocalDocument(document.url!) ?
          <a key={`link-${index}-anchor`} target={'_blank'} href={document.url!}>{document.name.length > 0 ? document.name : document.url}</a>
          : <label key={`link-${index}-label`} className={styles['link']} onClick={() => openDocument(document.id)}>
            {document.name.length > 0 ? document.name : document.url}
          </label>}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div className='justify-content-md-center' style={{marginTop: '1rem'}}>
          <h2>Documents</h2>
        </div>
        <Card.Body>
          <Col className='justify-content-md-center'>
            {documents.map((d, i) => directLinkFormatter(d, i))}
          </Col>
        </Card.Body>
      </>
    );
  };

  return (
    <div>
      {loading ?  <CenteredSpinner/> : (
        errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
          renderContent()
      )}
    </div>
  );

};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadDocuments: loadAssignedDocumentsData

  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  documents: documentStore.selectors.getAsArray(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDocuments);
