
import {connect} from 'react-redux';
import {mapIsShelterManagerToProps, mapIsVolunteerToPropsType} from "../../../common/redux/entities/user";

type ShelterManagerRouteProps = mapIsVolunteerToPropsType & {
  children: JSX.Element;
};

// TODO once manager role is implemented, create prop for whether managers can access
function ShelterManagerComponent({children, shelterManager}: ShelterManagerRouteProps) {
  return shelterManager ? children : null;
}

export default connect(mapIsShelterManagerToProps)(ShelterManagerComponent);
