import {AccountStatus, User, UserPreferences} from '../../redux/entities/user';
import {
  CreateVideoInterface,
  RoleAssignments
} from '../../../pages/Configuration/VideoManagement/components/VideoModal/VideoModal';
import {
  UpdateVideoInterface
} from '../../../pages/Configuration/VideoManagement/components/VideoModal/UpdateVideoModal';
import {VolunteerGroup, VolunteerGroupUser} from '../../redux/entities/volunteerGroup';
import {ShiftCreationInterface} from '../../../pages/Configuration/CalendarManagement/components/ShiftCreationModal/ShiftCreationModal';
import {Shift} from '../../redux/entities/shift';
import {
  ShiftUpdateInterface
} from '../../../pages/Configuration/CalendarManagement/components/ShiftUpdateModal/ShiftUpdateModal';
import {
  CateringSignupInterface, VolunteerGroupUserSignUpInterface,
  VolunteerSignupInterface
} from '../../../pages/VolunteerSignup/VolunteerShiftSignupModal/ShiftSignupModal';
import {ShiftVolunteer} from '../../redux/entities/shiftVolunteer';
import {
  UsernameAndPasswordFormInterface
} from '../../../pages/Register/components/UsernameAndPasswordForm/UsernameAndPasswordForm';
import {
  ContactInformationFormInterface
} from '../../../pages/Register/components/ContactInformationForm/ContactInformationForm';
import {GeneralSmsAlert, GroupEmail} from '../../../pages/Export/HcsiTextAlerts';
import {
  ShiftDeletionModalInterface
} from '../../../pages/Configuration/CalendarManagement/components/ShiftDeletionModal/ShiftDeletionModal';
import {parseISO} from 'date-fns';
import {DocumentModel} from '../../redux/entities/document';

export function makeUser(): User {
  return {
    id: '',
    email: '',
    archivedAt: null,
    name: '',
    phoneNumber: '',
    role: {
      id: '',
      roleName: ''
    },
    roleId: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    receiveTextNotifications: false,
    tosAccepted: false,
    accountStatus: AccountStatus.EmailValidationRequired,
    videosToWatch: [],
    videosWatched: [],
    volunteerGroups: []
  };
}


export function makeUpdateVideoRequest(): UpdateVideoInterface {
  return {
    id: '',
    title: '',
    description: '',
    thumbnailFile: '' as any,
    videoRoleAssignments: []
  };
}



export function makeCreateVideoRequest(): CreateVideoInterface {
  return {
    title: '',
    description: '',
    thumbnailFile: '' as any,
    videoFile: '' as any,
    videoRoleAssignments: []
  };
}

export function makeRoleList(): RoleAssignments[] {
  return [];
}


export function makeUserPreferences(user: User): UserPreferences {
  return {
    city: user.city,
    state: user.state,
    zip: user.zip,
    street: user.street,
    phoneNumber: user.phoneNumber
  };
}

export function makeVolunteerGroup(group: VolunteerGroup | null): VolunteerGroup {
  return {
    id: group !== null ? group.id : '',
    leaderId: group !== null ? group.leaderId : '',
    name: group !== null ? group.name : '',
    members: group !== null ? group.members : []
  };
}

export function makeVolunteerGroupUser(user: User): VolunteerGroupUser {
  return {
    id: user.id,
    email: user.email,
    name: user.name,
    phoneNumber: user.phoneNumber
  };
}

export function makeVolunteerGroupUserSignUpInterface(user: User, initialValue: boolean): VolunteerGroupUserSignUpInterface {
  return {
    id: user.id,
    email: user.email,
    name: user.name,
    phoneNumber: user.phoneNumber,
    initialValue: initialValue
  };
}

export function makeShiftCreationRequest(type: 'volunteer' | 'catering'): ShiftCreationInterface {
  return {
    id: '',
    title: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    catering: type === 'catering',
    description: '',
    businessName: '',
    volunteersNeeded: 1
  };
}

export function makeShiftUpdateRequest(shift: Shift): ShiftUpdateInterface {
  return {
    id: shift.id,
    title: shift.title ?? '',
    volunteersNeeded: shift.volunteersNeeded,
    currentVolunteers: shift.shiftVolunteers?.length ?? 0,
    description: shift.description,
    shiftVolunteerIds: shift.shiftVolunteers.map((volunteer) => volunteer.volunteerId)
  };
}

export function makeShiftDeletionRequest(shift: Shift): ShiftDeletionModalInterface {
  return {
    id: shift.id,
    startDate: new Date(parseISO(shift.startDate)),
    endDate: ''
  };
}

export function makeVolunteerShiftSignup(shift: Shift, volunteers: ShiftVolunteer[], users: User[], initialValue: boolean): VolunteerSignupInterface {
  const groupUsers = volunteers.map(v => {
    if(users.find(u => u.id === v.volunteerId)) {
      return makeVolunteerGroupUserSignUpInterface(users.find(u => u.id === v.volunteerId)!, initialValue);
    }
  });
  return {
    users: groupUsers as any as VolunteerGroupUserSignUpInterface[]
  };
}

export function makeCateringShiftSignup(shift: Shift): CateringSignupInterface {
  return {
    businessName: shift.businessName,
    description: shift.description
  };
}

export function makeGeneralSmsAlert(): GeneralSmsAlert {
  return {
    startDate: '',
    endDate: '',
    message: ''
  };
}

export function makeGroupEmail(): GroupEmail {
  return {
    userIds: [],
    roleIds: [],
    groupIds: [],
    title: '',
    subject: '',
    body: ''
  };
}

export function makeUsernameAndPasswordForm(): UsernameAndPasswordFormInterface {
  return {
    email: '',
    name: '',
    password: '',
    confirmPassword: ''
  };
}

export function makeContactInformationForm(): ContactInformationFormInterface {
  return {
    phoneNumber: '',
    street: '',
    city: '',
    state: '',
    zip: ''
  };
}

export function makeDocumentRequest(document: DocumentModel | null): DocumentModel {
  return {
    id: document !== null ? document.id : '',
    name: document !== null ? document.name : '',
    file: document !== null ? document.file : null,
    url: document !== null ? document.url : '',
    documentRoleAssignments: document !== null ? document.documentRoleAssignments : []
  };
}
