import {number, object, string} from 'yup';
import {ShiftUpdateInterface} from './ShiftUpdateModal';

// @ts-ignore
export const VolunteerShiftUpdateModalSchema = object<ShiftUpdateInterface>({
  id: string().required(),
  currentVolunteers: number().required(),
  volunteersNeeded: number()
    .required('')
    .min(1, 'A shift must require at least 1 volunteer')
    .when('currentVolunteers', (currentVolunteers, schema) =>
      schema.min(currentVolunteers, 'You cannot set a shift to have less volunteers than are already signed up.'))
});
