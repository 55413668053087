import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../../redux/types/WebState';
import {ConfirmationDialog} from '../../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import {Alert, Button} from 'react-bootstrap';
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next';
// @ts-ignore
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.js';
import {CenteredSpinner} from '../../../../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../../../../common/util/http';
import {makeDocumentRequest} from '../../../../../common/util/factory';
import DocumentModal from '../DocumentModal/DocumentModal';
import {DocumentModel, documentStore} from '../../../../../common/redux/entities/document';
import {openDocument} from '../../../../../api/documentManagementApi';
import styles from '../../DocumentManagement.module.scss';
import gridStyles from '../../../GridStyles.module.scss';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function DocumentGrid(props: Props) {
  const {actions: {upsertDocument, deleteDocument}, documents} = props;
  const { SearchBar } = Search;
  const [isDeleting, setIsDeleting] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [editingDocument, setEditingDocument] = useState<DocumentModel | null>(null);

  const documentActionsFormatter = (cell: any, document: DocumentModel) => {
    return (
      <>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <IconButton
            icon='edit'
            size={'2x'}
            onClick={() => {
              setEditingDocument(document);
            }}
            color={'#005A9C'}
          />
          <div>
            <IconButton
              icon={'trash-alt'}
              styles={{color: 'red', marginLeft: '.5rem'}}
              size={'2x'}
              iconToolTipText={'Delete Document'}
              onClick={() => setIsDeleting(document.id)}
            />
          </div>
        </div>
      </>
    );
  };


  const isLocalDocument = (url: string | null) => url?.includes('https://' || 'http://' || '.com');

  const fileTypeFormatter = (cell: any, document: DocumentModel) => {
    let fileType = 'File';
    if(document.url?.includes('https://' || 'http://' || '.com')) {
      fileType = 'Url';
    }
    return fileType;
  };

  const directLinkFormatter = (cell: any, document: DocumentModel) => {
    return (
      <>
        <div style={{marginRight: '10px', display: 'flex', justifyContent: 'center'}}>
          {isLocalDocument(document.url) ? <a target={'_blank'} href={document.url!}>{document.url}</a>
            : <label className={styles['link']} onClick={() => openDocument(document.id)}>{document.url}</label>}
        </div>
      </>
    );
  };

  const columns: ColumnDescription[] = [
    {
      dataField: 'id',
      text: 'ID',
      hidden: true,
      sort: true,
      headerStyle: () => {
        return { width: '5rem'};
      },
      style: () => {
        return { width: '5rem'};
      }
    },
    {
      dataField: 'id',
      text: 'test',
      hidden: true
    },
    {
      dataField: 'name',
      text: 'Short Name'
    },
    {
      dataField: 'type',
      formatter: fileTypeFormatter,
      text: 'Link Type',
      isDummyField: true
    },
    {
      dataField: 'link',
      formatter: directLinkFormatter,
      text: 'Link',
      isDummyField: true
    },
    {
      dataField: 'actionsColumn',
      text: '',
      isDummyField: true,
      formatter: documentActionsFormatter,
      headerStyle: () => {
        return { width: '6rem'};
      },
      style: () => {
        return { width: '6rem'};
      }
    }
  ];

  return (
    <ToolkitProvider
      keyField='id'
      data={documents}
      columns={columns}
      search={{
        searchFormatted: true
      }}
    >
      {(tableProps: any) => (
        <>
          <div style={{marginRight: '10px', display: 'flex', flexGrow: 1}}>
            <div style={{marginRight: '10px', display: 'flex', justifyContent: 'flex-end', marginBottom: '10px'}}>
              <Button
                onClick={() => setEditingDocument(makeDocumentRequest(null))}
                style={{backgroundColor: '#005A9C'}}
              >
                Add Document
              </Button>
            </div>
          </div>
          <SearchBar {...tableProps.searchProps} placeholder={'Search'} />
          {isLoading ?  <CenteredSpinner/> :
            <BootstrapTable
              wrapperClasses={gridStyles['responsive-table-wrapper']}
              classes={gridStyles['table-auto']}
              rowStyle={{height: '100%'}}
              {...tableProps.baseProps}
            />
          }
          {isDeleting !== '' && (
            <ConfirmationDialog
              onAccept={async () => {
              await deleteDocument(isDeleting);
              setIsDeleting('');
              }}
              onDecline={async () => { setIsDeleting(''); }}
              open={isDeleting !== ''}
              prompt='Are you sure you want to delete this file/url?'
              positiveText='Yes'
              negativeText='No'
              positiveVariant='danger'
            />
            )}
          {editingDocument ?
            <DocumentModal
              initialDocument={editingDocument}
              editable={true}
              onSubmit={async (value: FormData) => {
                try {
                  setErrorMessage('');
                  await upsertDocument(value);
                } catch (e: AxiosError | any) {
                  setErrorMessage(handleAxiosError(e));
                }
                setEditingDocument(null);
              }}
              onCancel={() => setEditingDocument(null)}
            />
            : null}
          {errorMessage ? <Alert variant='danger'>{errorMessage}</Alert> : null}
        </>
      )}
    </ToolkitProvider>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertDocument: documentStore.actions.upsert,
    deleteDocument: documentStore.actions.delete
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  documents: documentStore.selectors.getAsArray(state),
  getDocumentById: documentStore.selectors.getByIdGuid(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(DocumentGrid);
