import {add, set, format} from 'date-fns';


export const now = set(new Date(), {minutes: 30});
export const endDatePlaceholder = add(now, {hours: 2});

const addHour = (date: Date) => add(date, {hours: 1});

const addDay = (date: Date) => add(date, {days: 1});

const formatter = (date: Date) => format(date, 'p');

export interface EventProps {
  id: number;
  title: string;
  start: Date;
  end: Date;
  open: boolean;
}

export interface EventPropsTwo {
  id: string;
  title: string;
  start: Date;
  end: Date;
  open: boolean;
}

const placeholderEvents: EventProps[] = [
  {
    id: 0,
    title: `${formatter(now)} - ${formatter(endDatePlaceholder)}`,
    start: now,
    end: add(now, {hours: 2}),
    open: true
  },
  {
    id: 1,
    title: `begus`,
    start: addDay(now),
    end: add(addDay(now), {hours: 2}),
    open: true
  },
  {
    id: 2,
    title: `negus`,
    start: add(addDay(now), {hours: 4}),
    end: add(addDay(now), {hours: 6}),
    open: true
  },
  {
    id: 3,
    title: `chegus`,
    start: add(addDay(now), {hours: 6}),
    end: add(addDay(now), {hours: 8}),
    open: true
  }
];

export default placeholderEvents;
