import React from 'react';
import {Container} from 'react-bootstrap';
import {TermsOfServiceText} from './TermsOfServiceText';

const TermsOfService = () => {

  return (
    <>
      <div>
        <p>
          <h2>Terms of Service</h2>
        </p>
      </div>
      <div>
        {TermsOfServiceText}
      </div>
    </>
  );
};

export default TermsOfService;
