import React, {useState} from 'react';
import {WebState} from '../../redux/types/WebState';
import {connect} from 'react-redux';
import {UserPreferences, userStore} from '../../common/redux/entities/user';
import {bindActionCreators, Dispatch} from 'redux';
import styles from './Profile.module.scss';
import {Container, Row, Col, Card} from 'react-bootstrap';
import {CenteredSpinner} from '../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {useMount} from '../../hooks/useMount';
import {handleAxiosError} from '../../common/util/http';
import {RoutePaths} from '../../router/RoutePaths';
import {useStandardEditor} from '../../components/util/form-components/EditorForm/hooks';
import {makeUser, makeUserPreferences} from '../../common/util/factory';
import {InputColumn} from '../../components/util/form-components/InputColumn';
import Input from '../../components/util/form-components/formik-inputs/Input/Input';
import {EditorForm} from '../../components/util/form-components/EditorForm/EditorForm';
import {AxiosError} from 'axios';
import {loadUserPreferences} from '../../common/redux/userPreferences';
import UserVolunteerGroup from './components/UserGroupPreferences/UserVolunteerGroup';
import {AppTheme} from '../../appTheme';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function Profile(props: Props) {
  const {currentUser, actions: {loadUserPreferencesData}} = props;
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useMount(async () => {
    try {
      await loadUserPreferencesData(false);
    } catch (e) {
      setErrorMessage(handleAxiosError(e as any as AxiosError, {connectionMsg: 'Failed to load data'}));
    }
    setLoading(false);
  });
  const getFieldName = (name: keyof UserPreferences) => name;
  const standardEditor = useStandardEditor<UserPreferences>(
    'Profile',
    makeUser(),
    props,
    s => ({
      getEditUrl: () => RoutePaths.profilePaths.Edit,
      getCancelUrl: () => RoutePaths.profilePaths.View,
      onLoadForm: async () => makeUserPreferences(currentUser!),
      onNewForm: async () => makeUserPreferences(currentUser!),
      saveAndRedirect: async (form) => {
        await props.actions.updateUserPreferences(form);
        return RoutePaths.profilePaths.View;
      },
      footer: true,
      editButtonText: 'Edit Profile',
      saveButtonText: 'Save Profile',
      submitButtonColor: AppTheme.colors.oceanBlue
    }));
  const {editable} = standardEditor;

  const renderContent = () => {

    return (
      <Row style={{flexGrow: 1}}>
        <Col>
          <Card className={styles['page-body']} style={{borderLeftWidth: 0, borderRightWidth: 0}}>
            <Card.Body style={{display: 'flex'}}>
              <Col style={{alignItems: 'center', flex: '0.64'}}>
                <h2 style={{display: 'flex', flex: '1', justifyContent: 'center', paddingBottom: '1rem'}}>My Information</h2>
                <Row style={{justifyContent: 'center'}}>
                  <Col style={{justifyContent: 'center'}}>
                    <EditorForm standardEditor={standardEditor}>
                      {({values, setFieldValue}) => {
                        return (
                          <>
                            <InputColumn label='Phone Number' columnSize={12} style={{justifyContent: 'center', alignItems: 'center'}}>
                              <Input name={getFieldName('phoneNumber')} disabled={!editable}/>
                            </InputColumn>
                            <InputColumn label='Street' columnSize={12}>
                              <Input name={getFieldName('street')} disabled={!editable}/>
                            </InputColumn>
                            <InputColumn label='City' columnSize={12}>
                              <Input name={getFieldName('city')} disabled={!editable}/>
                            </InputColumn>
                            <InputColumn label='State' columnSize={12}>
                              <Input name={getFieldName('state')} disabled={!editable}/>
                            </InputColumn>
                            <InputColumn label='Zip Code' columnSize={12}>
                              <Input name={getFieldName('zip')} disabled={!editable}/>
                            </InputColumn>
                          </>
                        );
                      }}
                    </EditorForm>
                  </Col>
                </Row>
              </Col>
              <Col>
                <h2 style={{display: 'flex', flex: '1', justifyContent: 'center', paddingBottom: '1rem'}}>My Volunteer Group</h2>
                <UserVolunteerGroup editable={editable}/>
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      {loading ?  <CenteredSpinner/> : (
        errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
          renderContent()
      )}
    </div>
  );
}

const mapStateToProps = (state: WebState) => ({
  currentUser: userStore.selectors.getCurrentUser(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadUserPreferencesData: loadUserPreferences,
    updateUserPreferences: userStore.actions.updateUserPreferences
  }, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
