import React from 'react';

import {ArrayHelpers, FieldArray} from 'formik';
import {connect} from 'react-redux';
import {VolunteerGroupUser} from '../../../common/redux/entities/volunteerGroup';
import {makeVolunteerGroupUser, makeVolunteerGroupUserSignUpInterface} from '../../../common/util/factory';
import {DropdownOption} from '../../../components/util/form-components/SearchableDropdown/SearchableDropdown';
import {
  ListHeaderWithDropDown,
  ResetValueFunc
} from '../../../components/util/lists/ListHeaderWithDropDown/ListHeaderWithDropDown';
import {ListTable} from '../../../components/util/lists/ListTable/ListTable';
import {DeletableListRow} from '../../../components/util/lists/ListTable/ListTableRow';
import {InputColumn} from '../../../components/util/form-components/InputColumn';
import {userStore} from '../../../common/redux/entities/user';
import {selector} from '../../../common/redux/selectors';
import {VolunteerGroupUserSignUpInterface} from './ShiftSignupModal';

type Props = {
  users: VolunteerGroupUserSignUpInterface[];
  userDropDownOptions: DropdownOption[];
  prefix: string;
  editable: boolean;
} & ReturnType<typeof mapStateToProps>;

function ShiftSignupGroupUserSelection(props: Props) {
  const {prefix, getUser, editable, users, userDropDownOptions} = props;
  const onAdd = (helpers: ArrayHelpers, id: string, resetValue: ResetValueFunc) => {
    helpers.push(makeVolunteerGroupUserSignUpInterface(getUser(id), false) as VolunteerGroupUser);
    resetValue();
  };
  return (
    <FieldArray
      name={prefix}
      validateOnChange={false}
      render={helpers=> {
        return (
          <ListTable
            style={{marginBottom: '1rem'}}
            noItemsLabel={'No Members Added'}
            items={users}
            renderHeader={() => (
              <ListHeaderWithDropDown
                label={'Add from Group'}
                dropDownOptions={userDropDownOptions.filter(u => users.every(us => u.value !== us.id))}
                onAdd={(v, r) => onAdd(helpers, v, r)}
                disableAddButton={!editable}
                addOnSelect={true}
                placeholder={'Search Group Members'}
              />
            )}
            renderItem={(item, index) => (
              <DeletableListRow
                iconSize={'2x'}
                key={index}
                deleteColumnSize={1}
                disableDelete={item.initialValue}
                disabled={item.initialValue}
                render={() => (
                  <UserRow
                    prefix={`${prefix}.${index}`}
                    editable={editable}
                    user={item}
                    userDropDownOptions={userDropDownOptions}
                  />
                )}
                editable={editable}
                onDelete={() => helpers.remove(index)}
              />
            )}
          />
        );
      }}
    />
  );
}

type UserRowProps = {
  editable: boolean;
  user: VolunteerGroupUser;
  prefix: string;
  userDropDownOptions: DropdownOption[];
};
function UserRow({editable, user, prefix, userDropDownOptions}: UserRowProps) {
  return  (
    <div style={{display: 'flex', flexGrow: 1}}>
      <InputColumn label={'Name'} columnSize={4} className='pl-2'>
        <h4>{user.name}</h4>
      </InputColumn>
      <InputColumn label={'Email'} columnSize={4} className='pl-2'>
        <h4>{user.email}</h4>
      </InputColumn>
      <InputColumn label={'Phone Number'} columnSize={3} className='pl-2'>
        <h4>{user.phoneNumber}</h4>
      </InputColumn>
    </div>
  );
}
const mapStateToProps = selector(s => ({
  getUser: userStore.selectors.getByIdGuid(s)
}));
const mapToDispatch = () => ({});
export default connect(mapStateToProps, mapToDispatch)(ShiftSignupGroupUserSelection);
