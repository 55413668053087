import React, {FormEvent, useState} from 'react';
import styles from './VolunteerShiftUpdateModal.module.scss';
import {Button, Modal, Row, Form as BSForm, Spinner, Col, Alert} from 'react-bootstrap';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../../redux/types/WebState';
import {connect} from 'react-redux';
import {InputRow} from '../../../../../components/util/form-components/InputRow';
import Input from '../../../../../components/util/form-components/formik-inputs/Input/Input';
import {Form, Formik, FormikErrors} from 'formik';
import {makeShiftUpdateRequest} from '../../../../../common/util/factory';
import {Shift, shiftStore} from '../../../../../common/redux/entities/shift';
import InformationLabel from '../../../../../components/util/form-components/InformationLabel/InformationLabel';
import {format, parseISO} from 'date-fns';
import {VolunteerShiftUpdateModalSchema} from './VolunteerShiftUpdateModalSchema';
import {ShiftUpsertInterface} from '../../../../../api/shiftManagementApi';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../../../../common/util/http';
import {updateLabelSize} from '../index';
import ShiftVolunteerSelection from './ShiftVolunteerSelection';
import {convertToDropDownOptions} from '../../../../../common/util';
import {userStore} from '../../../../../common/redux/entities/user';

export interface ShiftUpdateInterface {
  id: string;
  title: string;
  volunteersNeeded: number;
  currentVolunteers: number;
  description: string;
  shiftVolunteerIds: string[];
}

type Props = {
  existingShift: Shift;
  onSubmit: () => void;
  onCancel: () => void;
  onDelete: () => void;
} & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const ShiftUpdateModal = (props: Props) => {
  const {onSubmit, onCancel, onDelete, existingShift, users, getUserById, currentUser, actions: {upsert}} = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const getFieldName = (name: keyof ShiftUpdateInterface) => name;
  const type = existingShift.catering ? 'catering' : 'volunteer';
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    data: ShiftUpdateInterface,
    validate: (data: ShiftUpdateInterface) => Promise<FormikErrors<ShiftUpdateInterface>>,
    handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void
  ) => {
    setIsSubmitting(true);
    e.persist();
    e.preventDefault();
    const errors = await validate(data);
    if (Object.values(errors).length !== 0) {
      handleSubmit(e);
      setIsSubmitting(false);
    } else {
      try {
        const request: ShiftUpsertInterface = {
          ...existingShift,
          title: data.title,
          volunteersNeeded: data.volunteersNeeded,
          description: existingShift.catering ? data.description : '',
          shiftVolunteerIds: data.shiftVolunteerIds
        };
        await upsert(request);
        await onSubmit();
      } catch (e: AxiosError | any) {
        setErrorMessage(handleAxiosError(e));
      }
    }
    setIsSubmitting(false);
  };

  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <>
            <Button onClick={onCancel} variant={'danger'}
                    className={styles['close-button']}>
              Cancel
            </Button>
            <Button onClick={onDelete} variant={'danger'}
                    className={styles['close-button']}>
              Delete
            </Button>
          </>
        }
        {!isSubmitting ? <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };

  const renderedDate = () => {
    return `${format(parseISO(existingShift.startDate), 'MM/dd/yyyy')} - ${format(parseISO(existingShift.endDate), 'MM/dd/yyyy')}`;
  };

  const renderedTime = () => {
    return `${format(parseISO(existingShift.startDate), 'p')} - ${format(parseISO(existingShift.endDate), 'p')}`;
  };

  const renderedVolunteers = () => {
    return `${existingShift.shiftVolunteers?.length ?? 0} / ${existingShift.volunteersNeeded}`;
  };

  // TODO do not allow form to reach negative number / possibly less than number of volunteers
  return (
    <Modal show={true} className={styles['on-top']} size={'lg'} centered={true}>
      <Modal.Body>
        <Row>
          <Col>
            <h4>Update {type === 'volunteer' ? 'volunteer' : 'meal'} shift "{existingShift.title}"</h4>
          </Col>
        </Row>
        <Formik<ShiftUpdateInterface>
          initialValues={makeShiftUpdateRequest(existingShift)}
          validationSchema={VolunteerShiftUpdateModalSchema}
          onSubmit={() => undefined}
        >
          {({values, validateForm, handleSubmit}) => (
            <Form noValidate={true} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
              <InputRow label={'Shift title:'} labelSize={updateLabelSize} columnSize={3} style={{paddingTop: '10px'}} requiredAsterisk={true}>
                <Input name={getFieldName('title')} type={'text'}/>
              </InputRow>
              <InformationLabel label={'Shift Date:'} labelSize={updateLabelSize} data={renderedDate()} style={{paddingTop: '20px'}}/>
              <InformationLabel label={'Time slot:'} labelSize={updateLabelSize} data={renderedTime()} style={{paddingTop: '20px'}}/>
              {type === 'volunteer' ? (
                <>
                  <InformationLabel label={'Volunteers:'} labelSize={updateLabelSize} data={renderedVolunteers()} style={{paddingTop: '20px'}}/>
                  <InputRow label={'Volunteers needed:'} labelSize={updateLabelSize}  columnSize={3} style={{paddingTop: '20px'}} requiredAsterisk={true}>
                    <Input name={getFieldName('volunteersNeeded')} type={'number'}/>
                  </InputRow>
                  <Input name={getFieldName('id')} hidden={true}/>
                  <Input name={getFieldName('currentVolunteers')} type={'number'} hidden={true}/>
                </>
              ) :
                (
                  <>
                    <InformationLabel label={'Business catering:'} labelSize={updateLabelSize} data={existingShift.businessName}/>
                    <InformationLabel label={'Description:'} labelSize={updateLabelSize} data={existingShift.description}/>
                  </>
                )}
              {errorMessage !== '' ?
                <div style={{marginTop: '1rem'}}>
                  <Alert variant='danger'>{errorMessage}</Alert>
                </div>
                : null}
              {currentUser?.role.roleName === 'Administrator' ?
                <ShiftVolunteerSelection
                  userDropDownOptions={convertToDropDownOptions(users)}
                  users={values.shiftVolunteerIds.map((id) => getUserById(id))}
                  editable={true}
                  prefix={getFieldName('shiftVolunteerIds')}
                /> : null
              }
              <Row>
                <BSForm.Group className={styles['form-buttons']}>
                  {renderButtons()}
                </BSForm.Group>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsert: shiftStore.actions.create
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  users: userStore.selectors.getAsArray(state),
  getUserById: userStore.selectors.getByIdGuid(state),
  currentUser: userStore.selectors.getCurrentUser(state),
  calendarEvents: shiftStore.selectors.getAsArray(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftUpdateModal);

