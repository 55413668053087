import {
  mapIsAdministratorToProps,
  mapIsAdministratorToPropsType
} from '../../../common/redux/entities/user';
import {connect} from 'react-redux';

type AdministratorRouteProps = mapIsAdministratorToPropsType & {
  children: JSX.Element;
};

// TODO once manager role is implemented, create prop for whether managers can access
function AdministratorComponent({children, administrator}: AdministratorRouteProps) {
  return administrator ? children : null;
}

export default connect(mapIsAdministratorToProps)(AdministratorComponent);
