import {
  number,
  object, string,
} from 'yup';
import {User} from '../../../../../common/redux/entities/user';



// @ts-ignore
export const UserModalSchema = object<User>({
  id: string().nullable(),
  email: string().required('Email is required'),
  archivedAt: string().nullable(),
  roleId: string().required('Role is required'),
  name: string().required('Name is required'),
  phoneNumber: string().nullable(),
  street: string().nullable(),
  city: string().nullable(),
  state: string().nullable(),
  zip: string().nullable(),
  accountStatus: number()

});
