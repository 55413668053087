import React, {useEffect, useState} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import BootstrapTable, { ColumnDescription} from 'react-bootstrap-table-next';
import IconButton from '../../../../components/util/widgets/IconButton/IconButton';
import {ConfirmationDialog} from '../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import {WebState} from '../../../../redux/types/WebState';
import {makeVolunteerGroup, makeVolunteerGroupUser} from '../../../../common/util/factory';

import {
  VolunteerGroup,
  volunteerGroupStore
} from '../../../../common/redux/entities/volunteerGroup';
import VolunteerGroupModal from '../UserGroupPreferenceModal/VolunteerGroupModal';
import {Row} from 'react-bootstrap';
import {toTitleCase} from '../../../../common/util/string';
import {userStore} from '../../../../common/redux/entities/user';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & {
  editable: boolean;
};

function UserVolunteerGroup({actions: {upsertGroup, deleteGroup, leaveGroup}, getUserNameById, getVolunteerGroup, currentUser}: Props) {
  const [isDeletingGroup, setIsDeletingGroup] = useState<VolunteerGroup | null>(null);
  const [isLeavingGroup, setIsLeavingGroup] = useState<VolunteerGroup | null>(null);
  const [editingVolunteerGroup, setEditingVolunteerGroup] = useState<VolunteerGroup | null>(null);
  const [currentVolunteerGroup, setCurrentVolunteerGroup] = useState<VolunteerGroup | null>(null);

  useEffect(() => {
    if(getVolunteerGroup.length !== 0) {
      setCurrentVolunteerGroup(getVolunteerGroup[0]);
    }
  }, [getVolunteerGroup]);

  const addButton = () => {
    if(currentVolunteerGroup !== null) {
      return null;
    }
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <IconButton
          icon={'plus-circle'}
          size={'2x'}
          iconToolTipText={'Create Volunteer Group'}
          onClick={() => {
            const initialGroup = makeVolunteerGroup(currentVolunteerGroup!);
            initialGroup.members.push(makeVolunteerGroupUser(currentUser!));
            setEditingVolunteerGroup(initialGroup);
          }}
        />
      </div>
    );
  };

  const renderEditDeleteOrLeaveButtons = () => {
    if (currentVolunteerGroup !== null && currentVolunteerGroup.leaderId !== currentUser!.id) {
      return (
        <>
          <div style={{marginRight: '10px'}}>
            <IconButton
              icon={'minus'}
              styles={{color: 'red'}}
              size={'2x'}
              iconToolTipText={'Leave Volunteer Group'}
              onClick={() => setIsLeavingGroup(currentVolunteerGroup)}
            />
          </div>
        </>
      );
    }
    return (
      <>
        <div style={{marginRight: '10px'}}>
          <IconButton
            icon={'edit'}
            size={'2x'}
            iconToolTipText={'Update Volunteer Group'}
            onClick={() => setEditingVolunteerGroup(makeVolunteerGroup(currentVolunteerGroup!))}
          />
        </div>
        <div style={{marginRight: '10px'}}>
          <IconButton
            icon={'trash-alt'}
            styles={{color: 'red'}}
            size={'2x'}
            iconToolTipText={'Delete Volunteer Group'}
            onClick={() => setIsDeletingGroup(currentVolunteerGroup)}
          />
        </div>
      </>
    );
  };

  const columns: ColumnDescription[] = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true
    },
    {
      dataField: 'phoneNumber',
      text: 'Phone Number',
      sort: true
    }
  ];

  return (
    <>
      {currentVolunteerGroup !== null ?
        <>
          <Row>
            <h3 style={{display: 'flex', alignItems: 'center', marginRight: '2rem', flexGrow: 1}}>{toTitleCase(currentVolunteerGroup.name)} Members</h3>
            <h4 style={{display: 'flex', alignItems: 'center', marginRight: '2rem'}}>Group Leader: {getUserNameById(currentVolunteerGroup.leaderId).name}</h4>
            {renderEditDeleteOrLeaveButtons()}
          </Row>
          <BootstrapTable keyField='id' data={currentVolunteerGroup.members} columns={columns}/>
        </>
      :
        <Row style={{justifyContent: 'center', marginTop: '2rem'}}>
          <h5 style={{display: 'flex', alignItems: 'center', marginBottom: '20px', marginRight: '20px'}}>Create a group</h5>
          {addButton()}
        </Row>
      }
      {isDeletingGroup !== null && (
        <ConfirmationDialog
          onAccept={async () => {
            deleteGroup(currentVolunteerGroup?.id as string);
            setIsDeletingGroup(null);
            setCurrentVolunteerGroup(null);
          }}
          onDecline={async () => setIsDeletingGroup(null)}
          open={isDeletingGroup !== null}
          prompt='Are you sure you want to delete this group?'
          positiveText='Yes'
          negativeText='No'
          positiveVariant='success'
          negativeVariant='danger'
        />
      )}
      {isLeavingGroup !== null && (
        <ConfirmationDialog
          onAccept={async () => {
            leaveGroup(currentVolunteerGroup?.id as string);
            setIsLeavingGroup(null);
            setCurrentVolunteerGroup(null);
          }}
          onDecline={async () => setIsLeavingGroup(null)}
          open={isLeavingGroup !== null}
          prompt='Are you sure you want to leave this group?'
          positiveText='Yes'
          negativeText='No'
          positiveVariant='success'
          negativeVariant='danger'
        />
      )}
      {editingVolunteerGroup ?
        <VolunteerGroupModal
          editable={true}
          userCreated={true}
          onSubmit={async (group: VolunteerGroup) => {
            const response = await upsertGroup(group);
            setEditingVolunteerGroup(null);
            setCurrentVolunteerGroup(response as any as VolunteerGroup);
          }}
          onCancel={() => setEditingVolunteerGroup(null)}
          volunteerGroup={editingVolunteerGroup}
        />
      : null}
    </>
  );
}

const mapStateToProps = (state: WebState) => ({
  getUserNameById: userStore.selectors.getByIdGuid(state),
  getVolunteerGroup: volunteerGroupStore.selectors.getAsArray(state),
  currentUser: userStore.selectors.getCurrentUser(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({ actions: bindActionCreators({
  upsertGroup: volunteerGroupStore.actions.upsert,
  deleteGroup: volunteerGroupStore.actions.delete,
  leaveGroup: volunteerGroupStore.actions.leave
  }, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(UserVolunteerGroup);
