import React, {FormEvent} from 'react';
import {User, userStore} from '../../../../common/redux/entities/user';
import {WebState} from '../../../../redux/types/WebState';
import {convertToDropDownOptions} from '../../../../common/util';
import {connect} from 'react-redux';
import styles from './VolunteerGroupModal.module.scss';
import {Form, Formik, FormikErrors} from 'formik';
import {DropdownInput} from '../../../../components/util/form-components/formik-inputs/DropdownInput/DropdownInput';
import {Button, Form as BSForm, Modal, Row} from 'react-bootstrap';
import {InputRow} from '../../../../components/util/form-components/InputRow';
import {VolunteerGroupModalSchema} from './VolunteerGroupModalSchema';
import {
  VolunteerGroup,
  volunteerGroupStore,
  VolunteerGroupUser
} from '../../../../common/redux/entities/volunteerGroup';
import Input from '../../../../components/util/form-components/formik-inputs/Input/Input';
import {makeVolunteerGroupUser} from '../../../../common/util/factory';
import UserSelection from './UserSelection';

type Props = {
  volunteerGroup: VolunteerGroup;
  userCreated: boolean;
  onCancel: () => void;
  onSubmit: (group: VolunteerGroup) => void;
  editable: boolean;
  onTop?: boolean;
} & ReturnType<typeof mapStateToProps>;

function VolunteerGroupModal(props: Props) {
  const {volunteerGroup, onTop, onSubmit, editable, onCancel, userCreated,
    currentUserDropDownOption, usersDropDownOptions, getUser} = props;
  const getFieldName = (name: keyof VolunteerGroup) => name;
  let previousLeaderId = '';

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    assignment: VolunteerGroup,
    validate: (values: VolunteerGroup) => Promise<FormikErrors<VolunteerGroup>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    e.persist();
    e.preventDefault();
    console.log('submit');
    const errors = await validate(assignment);
    if (Object.values(errors).length !== 0) {
      console.log('errors');
      formikHandleSubmit(e);
    } else {
      onSubmit(assignment);
    }
  };

  const leaderDropDownFilter = () => {
    if(volunteerGroup.id !== '') {
      return convertToDropDownOptions([getUser(volunteerGroup.leaderId)]);
    }
    return userCreated ? currentUserDropDownOption : usersDropDownOptions;
  };

  return (
    <Modal backdropClassName={onTop ? styles['on-top'] : ''} show={true} dialogClassName={styles['data-point-modal']}
           centered={true} onHide={() => null}>
      <Modal.Body>
        <Formik initialValues={volunteerGroup} validationSchema={VolunteerGroupModalSchema} onSubmit={() => undefined}>
          {({values, validateForm, handleSubmit}) => {
            return (
              <Form noValidate={true} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                <Row>
                  <Modal.Title style={{marginBottom: '1rem'}}>Volunteer Group</Modal.Title>
                  <BSForm.Group className={styles['form-buttons']}>
                    <Button onClick={onCancel} variant={editable ? 'danger' : 'info'} className={styles['close-button']}>
                      {editable ? 'Cancel' : 'Close'}
                    </Button>
                    {editable && <Button variant={'success'} type='submit'>Submit</Button>}
                  </BSForm.Group>
                </Row>
                <InputRow label={'Group name'} columnSize={2} labelSize={1} style={{marginBottom: '1rem'}}>
                  <Input name={getFieldName('name')} disabled={!editable}/>
                </InputRow>
                <InputRow label={'Leader'} columnSize={2} labelSize={1}>
                  <DropdownInput
                    name={getFieldName('leaderId')}
                    dropdownData={leaderDropDownFilter()}
                    disabled={userCreated || volunteerGroup.id !== ''}
                    defaultToFirstOptionIfOnlyOneOption={true}
                    onSelect={(name: string, selectedValue: any) => {
                      if(previousLeaderId !== '') {
                        values.members.splice(values.members.findIndex(a => a.id === previousLeaderId), 1);
                      }
                      if(values.members.findIndex(a => a.id === selectedValue) === -1) {
                        values.members.push(makeVolunteerGroupUser(getUser(selectedValue)) as VolunteerGroupUser);
                      }
                      previousLeaderId = selectedValue;
                    }}
                  />
                </InputRow>
                <UserSelection
                  userDropDownOptions={usersDropDownOptions}
                  users={values.members}
                  editable={editable}
                  prefix={getFieldName('members')}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state: WebState) => ({
  getUserAlertPreferenceById: userStore.selectors.getById(state),
  currentUserDropDownOption: convertToDropDownOptions([userStore.selectors.getCurrentUser(state) as any as User]),
  usersDropDownOptions: convertToDropDownOptions(userStore.selectors.getNonArchivedActiveUsers(state)
    .filter(u => u.volunteerGroups?.length === 0).map(u => makeVolunteerGroupUser(u))),
  getUser: userStore.selectors.getByIdGuid(state),
  getGroupByLeaderId: volunteerGroupStore.selectors.getGroupByLeaderId(state),
  currentUser: userStore.selectors.getCurrentUser(state)
});
export default connect(mapStateToProps)(VolunteerGroupModal);
