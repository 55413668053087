import {date, object} from 'yup';
import {ShiftDeletionModalInterface} from './ShiftDeletionModal';

// @ts-ignore
const ShiftDeletionModalSchema = object<ShiftDeletionModalInterface>({
  startDate: date().nullable()
    .required('A Start Date is required'),
  endDate: date().nullable()
    .when('startDate', (startDate: Date, schema) =>
      startDate && schema.min(startDate, 'End date must be after the shift start date'))
});

export default ShiftDeletionModalSchema;
