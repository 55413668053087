import {object, array, string} from 'yup';
import {GroupEmail} from './HcsiTextAlerts';

// @ts-ignore
export const GroupEmailSchema = object<GroupEmail>({
  subject: string().required('A subject is required'),
  title: string().nullable(),
  body: string().required('A body is required'),
  userIds: array().test('emptyFieldsUser', 'User list is required when no roles or groups are specified', (userIds, context) => {
    if (context.parent.roleIds.length < 1 && context.parent.groupIds.length < 1)
      if (!userIds || userIds.length < 1)
        return false;
    return true;
  }),
  roleIds: array().test('emptyFieldsRole', 'Role list is required when no users or groups are specified', (roleIds, context) => {
    if (context.parent.userIds.length < 1 && context.parent.groupIds.length < 1)
      if (!roleIds || roleIds.length < 1)
        return false;
    return true;
  }),
  groupIds: array().test('emptyFieldsGroup', 'Group list is required when no users or roles are specified', (groupIds, context) => {
    if (context.parent.userIds.length < 1 && context.parent.roleIds.length < 1)
      if (!groupIds || groupIds.length < 1)
        return false;
    return true;
  })
});
