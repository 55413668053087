import {Col, Row} from 'react-bootstrap';
import React, {CSSProperties} from 'react';

interface Props {
  label: string;
  data: string | number;
  labelSize?: number;
  dataSize?: number;
  style?: CSSProperties;
}

const InformationLabel = (props: Props) => (
  <Row lg={12}>
    <Col lg={props.labelSize ?? 2} style={props.style ?? {paddingTop: '1rem'}}>
        {props.label}
    </Col>
    <Col lg={props.dataSize ?? 8}>
      <h6 style={props.style ?? {paddingTop: '1rem'}}>
        {props.data}
      </h6>
    </Col>
  </Row>
);

export default InformationLabel;
