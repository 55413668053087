import {Dispatch} from 'redux';
import {Dictionary} from '../util';
import {getUserManagementState} from '../../api/userManagementApi';
import {User, userStore} from './entities/user';
import {batchActions} from 'redux-batched-actions';
import {Role, roleStore} from './entities/role';

export interface UserManagementState {
  users: Dictionary<User>;
  roles: Dictionary<Role>;
}

export const loadUserManagementData = () => async (dispatch: Dispatch) => {
  const response: UserManagementState = await getUserManagementState();
  await dispatch(batchActions([
    dispatch(userStore.actions.set(response.users)),
    dispatch(roleStore.actions.set(response.roles))
  ], 'LOAD_USER_MANAGEMENT_STATE'));
};
