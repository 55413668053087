import React, {SyntheticEvent} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../redux/types/WebState';
import {connect} from 'react-redux';
import styles from '../../Configuration/VideoManagement/components/VideoModal/VideoModal.module.scss';
import { Modal } from 'react-bootstrap';
import IconButton from '../../../components/util/widgets/IconButton/IconButton';
import ReactPlayer from 'react-player';
import {userStore} from '../../../common/redux/entities/user';
import {Video} from '../../../common/redux/entities/video';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & {
  onCancel: () => void;
  onTop?: boolean;
  video: Video | null;
};


const TrainingVideo = (props: Props) => {
  const {video, onCancel, onTop, actions: {setVideoWatched}} = props;

  const videoWatched = (videoId: string) => {
    setVideoWatched(videoId);
    onCancel();
  };

  return (
    <Modal backdropClassName={onTop ? styles['on-top'] : ''} style={{padding: 0}} show={true} size='xl' centered={true} onHide={() => onCancel()}>
      <Modal.Body style={{padding: '0'}}>
        <div style={{display: 'flex'}}>
          <ReactPlayer
            url={`${process.env.REACT_APP_STORAGE_URL + (video !== null ? video.url : '')}`}
            playing={true}
            controls={true}
            onContextMenu={(e: SyntheticEvent) => e.preventDefault()}
            pip={false}
            width='100%'
            height='100%'
            onEnded={() => video !== null ? videoWatched(video.id) : onCancel()}
            style={{display: 'flex', maxWidth: '100%'}}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                  disablePictureInPicture: true
                }
              }
            }}
          />
          <IconButton icon={'xmark'} styles={{top: '0', right: '.5rem', position: 'absolute'}} size={'2x'} onClick={() => onCancel()}/>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    setVideoWatched: userStore.actions.setVideoWatched
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingVideo);
