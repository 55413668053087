export interface RoutePath {
  path: string;
  component: any;
}
const notFound = '/not-found';

const RootPath = {
  all: '*',
  root: '*',
  home: '/'
};

enum MiscellaneousPaths {
  storage = '/storage',
  images = '/images',
  termsOfService = '/terms-of-service',
  privacyPolicy = '/privacy-policy'
}

enum DashboardPaths {
  dashboard = '/dashboard'
}

enum AuthenticationPaths {
  login = '/login',
  logout = '/logout',
  forgotPassword = '/forgot-password',
  resetPassword = '/reset-password',
  register = '/register',
  verifyEmail = '/verify'
}

enum VideoPaths {
  trainingVideos = '/training-videos'
}

enum DocumentPaths {
  documents = '/documents'
}


enum VolunteerCalendarPaths {
  volunteerCalendar = '/volunteer-calendar'
}

enum CateringCalendarPaths {
  cateringCalendar = '/meal-calendar'
}

enum ConfigurationPaths {
  userManagement = '/configuration/users',
  videoManagement = '/configuration/videos',
  groupManagement = '/configuration/groups',
  alerts = '/alerts',
  calendarManagement = '/configuration/calendar',
  scheduleManagement = '/configuration/schedule',
  documentManagement = '/configuration/documents',
}

const PublicPath = '/public';

const PublicPaths = {
  activate: PublicPath + '/activate',
  watch: PublicPath + '/watch'
};

const ProfilePaths = {
  View: '/profile/view',
  Edit: '/profile/edit'
};

const hcsiWebsitePath = 'https://soinhomeless.org';
const RavenBridgeLink = 'https://www.ravenbridgesoftware.com/';


export function applyBasePath(basePath: string, pathFn: (basePath: string) => string) {
  return pathFn(basePath);
}

export function applyBasePaths(basePath: string, ...pathsFns: ((basePath: string) => string)[]) {
  return pathsFns.map(fn => applyBasePath(basePath, fn));
}

export interface ComponentPath {
  component: any;
  paths: string[];
}

export function convertComponentPaths(...editorPaths: ComponentPath[]): RoutePath[] {
  return editorPaths.map(editorPath => editorPath.paths.map(path => ({
    path: path,
    component: editorPath.component
  }))).flat(1);
}

export type UrlParam = number|string;

export const RoutePaths = {
  ...RootPath,
  ...MiscellaneousPaths,
  ...AuthenticationPaths,
  ...VideoPaths,
  ...VolunteerCalendarPaths,
  ...CateringCalendarPaths,
  ...DashboardPaths,
  ...ConfigurationPaths,
  ...DocumentPaths,
  notFound: notFound,
  publicPath: PublicPath,
  publicPaths: PublicPaths,
  profilePaths: ProfilePaths,
  hcsiWebsitePath: hcsiWebsitePath,
  rbswLink: RavenBridgeLink
};
