import {
  mixed,
  object, string,
} from 'yup';
import {getExtension} from '../../../../../util';

// @ts-ignore
export const UpdateVideoModalSchema = object<UpdateVideoInterface>({
  id: string(),
  title: string().required('Title is required'),
  description: string().required('Description is required'),
  thumbnailFile: mixed()
    .test({
      message: 'Please provide a supported file type. Types are .png, .jpg, and .jpeg',
      test: (file, context) => {
        let isValid = true;
        // @ts-ignore
        if(context.originalValue !== undefined) {
          // @ts-ignore
          isValid = ['.png', '.jpeg', '.jpg'].includes(getExtension(context.originalValue));
        }
        if (!isValid) context?.createError();
        return isValid;
      }
    })
});
