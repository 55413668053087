import React, {SetStateAction} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../redux/types/WebState';
import {HomeText, homeTextStore} from '../../../common/redux/entities/homeText';
import {connect} from 'react-redux';
import {Button, Form, Modal} from 'react-bootstrap';
import {Formik, FormikErrors} from 'formik';
import {postHomeText} from '../../../api/homeTextApi';

interface ShowProps {
  show: boolean;
  setShow: React.Dispatch<SetStateAction<boolean>>;
}

type Props = ShowProps & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const HomeTextEditorModal = (props: Props) => {
  const {show, setShow, homeText, actions: {setHomeText}} = props;

  const handleClose = () => setShow(false);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop='static'
      keyboard={false}
    >
      <Formik<HomeText>
        initialValues={homeText}
        onSubmit={async (data, {setSubmitting}) => {
          try {
            const resp = await postHomeText(data.value);
            await setHomeText(resp.data);
            setSubmitting(false);
            handleClose();
          } catch (e) {
            console.log('invalid request');
          }
        }}
        validate={(values) => {
          const errors: FormikErrors<HomeText> = {};
          if (values.value.length >= 150) {
            errors.value = 'Length must be less than 150 characters';
          }
          return errors;
        }}>
        {({values, handleChange, errors, handleSubmit, isSubmitting}) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton={true}>
              <Modal.Title>Set home text</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Control
                type={'text'}
                name={'value'}
                onChange={handleChange}
                value={values.value}
                isInvalid={!!errors.value}
              />
              <Form.Control.Feedback type='invalid' role='alert'>
                {errors.value}
              </Form.Control.Feedback>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleClose}>
                Close
              </Button>
              <Button type='submit' variant='primary' disabled={isSubmitting}
                      onClick={() => handleSubmit()}>Submit</Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({setHomeText: homeTextStore.actions.set}, dispatch)
});
const mapStateToProps = (state: WebState) => ({homeText: homeTextStore.selectors.getState(state)});

export default connect(mapStateToProps, mapDispatchToProps)(HomeTextEditorModal);
