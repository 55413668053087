import {object} from 'yup';
import * as Yup from 'yup';

export const contactInformationFormSchema = object({
  phoneNumber: Yup.string().nullable(),
  street: Yup.string().nullable(),
  city: Yup.string().nullable(),
  state: Yup.string().nullable(),
  zip: Yup.string().nullable()
});
