import React from 'react';

export const PrivacyPolicyText =
  (<div>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pretium nulla vel malesuada molestie. Donec sem odio,
      consectetur quis nunc quis, vestibulum tincidunt enim. Vivamus mattis tempus ex, vitae mollis justo fermentum sit amet.
      Nunc cursus mi sit amet purus volutpat facilisis. Aliquam ultricies nisl orci, non gravida dui aliquet sed. Nulla
      suscipit, ex ut sagittis congue, lacus augue euismod mauris, sed tristique nulla tellus sit amet mi. Duis a ornare
      augue, ac pulvinar justo. Morbi scelerisque in tellus nec egestas. Suspendisse tempus pulvinar consequat.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pretium nulla vel malesuada molestie. Donec sem odio,
      consectetur quis nunc quis, vestibulum tincidunt enim. Vivamus mattis tempus ex, vitae mollis justo fermentum sit amet.
      Nunc cursus mi sit amet purus volutpat facilisis. Aliquam ultricies nisl orci, non gravida dui aliquet sed. Nulla
      suscipit, ex ut sagittis congue, lacus augue euismod mauris, sed tristique nulla tellus sit amet mi. Duis a ornare
      augue, ac pulvinar justo. Morbi scelerisque in tellus nec egestas. Suspendisse tempus pulvinar consequat.
    </p>
    <p>
      Morbi vel libero aliquam lectus scelerisque congue et vitae eros. Etiam elementum, tellus ac fermentum tristique,
      est libero bibendum sem, eget posuere leo mi sodales sapien. Maecenas porttitor scelerisque lacinia. Phasellus massa
      orci, pulvinar sed lacus non, vulputate vehicula velit. Quisque quis imperdiet enim, eu consequat nibh. Cras vel
      lacinia lectus. Nullam lacinia tincidunt enim non rhoncus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Cras pretium enim ornare lectus scelerisque, eget egestas arcu tincidunt. Suspendisse suscipit, ante eget bibendum
      sodales, elit magna dictum libero, non dictum leo dolor ac risus. Maecenas mollis lacus sit amet eros maximus gravida.
      Nam egestas ligula placerat purus dignissim, ac maximus magna posuere.
    </p>
    <p>
      Donec ut pulvinar libero, non lobortis orci. Sed commodo imperdiet risus, nec fringilla lectus ultricies vel.
      Pellentesque pulvinar consequat augue. Mauris at dictum arcu. Praesent blandit, velit et pulvinar tempor,
      ante nisl commodo ipsum, ac cursus lectus nibh suscipit odio. Curabitur vitae ipsum a velit condimentum pulvinar.
      Fusce in est vel ante hendrerit maximus. Maecenas sollicitudin odio felis, ut efficitur lacus facilisis nec.
      In hac habitasse platea dictumst. Morbi ut fermentum augue.
    </p>
    <p>
      In sed lectus libero. Curabitur faucibus quis urna non lacinia. Nunc consequat nunc magna, vel imperdiet ex tincidunt in.
      Curabitur tortor mi, egestas non aliquet vel, viverra eget urna. Aliquam pharetra enim non ipsum pharetra semper.
      Suspendisse elementum nisl et neque blandit, id suscipit justo semper. Pellentesque ornare felis sed mauris porta,
      a maximus augue auctor. Nunc rutrum urna non lectus sodales, nec auctor nibh placerat. Donec interdum blandit accumsan.
    </p>
    <p>
      Nunc vestibulum dictum leo at hendrerit. Curabitur nisl diam, hendrerit eu sem non, dictum vehicula quam.
      Ut convallis bibendum felis, in luctus purus lobortis eget. Donec quam arcu, ultricies vitae turpis non,
      malesuada mattis ex. Vivamus dictum congue nisi, vitae pharetra lacus fringilla eleifend. Morbi sed pretium diam,
      ut cursus purus. Pellentesque justo orci, lobortis sit amet felis sit amet, vehicula cursus arcu.
      Aliquam sodales porttitor nunc at bibendum. Integer eu lacinia felis. Integer in semper odio.
      Aliquam vel tortor et felis aliquet tristique pharetra ac mi. Nam semper vestibulum mauris,
      ac vestibulum quam tristique vitae. Duis interdum tristique ante, vel blandit tortor accumsan vel.
    </p>
  </div>);



