import React, {useMemo, useState} from 'react';
import {useField, useFormikContext} from 'formik';
import ReactDatePicker from 'react-datepicker';
// tslint:disable-next-line:no-import-side-effect
import 'react-datepicker/dist/react-datepicker.css';
import styles from './DatepickerInput.module.scss';
import {Form} from 'react-bootstrap';
import {differenceInDays, format, isValid, parse, parseISO} from 'date-fns';
import {RedErrorMessage} from '../../RedErrorMessage/RedErrorMessage';

interface DatepickerInputProps {
  name: string;
  showTimeInput?: boolean;
  showTimeSelect?: boolean;
  timeIntervals?: number;
  timeFormat?: string;
  dateFormat?: string;
  placeholderDate?: Date;
  minDateTime?: Date;
  maxDateTime?: Date;
  rows?: number;
  placeHolderText?: string;
}

const DatepickerInput = (props: DatepickerInputProps) => {
  const {name, showTimeInput, showTimeSelect, timeIntervals, timeFormat,
    dateFormat, placeholderDate, minDateTime, maxDateTime, placeHolderText} = props;

  const {setFieldValue} = useFormikContext<any>();
  const [field, {value, error, touched}] = useField(name);

  const combinedFormat = dateFormat || `MM/dd/yyyy ${timeFormat ? timeFormat : ''}`;
  const placeHolder = placeholderDate ? format(placeholderDate, combinedFormat) : '';


  return (
    <React.Fragment>
      <ReactDatePicker
        {...field}
        calendarClassName={styles['datepicker-calendar']}
        showTimeInput={showTimeInput || false}
        showTimeSelect={showTimeSelect || false}
        timeFormat={timeFormat || ''}
        timeIntervals={timeIntervals || 15}
        dateFormat={combinedFormat}
        placeholderText={placeHolderText ? placeHolderText : placeHolder}
        minDate={minDateTime}
        maxDate={maxDateTime}
        selected={field.value}
        autoComplete={'off'}
        value={field.value}
        // onChangeRaw={val => setFieldValue(field.name, val)}
        onChange={(val: any) => {
          setFieldValue(field.name, val);
        }}
        // @ts-ignore
        customInput={<Form.Control{...field} rows={props.rows}/>}
      />
      <RedErrorMessage name={props.name}/>
    </React.Fragment>
  );
};

export default DatepickerInput;
