import {Dispatch} from 'redux';
import {batchActions} from 'redux-batched-actions';
import {getUsersEmails} from '../../api/userManagementApi';
import {User, userStore} from './entities/user';
import {Role, roleStore} from './entities/role';
import {VolunteerGroup, volunteerGroupStore} from "./entities/volunteerGroup";

export interface GroupEmailUserAndRoleResponse {
  users: User[];
  roles: Role[];
  groups: VolunteerGroup[];
}

export const loadGroupEmailUserAndRoleData = () => async (dispatch: Dispatch) => {
  const response: GroupEmailUserAndRoleResponse = await getUsersEmails();
  await dispatch(batchActions([
    dispatch(userStore.actions.overwriteIdNameAndEmailAction(response.users)),
    dispatch(roleStore.actions.set(response.roles)),
    dispatch(volunteerGroupStore.actions.set(response.groups))
  ], 'LOAD_EMAIL_DATA'));
};
