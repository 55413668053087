import {api} from './index';
import {User, UserPreferences, UserRegistrationForm} from '../common/redux/entities/user';
import {UserManagementState} from '../common/redux/userManagement';
import {v4 as uuidv4} from 'uuid';
import {VolunteerGroup} from '../common/redux/entities/volunteerGroup';
import {UserPreferencesResponse} from '../common/redux/userPreferences';
import {Dictionary} from '../common/util';
import {GroupEmailUserAndRoleResponse} from '../common/redux/GroupEmailUserAndRoleData';

export async function getUsers() {
  return (await api.get<User[]>('/User')).data;
}

export async function getUsersEmails() {
  return (await api.get<GroupEmailUserAndRoleResponse>('User/emailData')).data;
}

export async function getUserManagementState() {
  return (await api.get<UserManagementState>('/User/management')).data;
}

export async function archiveUser(id: string) {
  return (await api.post<User>(`/User/${id}/archive`)).data;
}

export async function restoreUser(id: string) {
  return (await api.post<User>(`/User/${id}/restore`)).data;
}

export async function getVideosToWatch() {
  return (await api.get<User>(`/User/video`)).data;
}

export async function upsertUser(form: User) {
  return (await api.post<User>('/User', form)).data;
}

export async function createUser(form: User) {
  const request = form as UserRegistrationForm;
  const tempPass = uuidv4();
  request.password = tempPass;
  request.confirmPassword = tempPass;
  return (await api.post<User>('/User/create', request)).data;
}

export async function getUserPreferencesState() {
  return (await api.get<UserPreferencesResponse>('/UserPreferences')).data;
}

export async function getUserPreferencesStateAdmin() {
  return (await api.get<UserPreferencesResponse>('/UserPreferences/admin')).data;
}

export async function getUserVolunteerGroupState() {
  return (await api.get<Dictionary<User>>('/VolunteerGroup/user')).data;
}

export async function updateUserPreferences(form: UserPreferences) {
  return (await api.post<User>('/UserPreferences', form)).data;
}

export async function approveUser(id: string) {
  return (await api.post<User>(`/User/approve/${id}`)).data;
}

export async function upsertVolunteerGroup(form: VolunteerGroup) {
  return (await api.post<VolunteerGroup>('/VolunteerGroup', form)).data;
}

export async function deleteVolunteerGroupUsers(id: string, users: string[]) {
  return (await api.post<VolunteerGroup>(`/VolunteerGroup/${id}`, users)).data;
}

export async function deleteVolunteerGroup(id: string) {
  return (await api.delete<string>(`/VolunteerGroup/${id}`)).data;
}

export async function leaveVolunteerGroup() {
  return (await api.post<string>(`/VolunteerGroup/leave`)).data;
}

export async function getVolunteerGroupUsers() {
  return (await api.get<Dictionary<User>>(`/VolunteerGroup/user/current`)).data;
}

export async function getVolunteerCalendarUsersData() {
  return (await api.get<Dictionary<User>>('/Shift/calendarUsersData')).data;
}

export async function toggleGenericTwiliioNotificationStatus() {
  return (await api.post<User>('/User/notifications/set')).data;
}

export async function acceptTos() {
  return (await api.post<User>('/User/tos')).data;
}
