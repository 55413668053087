import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {CommonDispatch} from '../index';
import {
  createVideo,
  CreateVideoRequest, deleteVideo,
  updateVideo,
  uploadVideoFile
} from '../../../api/videoManagementApi';
import {CreateVideoInterface} from '../../../pages/Configuration/VideoManagement/components/VideoModal/VideoModal';
import {
  UpdateVideoInterface
} from '../../../pages/Configuration/VideoManagement/components/VideoModal/UpdateVideoModal';
import {VideoRoleAssignment} from './videoRoleAssignment';
import {createAction} from 'typesafe-actions';
import {deleteShiftAction} from './shift';

export interface Video {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  length: string;
  url: string;
  videoRoleAssignments: VideoRoleAssignment[];
}
export const deleteVideoAction = createAction('VIDEO/DELETE')<string>();
const actions = createStandardActions(placeholder<Video>(), 'VIDEO/SET', 'VIDEO/SAVE');
const selectors = createStandardSelectors(placeholder<Video>(), s => getEntities(s).videos);
export type VideoActions = GetActions<typeof actions> | ReturnType<typeof deleteVideoAction>;
export const videos = combineReducers({items:
    standardItemsReducer<Video, VideoActions>(actions)
      .handleAction(deleteVideoAction, (state, action) => {
        const newState = {...state};
        delete newState[action.payload as any];
        return newState;
      })
});
export const videoStore = readonly({
  selectors: {
    ...selectors,
    getVideoNameById: selector(s => (videoId: string) => selectors.getAsArray(s).filter(v => v.id === videoId)[0]),
    getVideoById: selector(s => (videoId: string) => selectors.getAsArray(s).filter(v => v.id === videoId)[0])
  },
  actions: {
    ...actions,
    create: (value: CreateVideoInterface) => async (dispatch: CommonDispatch) => {
      const createRequest: CreateVideoRequest = {
        title: value.title,
        description: value.description,
        encodedThumbnailFile: value.thumbnailFile,
        videoRoleAssignments: value.videoRoleAssignments
      };
      const video = await createVideo(createRequest);
      const response = await uploadVideoFile(video.id, value.videoFile);
      await dispatch(actions.save(response));
      return response;
    },
    upsertVideoFile: (id: string, value: FormData) => async (dispatch: CommonDispatch) => {
      const response = await uploadVideoFile(id, value);
      await dispatch(actions.save(response as any as Video));
      return response;
    },
    update: (value: UpdateVideoInterface) => async (dispatch: CommonDispatch) => {
      const response = await updateVideo(value.id, value);
      await dispatch(actions.save(response));
      return response;
    },
    delete: (id: string) => async (dispatch: CommonDispatch) => {
      const response = await deleteVideo(id);
      await dispatch(deleteVideoAction(id));
    }
  }
});
